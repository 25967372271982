<template>
  <MainLayout :title="capitalize($t('confirmation'))" backRoute="productsListView">
    <Form @submit="onSubmit" v-slot="{ meta }" class="max-w-[600px] w-full">
      <CartListBlock />
      <CartTotalBlock />
      <div class="bg-white flex justify-center p-4 mb-4">
        <div class="max-w-[320px] w-full">
          <div class="text-md">
            {{ $t('Your room') }}
          </div>
          <div class="text-xs text-gray-400">
            {{ $t('Please enter the phone number of the pre-checkin or reservation.') }}
          </div>
          <TextField
            v-model="formData.telephone"
            :rules="combineFieldValidators([fieldValidators.required(), fieldValidators.phone()])"
            placeholder="08012345678"
            name="telephone"
            class="mt-2"
          />
        </div>
      </div>
      <Button :loading="loading" :disabled="!meta.valid" class="mx-auto mb-4" type="submit">
        {{ $t('Next') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'

import MainLayout from '@/components/layouts/MainLayout'
import CartListBlock from '@/components/blocks/product/CartListBlock'
import CartTotalBlock from '@/components/blocks/product/CartTotalBlock'
import formValidatorsMixin from '@/mixins/formValidatorsMixin'
import { capitalize } from '@/utils/text'

export default {
  mixins: [formValidatorsMixin],
  components: { MainLayout, Form, CartListBlock, CartTotalBlock },
  data() {
    return {
      loading: false,
      formData: {
        telephone: '',
      },
    }
  },
  computed: {
    ...mapGetters(['cartRooms', 'productsCartTotalCount']),
  },
  watch: {
    productsCartTotalCount: {
      immediate: true,
      handler() {
        if (!this.productsCartTotalCount) return this.$router.replace({ name: 'productsListView' })
      },
    },
  },
  methods: {
    capitalize,
    ...mapActions(['globalErrorHandler', 'getGuestRoomByTelephone']),
    getProductCountList(product) {
      return [...new Array(product.stock || 0)].map((_, index) => ({
        value: index + 1,
        label: index + 1,
      }))
    },
    async onSubmit() {
      try {
        this.loading = true

        await this.getGuestRoomByTelephone({ telephone: this.formData.telephone })

        if (!this.cartRooms?.length) {
          return this.globalErrorHandler({
            error: this.$t('There is no room information. Check it again please.'),
          })
        }

        this.$router.push({ name: 'cartOrderConfirmationView' })
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
