<template>
  <div
    class="lg:w-[600px] md:max-w-[600px] p-8 mb-4 bg-white flex flex-col justify-start items-start"
  >
    <slot name="top" />
    <RadioField
      v-model="formData.nationality"
      :label="$t('Nationality')"
      :options="[
        { value: 'JPN', label: $t('Japanese') },
        { value: 'NJP', label: $t('non-Japanese') },
      ]"
      :rules="fieldValidators.required()"
      name="nationality"
    />
    <div class="w-full">
      <label class="mb-2 block text-small font-semibold">
        {{ $t('Full name') }}
        <span class="text-[#272727]">*</span>
      </label>
      <div class="flex">
        <template v-if="$i18next.isJapanese()">
          <TextField
            v-model="formData.surname"
            :placeholder="$t('Last name')"
            :rules="fieldValidators.required()"
            name="surname"
            class="mr-2"
          />
          <TextField
            v-model="formData.name"
            :placeholder="$t('First name')"
            :rules="fieldValidators.required()"
            name="name"
            class="ml-2"
          />
        </template>
        <template v-else>
          <TextField
            v-model="formData.name"
            :placeholder="$t('First name')"
            :rules="fieldValidators.required()"
            name="name"
            class="mr-2"
          />
          <TextField
            v-model="formData.surname"
            :placeholder="$t('Last name')"
            :rules="fieldValidators.required()"
            name="surname"
            class="ml-2"
          />
        </template>
      </div>
    </div>
    <div v-if="formData.nationality === 'JPN' && $i18next.isJapanese()" class="w-full">
      <label class="mb-2 block text-small font-semibold">
        フリガナ
        <span class="text-[#272727]">*</span>
      </label>
      <div class="flex">
        <TextField
          v-model="formData.familyNameKana"
          :rules="combineFieldValidators([fieldValidators.required(), fieldValidators.katakana()])"
          placeholder="セイ"
          name="familyNameKana"
          class="mr-2"
        />
        <TextField
          v-model="formData.givenNameKana"
          :rules="combineFieldValidators([fieldValidators.required(), fieldValidators.katakana()])"
          placeholder="メイ"
          name="givenNameKana"
          class="ml-2"
        />
      </div>
    </div>
    <DateField
      v-model="formData.birthDate"
      :label="$t('Date of birth')"
      :placeholder="$t('Please select')"
      :rules="fieldValidators.required()"
      showDate="1985-01-01"
      name="birthDate"
    />
    <RadioField
      v-model="formData.gender"
      :label="$t('Gender')"
      :options="[
        { value: 'M', label: $t('Male') },
        { value: 'F', label: $t('Female') },
      ]"
      :rules="fieldValidators.required()"
      name="gender"
    />
    <TextField
      v-model="formData.telephone"
      :rules="combineFieldValidators([fieldValidators.required(), fieldValidators.phone()])"
      :label="$t('Phone number')"
      placeholder="0123456789"
      name="telephone"
    />
    <template v-if="formData.nationality === 'JPN'">
      <TextField
        v-model="formData.postalCode"
        @change="checkZipCode"
        :label="capitalize($t('post code'))"
        placeholder="1234567"
        type="numbeer"
        name="postalCode"
      />
    </template>
    <TextField
      v-model="formData.address"
      :rules="fieldValidators.required()"
      :label="$t('Address')"
      :placeholder="$t('Street, City, State, Country')"
      name="address"
    />
    <SelectField
      v-model="formData.occupation"
      :label="$t('Occupation')"
      :options="occupation_list"
      name="occupation"
    />
    <TextField
      v-if="isEmployeeByOccupation(formData.occupation)"
      v-model="formData.company"
      :label="$t('Company')"
      placeholder="aipass inc."
      name="company"
      :hint="$t('If you would like a receipt with your corporate name, please fill it out')"
    />
    <TextField
      v-if="isOtherByOccupation(formData.occupation)"
      v-model="formData.otherOccupation"
      :label="$t('Other')"
      name="otherOccupation"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import formValidatorsMixin from '@/mixins/formValidatorsMixin.vue'
import userOccupationMixin, { OCCUPATION_LIST } from '@/mixins/userOccupationMixin.vue'
import { capitalize } from '@/utils/text'

export default {
  name: 'RegistrationFormView',
  mixins: [formValidatorsMixin, userOccupationMixin],
  props: {
    modelValue: { type: Object, required: true },
  },
  data() {
    return {
      formData: {
        nationality: 'JPN',
        name: '',
        surname: '',
        birthDate: null,
        gender: 'M',
        telephone: '',
        address: '',
        occupation: '会社員',
        company: '',
        postalCode: null,
        otherOccupation: '',
      },
    }
  },
  computed: {
    ...mapGetters(['userData']),
    occupation_list() {
      return Object.keys(OCCUPATION_LIST).map(key => ({
        value: key,
        label: this.$t(OCCUPATION_LIST[key]),
      }))
    },
  },
  beforeMount() {
    this.initFormData()
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        return this.$emit('update:modelValue', this.formData)
      },
    },
  },
  methods: {
    capitalize,
    ...mapActions(['fetchZipCode']),
    initFormData() {
      const selectedReservation = JSON.parse(sessionStorage.getItem('selectedReservation'))

      this.formData.nationality = this.modelValue?.nationality || this.userData?.nationality

      this.formData.name = this.modelValue?.name || this.getFirstName(this.userData?.name)

      this.formData.surname = this.modelValue?.surname || this.getSurname(this.userData?.name)

      const guestNameKana = selectedReservation?.guestNameKana
      const guestFamilyNameKana = guestNameKana?.split(' ')[0]
        ? this.convertToNameKatakana(guestNameKana?.split(' ')[0])
        : ''
      const guestGivenNameKana = guestNameKana?.split(' ')[1]
        ? this.convertToNameKatakana(guestNameKana?.split(' ')[1])
        : ''

      this.formData.familyNameKana =
        this.modelValue?.familyNameKana ||
        (this.userData?.nameKana ? this.userData.nameKana.split(' ')[0] : '') ||
        guestFamilyNameKana

      this.formData.givenNameKana =
        this.modelValue?.givenNameKana ||
        (this.userData?.nameKana ? this.userData.nameKana.split(' ')[1] : '') ||
        guestGivenNameKana

      this.formData.birthDate = this.modelValue?.birthDate || this.userData?.birthDate
      this.formData.gender = this.modelValue?.gender || this.userData?.gender
      this.formData.telephone =
        this.modelValue?.telephone || this.userData?.telephone || selectedReservation?.guestTel
      this.formData.address =
        this.modelValue?.address || this.userData?.address || selectedReservation?.guestAddr
      this.formData.occupation =
        this.modelValue?.occupation || this.userData?.occupation || this.formData?.occupation

      if (this.isOtherByOccupation(this.formData.occupation)) {
        this.formData.otherOccupation =
          this.modelValue?.otherOccupation || this.userData?.otherOccupation
      }
      if (this.isEmployeeByOccupation(this.formData.occupation)) {
        this.formData.company = this.modelValue?.company || this.userData?.company
      }

      this.formData.postalCode = this.modelValue?.postalCode || this.userData?.postalCode
    },
    convertToNameKatakana(text) {
      return text.replace(/[\u3041-\u3096]/g, t => String.fromCharCode(t.charCodeAt(0) + 96))
    },
    getFirstName(fullName) {
      if (!fullName) return ''
      if (this.$i18next.isJapanese()) return fullName.split(' ')[1] || ''

      return fullName.split(' ')[0] || ''
    },
    getSurname(fullName) {
      if (!fullName) return ''
      if (this.$i18next.isJapanese()) return fullName.split(' ')[0] || ''

      return fullName.split(' ')[1] || ''
    },
    async checkZipCode(event) {
      try {
        const zipCode = event.target.value

        if (!zipCode) return

        const response = await this.fetchZipCode(zipCode)
        const address = response?.data?.results?.[0]

        if (address) {
          this.formData.address = `${address.address1}${address.address2}${address.address3}`
        }
      } catch (err) {
        console.warn(err)
      }
    },
  },
}
</script>
