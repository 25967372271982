<script>
import { Field } from 'vee-validate'

import FieldMixin from '@/components/UI/formFields/mixins/FieldMixin.vue'

export default {
  mixins: [FieldMixin],
  components: { Field },
  props: {
    mask: String,
    content: String,
  },
}
</script>

<template>
  <div class="w-full flex flex-col mb-6">
    <Field v-bind="$attrs" v-slot="{ field, errorMessage, meta }" :name="name" :rules="rules">
      <label v-if="label" class="mb-2 text-small font-semibold" :for="name">
        {{ label }}
        <span v-if="is_required" class="text-[#272727]">*</span>
      </label>
      <div class="flex flex-wrap items-stretch w-full relative">
        <div v-if="content" class="mt-4 text-sm leading-6" v-html="content" />
        <slot name="prepend" />
        <textarea
          v-if="mask"
          v-bind="{ ...field, ...$attrs }"
          v-mask="mask"
          :class="{
            'border-red-500': !meta.valid && meta.validated,
          }"
          :disabled="disabled"
          class="w-[100%] p-4 border appearance-none focus:outline-none border-slate-300 rounded-md touch-none mt-4"
        />
        <textarea
          v-else
          v-bind="{ ...field, ...$attrs }"
          :class="{
            'border-red-500': !meta.valid && meta.validated,
          }"
          :disabled="disabled"
          class="w-[100%] p-4 border appearance-none focus:outline-none border-slate-300 rounded-md touch-none mt-4"
        />
      </div>
      <div class="mt-2 font-semibold text-red-500" v-if="errorMessage">{{ errorMessage }}</div>
    </Field>
  </div>
</template>
