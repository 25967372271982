import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'
import I18next from '../i18n'
import HomeView from '../views/HomeView.vue'
import MyPageView from '../views/myPage/MyPageView.vue'
import MemberInfoView from '../views/myPage/MemberInfoView.vue'
import CheckInListView from '../views/myPage/checkin/CheckInListView.vue'
import LoginView from '../views/login/LoginView.vue'
import EmailLoginView from '../views/login/EmailLoginView.vue'
import ResetPasswordView from '../views/login/ResetPasswordView.vue'
import ResetPasswordConfirmView from '../views/login/ResetPasswordConfirmView.vue'
import RegistrationFormView from '../views/login/RegistrationFormView.vue'
import TravelGuideView from '../views/aroundSpot/TravelGuideListView.vue'
import TravelGuideDetail from '../views/aroundSpot/TravelGuideDetail.vue'
import Auth0Callback from '../views/Auth0Callback.vue'
import TermsOfUseView from '../views/static/TermsOfUseView.vue'
import TermsOfUseHotelView from '../views/static/TermsOfUseHotelView.vue'
import PrivacyPolicyView from '../views/static/PrivacyPolicyView.vue'
import SecurityPolicyView from '../views/static/SecurityPolicyView.vue'
import CheckinView from '../views/checkin/CheckinView.vue'
import CheckinReservationSearchView from '../views/checkin/CheckinReservationSearchView.vue'
import ConfirmReservationsListView from '../views/checkin/CheckinReservationsListView.vue'
import CheckinCustomFormView from '../views/checkin/CheckinCustomFormView.vue'
import CheckinConfirmView from '../views/checkin/CheckinConfirmView.vue'
import CheckinEditReservationView from '../views/checkin/CheckinEditReservationView.vue'
import CheckinAcceptView from '@/views/checkin/CheckinAcceptView'
import CheckinInfoView from '@/views/myPage/checkin/CheckinInfoView'
import CheckoutSearchView from '@/views/checkout/CheckoutSearchView'
import CheckoutReviewView from '@/views/checkout/CheckoutReviewView'
import CheckoutThanksView from '@/views/checkout/CheckoutThanksView'
import CreditCardView from '@/views/myPage/creditCard/CreditCardView'
import RegisterCardView from '@/views/myPage/creditCard/RegisterCardView'
import InvoiceView from '@/views/myPage/invoices/InvoicesListView'
import InfoMenuView from '@/views/information/InfoMenuView'
import CheckoutPaymentView from '@/views/checkout/CheckoutPaymentView'
import InvoiceDetailsView from '@/views/myPage/invoices/InvoiceDetailsView'
import DetailInfoView from '@/views/information/DetailInfoView'
import AddressView from '@/views/information/AddressView'
import CheckoutReservationsList from '@/views/checkout/CheckoutReservationsList'
import CheckinPaymentView from '@/views/checkin/CheckinPaymentView'

import ProductsListView from '@/views/request/ProductsListView'
import CartProductsConfirmationView from '@/views/request/CartProductsConfirmationView'
import CartOrderConfirmationView from '@/views/request/CartOrderConfirmationView'
import CartOrderCompleteView from '@/views/request/CartOrderCompleteView'

import WifiView from '@/views/WifiView'
import NotFound from '@/views/404'
import CouponDetailView from '@/views/coupon/CouponDetailView'

import { checkUserDataValid } from '@/utils/validation'

const routes = [
  {
    path: '/',
    name: 'Auth0Callback',
    component: Auth0Callback,
  },
  {
    path: '/hotel-guide',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/hotel-guide/detail',
    name: 'infoMenu',
    component: InfoMenuView,
  },
  {
    path: '/hotel-guide/detail/checkin-out',
    name: 'checkinOutInfo',
    component: DetailInfoView,
    meta: {
      pageIndex: 10,
      getPageTitle: () => I18next.t('Check-in / Check-out'),
    },
  },
  {
    path: '/hotel-guide/detail/wifi',
    name: 'wifiInfo',
    component: DetailInfoView,
    meta: {
      pageIndex: 3,
      getPageTitle: () => I18next.t('Wi-Fi'),
    },
  },
  {
    path: '/hotel-guide/detail/meal',
    name: 'foodInfo',
    component: DetailInfoView,
    meta: {
      pageIndex: 7,
      getPageTitle: () => I18next.t('Breakfast and dinner venue'),
    },
  },
  {
    path: '/hotel-guide/detail/bath',
    name: 'bathInfo',
    component: DetailInfoView,
    meta: {
      pageIndex: 8,
      getPageTitle: () => I18next.t('Public bath'),
    },
  },
  {
    path: '/hotel-guide/detail/equipment',
    name: 'facilityInfo',
    component: DetailInfoView,
    meta: {
      pageIndex: 9,
      getPageTitle: () => I18next.t('Facility'),
    },
  },
  {
    path: '/hotel-guide/detail/floor',
    name: 'floorGuide',
    component: DetailInfoView,
    meta: {
      pageIndex: 5,
      getPageTitle: () => I18next.t('Floor Guide'),
    },
  },
  {
    path: '/hotel-guide/detail/notice',
    name: 'notices',
    component: DetailInfoView,
    meta: {
      pageIndex: 4,
      getPageTitle: () => I18next.t('Notice'),
    },
  },
  {
    path: '/hotel-guide/detail/access',
    name: 'address',
    component: AddressView,
  },
  {
    path: '/hotel-guide/detail/terms-of-use',
    name: 'hotelTermsOfUse',
    component: DetailInfoView,
    meta: {
      pageIndex: 6,
      getPageTitle: () => I18next.t('Terms and Conditions'),
    },
  },
  {
    path: '/checkout/payment',
    name: 'checkoutPayment',
    component: CheckoutPaymentView,
  },

  {
    path: '/checkout',
    name: 'checkoutSearch',
    component: CheckoutSearchView,
  },
  {
    path: '/checkout/select-reservation',
    name: 'confirmCheckoutReservations',
    component: CheckoutReservationsList,
  },
  {
    path: '/checkout/select-checkin',
    name: 'confirmCheckoutCheckins',
    component: CheckoutReservationsList,
  },
  {
    path: '/checkout/review',
    name: 'checkoutReview',
    component: CheckoutReviewView,
  },
  {
    path: '/checkout/thanks',
    name: 'checkoutThanks',
    component: CheckoutThanksView,
  },
  {
    path: '/checkin',
    name: 'checkIn',
    component: CheckinView,
    meta: { prepareUserData: true },
  },
  {
    path: '/leaflet-checkin',
    name: 'leafletCheckIn',
    component: CheckinView,
    meta: { prepareUserData: true },
  },
  {
    path: '/checkin-reservation-search',
    name: 'reservationSearch',
    component: CheckinReservationSearchView,
  },
  {
    path: '/checkin-reservation-confirm',
    name: 'confirmReservationsList',
    component: ConfirmReservationsListView,
  },
  {
    path: '/custom-checkin',
    name: 'checkinCustomForm',
    component: CheckinCustomFormView,
    meta: { requiresAuth: true },
  },
  {
    path: '/checkin-payment',
    name: 'checkinPayment',
    component: CheckinPaymentView,
    meta: { requiresAuth: true },
  },
  {
    path: '/checkin-confirm',
    name: 'checkinConfirm',
    component: CheckinConfirmView,
    meta: { requiresAuth: true },
  },
  {
    path: '/checkin-edit',
    name: 'checkinEdit',
    component: CheckinEditReservationView,
    meta: { requiresAuth: true },
  },
  {
    path: '/checkin-accept',
    name: 'checkinAccept',
    component: CheckinAcceptView,
    meta: { requiresAuth: true },
  },
  {
    path: '/hotel-guide',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    props: route => ({ redirectTo: route.redirectedFrom }),
  },
  {
    path: '/login-email',
    name: 'emailLogin',
    component: EmailLoginView,
  },
  {
    path: '/forget-password',
    name: 'resetPassword',
    component: ResetPasswordView,
  },
  {
    path: '/reset-password/:token',
    name: 'resetPasswordConfirm',
    component: ResetPasswordConfirmView,
  },
  {
    path: '/register-account',
    name: 'registrationForm',
    component: RegistrationFormView,
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage',
    name: 'myPage',
    component: MyPageView,
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/account',
    name: 'memberInfoView',
    component: MemberInfoView,
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/checkin-history',
    name: 'CheckInListView',
    component: CheckInListView,
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/checkin-history/:id',
    name: 'checkinInfoView',
    component: CheckinInfoView,
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/credit-card',
    name: 'creditCard',
    component: CreditCardView,
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/credit-card/register',
    name: 'registerCard',
    component: RegisterCardView,
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/usage-detail',
    name: 'invoice',
    component: InvoiceView,
    meta: { requiresAuth: true },
  },
  {
    path: '/mypage/usage-detail/:id',
    name: 'invoiceDetails',
    component: InvoiceDetailsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/around-spot',
    name: 'TravelGuideView',
    component: TravelGuideView,
  },
  {
    path: '/around-spot/detail',
    name: 'TravelGuideDetail',
    component: TravelGuideDetail,
  },
  {
    path: '/request',
    name: 'productsListView',
    component: ProductsListView,
  },
  {
    path: '/request/product-confirmation',
    name: 'cartProductsConfirmationView',
    component: CartProductsConfirmationView,
  },
  {
    path: '/request/order-confirmation',
    name: 'cartOrderConfirmationView',
    component: CartOrderConfirmationView,
  },
  {
    path: '/request/complete',
    name: 'cartOrderCompleteView',
    component: CartOrderCompleteView,
  },
  {
    path: '/wifi',
    name: 'wifi',
    component: WifiView,
  },
  {
    path: '/coupon/:id',
    name: 'couponDetailView',
    component: CouponDetailView,
    meta: { prepareUserData: true },
  },
  {
    path: '/about-terms-of-use-hotel',
    name: 'termsOfUseHotel',
    component: TermsOfUseHotelView,
    meta: { hotelFreePage: true },
  },
  {
    path: '/about-terms-of-use',
    name: 'termsOfUse',
    component: TermsOfUseView,
    meta: { hotelFreePage: true },
  },
  {
    path: '/about-privacy-policy',
    name: 'PrivacyPolicyView',
    component: PrivacyPolicyView,
    meta: { hotelFreePage: true },
  },
  {
    path: '/about-security-policy',
    name: 'SecurityPolicyView',
    component: SecurityPolicyView,
    meta: { hotelFreePage: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)

  const hotelId = to.query.hotelId || localStorage?.getItem('hotel_id')
  const lang = to.query.lang || localStorage?.getItem('app_lang') || process.env.VUE_APP_I18N_LOCALE || 'ja'
  const authToken = localStorage?.getItem('access_token')
  const routerAuthCheck = to.matched.some(route => route.meta.requiresAuth)
  const prepareUserData = to.matched.some(route => route.meta.prepareUserData)
  const hotelFreePage = to.matched.some(route => route.meta.hotelFreePage)

  let hotelGuideData = store.getters.hotelGuideData
  let userData = store.getters.userData

  const nextWithQuery = (route = to) => {
    store.dispatch('setMainLoading', false)

    if (!route.query?.hotelId || !route.query?.lang) {
      next({ ...route, query: { hotelId, lang, ...to.query } })
    } else {
      next()
    }
  }

  const redirectToLogin = () => {
    return nextWithQuery({ name: 'login' })
  }

  const redirectToRegistration = () => {
    return nextWithQuery({ name: 'registrationForm' })
  }

  const loadHotelGuide = async () => {
    try {
      return await store.dispatch('getHotelGuide', hotelId)
    } catch (error) {
      store.dispatch('globalErrorHandler', hotelId)
      return redirectTo404()
    }
  }

  const loadUserData = async () => {
    try {
      if (prepareUserData && !authToken) return

      store.dispatch('setMainLoading', true)

      await store.dispatch('getUserInfo')

      userData = store.getters.userData
    } catch (error) {
      if (prepareUserData) return
      if (error.response?.status === 401) return redirectToLogin()
      else store.dispatch('globalErrorHandler', error)
    } finally {
      store.dispatch('setMainLoading', false)
    }
  }

  if (to.name === 'NotFound') return next()
  if (hotelFreePage) return next()
  if (!hotelId) return redirectTo404()
  if (!to.query?.hotelId || !to.query?.lang) return nextWithQuery()
  if (!hotelGuideData) hotelGuideData = await loadHotelGuide()
  if (!hotelGuideData) return redirectTo404()

  if (await ifNecessaryRedirectToGuestApp(hotelId, to)) return

  localStorage?.setItem('hotel_id', hotelId)

  if (!routerAuthCheck && !prepareUserData && to.name !== 'registrationForm') return nextWithQuery()
  if (!authToken && routerAuthCheck) return redirectToLogin()
  if (!userData) await loadUserData()
  if (userData && !checkUserDataValid(userData) && to.name !== 'registrationForm') {
    return redirectToRegistration()
  }

  return nextWithQuery()
})

const ifNecessaryRedirectToGuestApp = async (hotelId, to) => {
  if (!store.getters.hasGuestAppPlugin || to.name !== 'productsListView') {
    // request only
    return false
  }

  const assets = (await store.dispatch('getHotelAsset', hotelId)) || []
  const requestAssetIdx = assets.findIndex(asset => asset.formatType === 'request')

  if (requestAssetIdx === -1) {
    // request only
    return false
  }

  const replaceMapping = {
    checkIn: '/checkin',
    checkoutSearch: '/checkout',
    productsListView: requestAssetIdx !== -1 ? `/request/${assets[requestAssetIdx].id}` : '/',
  }
  const guestAppUrl = process.env.VUE_APP_GUEST_APP_URL
  const guestAppPath = Object.keys(replaceMapping).includes(to.name) ? replaceMapping[to.name] : '/'
  const queryString = new URLSearchParams(to.query).toString()

  window.location.replace(`${guestAppUrl}${guestAppPath}?${queryString}`)
  return true
}

export const redirectTo404 = () => {
  window.location.replace('/404')
}

export default router
