<template>
  <div>
    <div
      v-if="activityData"
      class="max-w-[600px] mx-auto mt-10 flex flex-wrap justify-between items-start"
    >
      <RouterLinkControl
        v-for="(item, index) of travel_guideList"
        :key="index"
        :style="{ backgroundImage: 'url(' + item.image + ')' }"
        :componentName="item.url ? undefined : item.component"
        :link="item.url"
        :queryParams="item.queryParams"
        :class="{
          'w-[calc(33.3%-8px)] pb-[calc(33.3%-8px)] relative rounded-lg bg-center bg-cover':
            index > 0,
          'basis-full pb-[50%] mb-3 rounded-lg relative bg-center bg-cover': index === 0,
        }"
        class="hover:opacity-90"
        :target="item.url ? '_blank' : '_self'"
      >
        <div
          class="w-full h-[45px] px-2 absolute bottom-0 rounded-b-lg bg-gradient-to-t from-neutral-500 flex justify-start items-center"
        >
          <img :src="item.icon" alt="activity" />
          <p class="ml-2 text-white font-lato font-bold text-sm truncate">{{ item.title }}</p>
        </div>
      </RouterLinkControl>
    </div>
    <div class="flex justify-center items-center">
      <RouterLinkControl
        :queryParams="{ category: 0 }"
        componentName="TravelGuideView"
        class="mt-8 w-[260px] h-[44px] font-lato text-amber text-sm flex justify-center items-center border border-amber rounded-full"
      >
        {{ $t('More') }}
      </RouterLinkControl>
    </div>
  </div>
</template>

<script>
import RouterLinkControl from '@/components/controls/RouterLinkControl'
import { capitalize } from '@/utils/text'

export default {
  name: 'TravelGuideBlock',
  components: {
    RouterLinkControl,
  },
  props: {
    activityData: {
      type: Object,
    },
  },
  computed: {
    //todo add components
    travel_guideList() {
      return [
        {
          title: capitalize(this.$t('popular')),
          icon: require('@/assets/images/travelGuide/star_icon.svg'),
          image: this.activityData.facilityRecommendImageId
            ? this.activityData.facilityRecommendImageId
            : require('@/assets/images/travelGuide/popular.jpg'),
          component: 'TravelGuideView',
          queryParams: { category: 'popular' },
          url: this.activityData.facilityRecommendLinkUrl,
        },
        {
          title: capitalize(this.$t('food')),
          icon: require('@/assets/images/travelGuide/eat_icon.svg'),
          image: this.activityData.foodImageId
            ? this.activityData.foodImageId
            : require('@/assets/images/travelGuide/food.jpg'),
          component: 'TravelGuideView',
          queryParams: { category: 1 },
        },
        {
          title: capitalize(this.$t('activity')),
          icon: require('@/assets/images/travelGuide/activity_icon.svg'),
          image: this.activityData.playImageId
            ? this.activityData.playImageId
            : require('@/assets/images/travelGuide/play.jpg'),
          component: 'TravelGuideView',
          queryParams: { category: 2 },
        },
        {
          title: capitalize(this.$t('shopping')),
          icon: require('@/assets/images/travelGuide/buy_icon.svg'),
          image: this.activityData.buyImageId
            ? this.activityData.buyImageId
            : require('@/assets/images/travelGuide/buy.jpg'),
          component: 'TravelGuideView',
          queryParams: { category: 3 },
        },
      ]
    },
  },
}
</script>
