<template>
  <MainLayout
    :title="capitalize($t('register'))"
    :backRoute="back_route"
    :hideBackRoute="hide_back_route"
  >
    <Form v-slot="{ meta }" @submit="onSubmit" class="flex flex-col items-center">
      <UserForm v-model="formData" />
      <Button :loading="loadingAction" :disabled="!meta.valid" type="submit" class="mt-4 mb-4">
        {{ $t('Registration') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'

import MainLayout from '@/components/layouts/MainLayout'
import UserForm from '@/components/blocks/UserForm.vue'
import formValidatorsMixin from '@/mixins/formValidatorsMixin.vue'
import { capitalize } from '@/utils/text'
import { checkUserDataValid } from '@/utils/validation'

export default {
  name: 'RegistrationFormView',
  mixins: [formValidatorsMixin],
  components: {
    MainLayout,
    Form,
    UserForm,
  },
  data() {
    return {
      loadingAction: false,
      formData: {},
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'checkoutReservations',
      'checkoutCheckins',
      'loginRedirect',
      'loginFrom',
    ]),
    back_route() {
      return this.loginFrom?.name || 'home'
    },
    hide_back_route() {
      return !checkUserDataValid(this.userData)
    },
  },
  methods: {
    capitalize,
    ...mapActions(['updateUserInfo', 'globalErrorHandler']),
    async onSubmit() {
      try {
        this.loadingAction = true

        const formData = {
          ...this.formData,
          name:
            this.$i18next.language === 'ja'
              ? `${this.formData.surname} ${this.formData.name}`
              : `${this.formData.name} ${this.formData.surname}`,
          nameKana: `${this.formData.familyNameKana} ${this.formData.givenNameKana}`,
          telephone: this.formData.telephone.toString(),
          email: this.userData.email,
          picture: this.userData.picture,
          nameLatin: this.userData.nameLatin,
        }
        const payload = {
          user: formData,
        }

        await this.updateUserInfo(payload)

        if (this.loginRedirect) {
          return this.$router.replace(this.loginRedirect)
        }

        this.$router.push({ name: 'myPage' })
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loadingAction = false
      }
    },
  },
}
</script>
