<template>
  <div class="mt-16 w-full max-w-[1000px] px-6 py-12 font-yuGothic">
    <h2 class="text-center font-semibold text-xl">aipass Privacy Policy</h2>
    <p class="text-center text-xs my-6 text-slate-600 leading-relaxed">
      Last updated : April 15, 2022
    </p>
    <p class="mb-4 text-sm leading-relaxed">
      aipass Corporation (hereinafter referred to as the "Company") has established the following
      privacy policy (hereinafter referred to as the "Policy"). regarding the handling of personal
      information of users in the service aipass (hereinafter referred to as the "Service") provided
      on this website.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 1 (Personal Information)</h3>
    <p class="text-sm leading-relaxed">
      The term "personal information" means information about a living individual that falls under
      any of the following categories
    </p>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        Information that can identify a specific individual by name, address, date of birth, gender,
        occupation, telephone number, email address, etc. included in the information.
      </li>
      <li class="text-xs leading-relaxed">
        Information that, on its own, cannot identify a specific individual, but can be easily
        matched with other information, and through such matching, a specific individual can be
        identified.
      </li>
      <li class="text-xs leading-relaxed">
        Personal Identification Code (Act on the Protection of Personal Information (hereinafter
        referred to as the "Personal Information Protection Act" (Article 2, paragraph 2)).
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 2 (Method of collecting personal information)</h3>
    <p class="text-sm leading-relaxed">
      The company may ask users to provide personal information such as their name, date of birth,
      address, telephone number, email address, bank account number, credit card number, and
      driver's license number when they register to use the site. In addition, The company may
      collect information about transaction records and payments, including personal information of
      users made between users and partners, from our partners. (Including information provider,
      advertiser, advertisement delivery destination, etc.)
    </p>
    <h3 class="font-semibold mt-8 mb-2">
      Article 3 (Purpose of collecting and using personal information)
    </h3>
    <p class="text-sm leading-relaxed">
      The purposes for which the Company collects and uses personal information are as follows
    </p>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        Personal authentication of users and provision of the Service for users
      </li>
      <li class="text-xs leading-relaxed">
        To confirm and respond to opinions, inquiries, and word-of-mouth postings regarding this
        service (including confirmation of identity)
      </li>
      <li class="text-xs leading-relaxed">
        Distribution and sending of communications, email newsletters, direct mail, and various
        notices in connection with the use of this service.
      </li>
      <li class="text-xs leading-relaxed">
        To investigate and confirm the details of users who violate the Terms of Service or who try
        to use the Service for illegal or unjust purposes.
      </li>
      <li class="text-xs leading-relaxed">
        To allow users to view, change, or delete their own registration information and to view
        their usage status
      </li>
      <li class="text-xs leading-relaxed">
        Provision of personal information to companies using the Service based on the user's consent
        or application
      </li>
      <li class="text-xs leading-relaxed">
        Distribution and display of advertisements, contents, etc. based on attribute information,
        terminal information, location information, behavioral history, etc., and provision of the
        Service
      </li>
      <li class="text-xs leading-relaxed">
        Improvement of this service, development of new services, survey, analysis and marketing of
        point usage
      </li>
      <li class="text-xs leading-relaxed">
        Conducting campaigns, questionnaires, monitors, interviews, etc.
      </li>
      <li class="text-xs leading-relaxed">To charge a user for a paid service.</li>
      <li class="text-xs leading-relaxed">Purposes incidental to the above purposes of use</li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 4 (Change of purpose of use)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The Company will change the purpose of use of personal information only when it is
        reasonably recognized that the purpose of use is related to the one before the change.
      </li>
      <li class="text-xs leading-relaxed">
        If the purpose of use is changed, the Company shall notify the user of the changed purpose
        of use by the method prescribed by the Company, or announce it on this website.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">
      Article 5 (Provision of personal information to a third party)
    </h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        As a general rule, the Company will not provide personal information to a third party
        without the consent of the user. The Company will provide the information only when the
        Company have obtained the consent of the user after specifying the recipient and the content
        of the information provided. This information is provided for the purpose of providing
        services such as requesting materials, making applications, making reservations, and making
        purchases to companies using this service.
      </li>
      <li class="text-xs leading-relaxed">
        The items of personal information to be provided shall be information items within the scope
        necessary to achieve the purpose of use among the information obtained from users (including
        service use history and information corresponding to any action history such as browsing,
        searching, bookmarking, etc.). The means or methods of providing the information shall be in
        writing or by sending or transmitting it in an electronic form.
      </li>
      <li class="text-xs leading-relaxed">
        In the following cases, personal information may be provided without the consent of the
        user, to the extent that it does not violate the relevant laws and regulations.
        <ol class="list-disc mx-8">
          <li>When the user is judged to be causing disadvantage to a third party</li>
          <li>
            When it is particularly necessary to improve public health or promote the sound growth
            of children, and when it is difficult to obtain the consent of the user himself/herself
          </li>
          <li>
            When it is necessary to cooperate with a national agency, local government, or a person
            entrusted by such an agency or local government in executing the affairs prescribed by
            law, and obtaining the consent of the user is likely to interfere with the execution of
            such affairs.
          </li>
          <li>
            When a court, public prosecutor's office, police department, or other organization with
            similar authority requests the disclosure of personal information.
          </li>
          <li>
            When the user explicitly requests that the information be disclosed or provided to a
            third party
          </li>
          <li>When disclosure or provision is permitted by law</li>
          <li>
            When personal information is provided in connection with the succession of a business
            due to a merger or other reasons, and it is handled within the scope of the purpose of
            use prior to the succession.
          </li>
        </ol>
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">
      Article 6 (Disclaimer regarding provision to third parties)
    </h3>
    <p class="text-sm leading-relaxed">
      The Company will not be held responsible for the acquisition of personal information by third
      parties in the following cases
    </p>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        When the user himself/herself reveals personal information to a company using the functions
        of the Service or by other means. (Please contact the company directly regarding the
        handling of personal information by the company using the Service.)
      </li>
      <li class="text-xs leading-relaxed">
        In the event that the person in question is unexpectedly identified by the information
        entered into this service
      </li>
      <li class="text-xs leading-relaxed">
        In the event that personal information is provided by users on external sites linked to this
        service, and that information is used
      </li>
      <li class="text-xs leading-relaxed">
        When information (ID, password, etc.) that can be used to identify an individual user is
        obtained by someone other than the user himself or herself.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">
      Article 7 (Outsourcing of personal information processing)
    </h3>
    <p class="text-sm leading-relaxed">
      The Company may outsource all or part of the handling of personal information. The Company
      shall be responsible for the handling of personal information by its subcontractors.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 8 (Use of Statistically Processed Data)</h3>
    <p class="text-sm leading-relaxed">
      Based on the personal information that the Company have received, the Company will use the
      statistical data (meaning those stipulated in Article 2, paragraph 9 of the Personal
      Information Protection Law and limited to those that constitute the anonymously processed
      information database, etc. stipulated in Article 2, paragraph 10 of the same law) processed so
      that individuals cannot be identified. The same shall apply hereinafter) may be created. For
      statistical data that does not identify an individual, personal information shall be processed
      in accordance with the standards set forth in the Regulations of the Commission for the
      Protection of Personal Information and measures shall be taken to ensure its security.
      <br />
      When the Company has created the data concerned, the items of personal information contained
      in the anonymized information concerned shall be made public in accordance with the rules of
      the Personal Information Protection Committee, and the Company shall be able to use the data
      concerned without any restrictions. Personal information items include gender, nationality,
      age, state of residence, zip code, accommodation reservation, accommodation history, number of
      people accompanying user, and transaction amount.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 9 (Change of personal information, etc.)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        As a general rule, only the user himself/herself can request notification of the "purpose of
        use of personal information", disclosure, correction, addition or deletion of registered
        personal information, suspension of use, and suspension of provision to third parties
        (hereinafter referred to as "change of personal information, etc."). However, the Company
        may not be able to respond to changes in personal information in the following cases
        <ol class="list-disc mx-8">
          <li>
            When there is a risk of harm to the life, body, property or other rights and interests
            of the person or a third party
          </li>
          <li>
            When there is a risk of significant hindrance to the proper execution of the Company's
            business
          </li>
          <li>In case of violation of other laws and regulations</li>
        </ol>
      </li>
      <li class="text-xs leading-relaxed">
        Notwithstanding the provisions of the preceding paragraph, as a general rule, information
        other than personal information, such as history and attribute information, will not be
        disclosed. In addition, the Company may not respond to changes in personal information when
        it is necessary to take alternative measures to protect the rights and interests of users
        when it is difficult to make changes in personal information, such as when a large amount of
        money is required to make such changes, or when it is otherwise difficult to make such
        changes.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">
      Article 10 (Correction and deletion of personal information)
    </h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        Users can request the correction, addition, or deletion(hereinafter referred to as
        "correction, etc.") of their personal information to our company when their personal
        information held by our company is incorrect information.
      </li>
      <li class="text-xs leading-relaxed">
        In the event that the Company receives a request from a user as described in the preceding
        paragraph and determines that it is necessary to respond to the request, the Company will
        correct, etc. the personal information concerned without delay.
      </li>
      <li class="text-xs leading-relaxed">
        When a correction is made under the preceding paragraph, or a decision is made not to make a
        correction, etc., the Company will notify the user of the correction without delay.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">
      Article 11 (Suspension of use of personal information, etc.)
    </h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        If the Company receives a request from an individual that personal information is being
        handled beyond the scope of the purpose of use or that it has been obtained by wrongful
        means, the Company will suspend the use or delete the information (hereinafter referred to
        as "suspension of use, etc."). In the event of a request for anonymity (e.g., in the case of
        anonymity), the Company will conduct the necessary investigation without delay.
      </li>
      <li class="text-xs leading-relaxed">
        If, based on the results of the investigation set forth in the preceding paragraph, the
        Company determine that it is necessary to respond to the request, the Company will suspend
        the use of the relevant personal information without delay.
      </li>
      <li class="text-xs leading-relaxed">
        In the event of suspension of use, etc. pursuant to the provisions of the preceding
        paragraph, or when the Company decides not to suspend use, etc., the Company will notify the
        user without delay.
      </li>
      <li class="text-xs leading-relaxed">
        Notwithstanding the preceding two paragraphs, when it is difficult to suspend the use of the
        Website, etc., such as when it would cost a large amount of money to suspend the use of the
        Website, etc., and when alternative measures can be taken to protect the rights and
        interests of the User, such alternative measures shall be taken.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 12 (Accuracy of personal information)</h3>
    <p class="text-sm leading-relaxed">
      The Company strive to accurately process the personal information user provide to us. However,
      it is the user's responsibility to ensure that the personal information provided by the user
      is accurate and up-to-date.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 13 (Identity Confirmation)</h3>
    <p class="text-sm leading-relaxed">
      When a member registers for each web service or uses this service, or when a member requests
      the disclosure, correction, deletion, or suspension of use of personal information, the
      Company will confirm the identity of the individual by using information that can be used to
      identify the individual (name, address, telephone number, date of birth, e-mail address,
      membership number, password, etc.). However, the Company will not be held responsible for the
      use of information that can be used to identify an individual by someone other than the
      individual.
    </p>
    <h3 class="font-semibold mt-8 mb-2">
      Article 14 (Acquisition and Use of Attribute Information, Terminal Information, Location
      Information, Activity History, etc.)
    </h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        Among attribute information, device information, location information, behavioral history,
        etc. ("behavioral history, etc."), the Company uses cookies, etc. to protect users' privacy,
        improve convenience, deliver advertising, and obtain statistical data. In addition, cookies,
        JavaScript, and other technologies may be used to obtain, among the information provided,
        attribute information that cannot be used to identify individuals, such as age, gender,
        occupation, and area of residence (such information is limited to information that cannot be
        used to identify individuals even if combined), terminal information, the user's activity
        history on the Service Site (URLs accessed, contents, reference order, etc.), and location
        information based on the user's consent or application when using a smartphone, etc.
        However, cookies and activity history do not contain any personal information.
      </li>
      <li class="text-xs leading-relaxed">
        Cookies on the device remain on the device until user delete them yourself or they have
        expired. Most browsers automatically enable cookie acceptance by default, but user can
        disable cookies by changing your browser settings. However, if user disable cookies, user
        may not be able to use some of the features of the website or some of the pages may not
        display properly.
      </li>
      <li class="text-xs leading-relaxed">
        When a user logs in to use the Service, the Company may use the behavioral history, etc.
        from before the user logged in to deliver and display advertisements, content, etc., and
        provide the Service, after identifying the individual, with respect to access logs, etc. in
        the following cases If user wish to stop receiving these advertisements, etc., please
        contact the personal information management contact.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 15 (Changes to the Privacy Policy)</h3>
    <p class="text-sm leading-relaxed">
      The contents of this policy may be changed without notice to the user, except as otherwise
      provided in laws and regulations or this policy. Unless otherwise specified by the Company,
      the revised Privacy Policy shall take effect from the time it is posted on this website.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 16 (Contact for inquiries)</h3>
    <p class="text-sm leading-relaxed">
      If user have any questions about this policy, please contact at the following
    </p>
    <p class="text-sm leading-relaxed mt-4">
      Address. <br />
      150-0031, Japan <br />
      Shinoda Building 3F, 23-3 Sakuragaokacho, Shibuya-ku, Tokyo
    </p>
    <p class="text-sm leading-relaxed mt-4">
      Company name <br />
      aipass Inc.
    </p>
    <p class="text-sm leading-relaxed mt-4">
      Email address <br />
      support@aipass.inc
    </p>
  </div>
</template>

<script>
export default {
  name: 'EnPrivacyPolicyContent',
}
</script>
