<template>
  <MainLayout :title="$t('Check-out')" class="flex flex-column items-center" backRoute="home">
    <Form
      v-slot="{ meta }"
      @submit="onSubmit"
      class="w-full max-w-[600px] flex flex-col items-center"
    >
      <div class="w-full p-8 bg-white flex flex-col justify-start items-center">
        <TextField
          v-model="formData.telephone"
          :rules="combineFieldValidators([fieldValidators.required(), fieldValidators.phone()])"
          :label="$t('Phone number of your reservation')"
          placeholder="08012345678"
          name="telephone"
        />
      </div>
      <Button :disabled="!meta.valid" :loading="loadingAction" class="mb-4" type="submit">
        {{ $t('Next') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'

import MainLayout from '@/components/layouts/MainLayout'
import formValidatorsMixin from '@/mixins/formValidatorsMixin.vue'

export default {
  name: 'CheckoutSearchView',
  mixins: [formValidatorsMixin],
  components: {
    MainLayout,
    Form,
  },
  data() {
    return {
      loadingAction: false,
      stayReservations: undefined,
      checkinsList: undefined,
      formData: {
        telephone: '',
      },
    }
  },
  computed: {
    ...mapGetters(['hotelGuideData']),
  },
  mounted() {
    this.clearCheckoutResult()
  },
  methods: {
    ...mapActions([
      'getStayReservations',
      'getCheckinsList',
      'globalErrorHandler',
      'setCheckoutReservations',
      'setCheckoutCheckins',
      'clearCheckoutResult',
    ]),
    async onSubmit() {
      try {
        this.setCheckoutReservations(undefined)
        this.setCheckoutCheckins(undefined)
        this.loadingAction = true

        this.stayReservations = await this.getStayReservations({
          telephone: this.formData.telephone,
        })

        if (this.stayReservations?.length) {
          if (this.stayReservations.some(reservation => reservation.frontSupported)) {
            this.setCheckoutReservations(this.stayReservations)
            return this.$router.push({ name: 'checkoutThanks' })
          }
          return this.$router.push({
            name: 'confirmCheckoutReservations',
            query: { telephone: this.formData.telephone },
          })
        }

        this.checkinsList = await this.getCheckinsList({
          telephone: this.formData.telephone,
        })

        let checkinDataListStayStatus = this.checkinsList.filter(v => v.approvedStatus === 2)
        if (checkinDataListStayStatus?.length) {
          if (this.checkinsList.some(checkin => checkin.frontSupported)) {
            this.setCheckoutCheckins(this.checkinsList)
            return this.$router.push({ name: 'checkoutThanks' })
          }
          return this.$router.push({
            name: 'confirmCheckoutCheckins',
            query: { telephone: this.formData.telephone },
          })
        } else {
          this.globalErrorHandler({
            error: this.$t('Registration information not found. Please try again.'),
          })
        }
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loadingAction = false
      }
    },
  },
}
</script>
