<template>
  <div class="mt-16 w-full max-w-[1000px] px-6 py-12 font-yuGothic">
    <h2 class="text-center font-semibold text-xl">aipass Terms of Service｜Facility Operators</h2>
    <p class="text-center text-xs my-6 text-slate-600 leading-relaxed">
      Last update: April 15, 2022
    </p>
    <p class="mb-4 text-sm leading-relaxed">
      This aipass Terms of Service Agreement (hereinafter referred to as the "Agreement") This
      aipass Service Terms of Use (hereinafter referred to as the "Terms of Use") applies to the use
      of the aipass service provided by aipass Corporation (hereinafter referred to as the
      "Company"). (hereinafter referred to as "the Company") and the relationship of rights and
      obligations between the Company and registered users. If you are a registered user of the
      service, please be sure to read the entire agreement before agreeing to it.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 1 (Application)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The purpose of this Agreement is to define the relationship of rights and obligations
        between the Company and registered users (defined in Article 2) regarding the use of the
        Service (defined in Article 2), and it applies to all relationships between registered users
        and the Company regarding the use of the Service.
      </li>
      <li class="text-xs leading-relaxed">
        The rules, regulations, etc. regarding the Service posted by the Company on the Company's
        website (defined in Article 2) from time to time shall constitute a part of these Terms.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 2 (Definitions)</h3>
    <p class="text-sm leading-relaxed">
      The following terms used in these Terms shall have the meanings set forth below.
    </p>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        "External Service" means a service provided by another service provider and prescribed by
        the Company, which is linked to the Service.
      </li>
      <li class="text-xs leading-relaxed">
        "External Service Provider" means the service provider of External Service.
      </li>
      <li class="text-xs leading-relaxed">
        "External Terms of Use" means the terms that define the rights and obligations of External
        Services.
      </li>
      <li class="text-xs leading-relaxed">
        If there are any changes to the registered information, the registered user shall notify the
        Company of such changes without delay in accordance with the method specified by the
        Company, and shall submit the materials requested by the Company.
      </li>
      <li class="text-xs leading-relaxed">
        "Our website" shall mean the website operated by us with the domain name "aipass.jp" (if the
        domain name or content of our website is changed for any reason, the website after such
        change shall be included). (In the event that the domain or content of the Company's website
        is changed for any reason, the website after such change shall be included.
      </li>
      <li class="text-xs leading-relaxed">
        "Prospective User" shall mean "Prospective User" as defined in Article 3.
      </li>
      <li class="text-xs leading-relaxed">
        "Registered Information" means the "Registered Information" as defined in Article 3.
      </li>
      <li class="text-xs leading-relaxed">
        "Registered User" means an individual or corporation who has registered as a user of the
        Service in accordance with Article 3.
      </li>
      <li class="text-xs leading-relaxed">
        The "Service" means the hotel check-in related service provided by the Company under the
        name "aipass" (if the name or content of the service is changed for any reason, the service
        after such change is included). The term "Data, etc." shall mean the hotel check-in related
        service named aipass (including the service after such change if the name or content of the
        service is changed for any reason) provided by the Company.
      </li>
      <li class="text-xs leading-relaxed">
        The term "Data, etc." shall mean the data and information provided by Fuji Xerox in the
        Service.
      </li>
      <li class="text-xs leading-relaxed">
        "Usage Contract" means the usage contract for the Service formed between the Company and the
        Registered User in accordance with Article 3.4, and in accordance with the provisions of
        these Terms.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 3 (Application for Use)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        Those who wish to use the Service (hereinafter referred to as "User Applicants") shall
        comply with these Terms. A person who wishes to use the Service (hereinafter referred to as
        a "User Applicant") shall agree to comply with these Terms of Use and provide certain
        information (hereinafter referred to as "Registration Information") to the Company in a
        manner prescribed by the Company. (hereinafter referred to as the "Service Applicant") may
        apply to use the Service by agreeing to comply with these Terms and providing certain
        information (hereinafter referred to as the "Registration Information") to the Company in a
        manner prescribed by the Company.
      </li>
      <li class="text-xs leading-relaxed">
        The application for use of the Service must be made by the individual or corporation who
        wishes to use the Service, and as a general rule, applications made by agents are not
        accepted. In addition, when applying to use the service, the applicant must provide the
        Company with true, accurate, and up-to-date information.
      </li>
      <li class="text-xs leading-relaxed">
        The Company may reject the application of a person who has applied for use in accordance
        with Paragraph 1 if the person falls under any of the following items.
        <ol class="list-alpha mx-8">
          <li>When the Company judges that there is a possibility of violating this Agreement.</li>
          <li>
            When there is a falsehood, error, or omission in all or part of the registration
            information provided to the Company.
          </li>
          <li>If the registration for the use of this service has been cancelled in the past.</li>
          <li>
            The user is a minor, an adult ward, a person under curatorship, or a person under
            assistance, and has not obtained the consent of a legal representative, guardian,
            curator, or assistant.
          </li>
          <li>
            Antisocial forces, etc. (meaning organized crime groups, organized crime group members,
            right-wing groups, antisocial forces, and other similar parties. The same shall apply
            hereinafter. In the event that the Company judges that the person is an antisocial
            force, etc. (meaning organized crime groups, right-wing groups, antisocial forces, or
            other similar persons; the same shall apply hereinafter), or that the person has some
            kind of interaction or involvement with antisocial forces, etc. such as cooperating with
            or being involved in the maintenance, operation, or management of antisocial forces,
            etc. through the provision of funds or other means.
          </li>
          <li>In any other cases where the Company deems registration to be inappropriate.</li>
        </ol>
      </li>
      <li class="text-xs leading-relaxed">
        In accordance with the preceding paragraph and other standards of the Company, the Company
        shall determine whether or not the registration of a user applicant is acceptable, and if
        the Company approves the registration, the Company shall notify the user applicant to that
        effect. With such notification, the registration of the user applicant as a registered user
        shall be completed, and a contract for the use of the Service in accordance with the
        provisions of this Agreement (hereinafter referred to as the "Usage Contract") shall be
        concluded between the registered user and the Company. A contract for the use of the Service
        in accordance with the provisions of this Agreement (hereinafter referred to as the "Usage
        Contract") shall be established between the Registered User and the Company.
      </li>
      <li class="text-xs leading-relaxed">
        In the event of any changes to the registered information, the registered user shall,
        without delay, notify the Company of such changes in accordance with the method prescribed
        by the Company, and submit the materials requested by the Company.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 4 (Use of the Service)</h3>
    <p class="mb-4 text-sm leading-relaxed">
      Registered Users may use the Service in accordance with the Terms of Use and in accordance
      with the methods specified by the Company during the effective period of the User Agreement.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 5 (Re-consignment of Business)</h3>
    <p class="mb-4 text-sm leading-relaxed">
      The Company may subcontract all or part of the work related to the Service to other third
      parties.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 6 (Usage Fees)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The registered user shall pay the usage fee for the Service as compensation for using the
        Service. The amount, method of payment, and timing of payment of the usage fee shall be
        separately presented to the registered user by the Company in the form of a fee schedule,
        etc., and the registered user shall make payment in accordance with this.
      </li>
      <li class="text-xs leading-relaxed">
        The registered user shall bear the costs associated with the payment of the usage fee.
      </li>
      <li class="text-xs leading-relaxed">
        In the event that the registered user delays payment of the usage fee, the registered user
        shall pay the Company a late fee at the rate of 14.6% per annum.
      </li>
      <li class="text-xs leading-relaxed">
        The Company shall not, under any circumstances, refund the usage fee once it has been
        received.
      </li>
      <li class="text-xs leading-relaxed">
        In the event that the Company deems it necessary to revise the usage fees due to changes in
        economic conditions or other reasons, the Company may change the fees by notifying the
        registered user at least one month prior to the revision.
      </li>
      <li class="text-xs leading-relaxed">
        If the Company is unable to confirm payment of the usage fee on the due date, the Company
        may cancel the usage contract. In this case, the Company may immediately suspend all of the
        Services without notice to the registered user.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 7 (Trial)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The Company may provide a service (hereinafter referred to as "Trial") that allows users who
        are considering using the Service to use all or part of the Service free of charge for a
        certain period of time separately determined by the Company. However, this does not mean
        that the Company is obligated to provide the trial service.
      </li>
      <li class="text-xs leading-relaxed">
        The Company may impose restrictions on the range of functions and usage of the Service
        provided in the trial.
      </li>
      <li class="text-xs leading-relaxed">
        The person who uses the trial shall use the trial after agreeing to these Terms of Use as a
        registered user.
      </li>
      <li class="text-xs leading-relaxed">
        If the trial user wishes to cancel the Service after the trial period expires, the trial
        user shall apply for cancellation in the manner prescribed by the Company by the expiration
        date of the trial period. If there is no request for cancellation from the trial user, the
        Company shall determine that the trial user is continuing to use the Service and shall
        charge the usage fee stipulated in Article 6 in the method designated by the Company, and
        the trial user shall accept this as a registered user.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 8 (Management of Password and User ID)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        Registered users shall manage and keep their passwords and user IDs at their own risk, and
        shall not allow any third party to use them, nor shall they lend, transfer, change the name
        of, or sell them.
      </li>
      <li class="text-xs leading-relaxed">
        The registered user shall be responsible for any damage caused by inadequate management of
        the password or user ID, errors in use, or use by a third party, and the Company shall not
        be held responsible for any such damage.
      </li>
      <li class="text-xs leading-relaxed">
        In the event that a registered user's password or user ID is found to be stolen or used by a
        third party, the registered user shall immediately notify the Company and follow the
        Company's instructions to that effect.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 9 (Services of External Entities)</h3>
    <p class="mb-4 text-sm leading-relaxed">
      Registered users may be able to use the services of external service providers designated by
      the Company in conjunction with the Service. In this case, the company will present the
      services of the external service provider to the registered user. Partners who wish to use the
      services of such external service providers shall agree to the terms of service of such
      external service providers and use the services of such external service providers in
      accordance with the terms and conditions of each service. The Company does not guarantee that
      the services of such external service providers are free from defects. In addition, the
      Company shall not be liable for any damage caused by the change or termination of the services
      of the relevant external service providers.
    </p>
    <h3 class="font-semibold mt-8 mb-2">
      Article 10 (Handling of Registered User's Trade Name, etc.)
    </h3>
    <p class="mb-4 text-sm leading-relaxed">
      The Company may use the registered user's trade name, trademark, or logo for the Company's
      marketing purposes. In addition, the Company may disclose and publish in general terms the
      information that the Registered User is a user of the Service, the contents distributed using
      the Service, and the measures implemented. However, this shall not apply in cases where the
      registered user has expressed his/her objection in advance.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 11 (Prohibited Acts)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-loose">
        Registered users shall not engage in any of the following acts when using this service.
        <ol class="list-alpha mx-8">
          <li>
            The Registered User shall not engage in any of the following acts when using the
            Service: (i) Inappropriate use of the Service by the Company, other Registered Users,
            outside businesses, or other third parties (including users or prospective users of the
            hotel operated by the Registered User. The same applies hereinafter in this Agreement).
            Acts that infringe the intellectual property rights, portrait rights, rights of privacy,
            honor, or other rights or interests of any third party (including acts that directly or
            indirectly cause such infringement)
          </li>
          <li>
            Acts related to criminal acts or acts that are offensive to public order and morals
          </li>
          <li>Acts of sending obscene information or information harmful to youth.</li>
          <li>Acts of transmitting information related to heterosexual relationships</li>
          <li>
            Acts that violate laws and regulations or the internal rules of the Company or the
            industry group to which the registered user belongs.
          </li>
          <li>
            Sending information that contains computer viruses or other harmful computer programs.
          </li>
          <li>Falsifying information that can be used in relation to this service.</li>
          <li>
            Sending data through this service that exceeds a certain amount of data capacity
            specified by this company.
          </li>
          <li>Acts that may interfere with the operation of this service by the Company.</li>
          <li>
            Conducting the same or similar services as this service by oneself, or having a third
            party conduct the same or similar services as this service.
          </li>
          <li>
            Disclosure of the Data, etc. to the public or a third party, or use of the Data, etc.
            for the purpose of transferring, selling, distributing, or developing the same or
            similar services as the Service.
          </li>
          <li>Any other actions that the Company deems inappropriate.</li>
        </ol>
      </li>
      <li class="text-xs leading-relaxed">
        In the event that the Company determines that an act by a registered user in the Service
        falls under any of the items of the preceding paragraph, or is likely to fall under any of
        the items of the preceding paragraph, the Company may, without prior notice to the
        registered user, suspend all or part of the Service, and delete all or part of the
        information sent by said act. The Company may, without prior notice to the registered user,
        suspend all or part of the Service, and delete all or part of the information sent as a
        result of such action. The Company shall not be liable for any damages incurred by the
        registered user based on the measures taken by the Company in accordance with this section.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 12 (Suspension of the Service, etc.)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        In any of the following cases, the Company may suspend or discontinue all or part of the use
        of the Service without prior notice to the Registered User.
        <ol class="list-alpha mx-8">
          <li>
            In the event of periodic or emergency inspection or maintenance of the computer system
            related to the Service.
          </li>
          <li>In the event of a computer or communication line outage due to an accident.</li>
          <li>
            In the event that the operation of the Service becomes impossible due to force majeure
            such as fire, power outage, or natural disaster
          </li>
          <li>When updating this service.</li>
          <li>
            When there is trouble with external services, interruption or suspension of service
            provision, suspension of cooperation with this service, or changes in specifications,
            etc.
          </li>
          <li>
            In any other cases where the Company deems it necessary to suspend or discontinue the
            Service.
          </li>
        </ol>
      </li>
      <li class="text-xs leading-relaxed">
        The Company may terminate the provision of the Service at the Company's convenience. In this
        case, the Company shall notify the registered user in advance.
      </li>
      <li class="text-xs leading-relaxed">
        The Company shall not be liable for any damages incurred by the registered user based on the
        measures taken by the Company in accordance with this Article.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 13 (Burden of Equipment, etc.)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The preparation and maintenance of computers, software and other equipment, communication
        lines, and other communication environments necessary to receive the Service shall be at the
        expense and responsibility of the registered user.
      </li>
      <li class="text-xs leading-relaxed">
        The registered user shall take security measures, such as prevention of computer virus
        infection, unauthorized access, and information leakage, at his or her own expense and
        responsibility according to the environment in which he or she uses the Service.
      </li>
      <li class="text-xs leading-relaxed">
        The Company is not obligated to save messages and other information sent or received by
        registered users, even if such information is saved for a certain period of time for
        operational purposes, and the Company may delete such information at any time. The Company
        shall not be liable for any damages incurred by the registered user based on the deletion of
        such information.
      </li>
      <li class="text-xs leading-relaxed">
        In the event that the registered user installs software, etc. on the registered user's
        computer, etc. by downloading from the Company's website or other methods at the start of
        use of the Service or during the use of the Service, the registered user shall pay
        sufficient attention to prevent the disappearance or alteration of the information held by
        the registered user or the failure or damage of the equipment, etc., and the Company shall
        not be liable for any such damage incurred by the registered user.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 14 (Ownership of Rights)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The ownership and intellectual property rights of the Company's website, the Service, and
        the Data, etc., all belong to the Company or the party that has granted a license to the
        Company, and the permission to use the Service based on the registration set forth in these
        Terms of Use does not mean the transfer or license of the intellectual property rights of
        the Company or the party that has granted a license to the Company regarding the Company's
        website, the Service, or the Data, etc.
      </li>
      <li class="text-xs leading-relaxed">
        The registered user agrees in advance that the information obtained through the provision of
        the service may be used by the Company for the purpose of improving the service free of
        charge.
      </li>
      <li class="text-xs leading-relaxed">
        Registered users shall not, for any reason whatsoever, perform any act that may infringe on
        the intellectual property rights of the Company or any party that has granted a license to
        the Company (including, but not limited to, disassembly, decompilation, and reverse
        engineering).
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 15 (Handling of the Data, etc.)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The registered user may use the Data, etc. only for the purpose of operating the
        accommodation facility operated by the registered user, and may not use the Data, etc. for
        any other purpose.
      </li>
      <li class="text-xs leading-relaxed">
        Check-in information and data related to check-ins obtained through the use of the Service
        by a registered user shall be stored for a period of three (3) years counting from the date
        of check-in, in accordance with the Ryokan Business Act. However, this shall not apply if
        the registered user cancels his/her registration. In addition, even if this information is
        lost, the Company shall not bear any responsibility.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 16 (Cancellation of Registration, etc.)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        In the event that a registered user falls under any of the following items, the Company may
        temporarily suspend the use of the Service or cancel the registration of the registered user
        without prior notice or demand.
        <ol class="list-disc mx-8">
          <li>In the event of violation of any of the provisions of this Agreement.</li>
          <li>When it is found that there is a false fact in the registration information.</li>
          <li>
            In the event that the user has used or attempted to use the Service for purposes or in a
            manner that may cause damage to the Company, other registered users, outside businesses,
            or other third parties.
          </li>
          <li>
            In the event that the user interferes with the operation of this service by any means
            whatsoever.
          </li>
          <li>
            In the event of suspension of payment or inability to pay, or in the event of a petition
            for commencement of bankruptcy proceedings, commencement of civil rehabilitation
            proceedings, commencement of corporate reorganization proceedings, commencement of
            special liquidation or similar proceedings.
          </li>
          <li>
            In the event that a bill or check drawn or subscribed for by the customer has been
            dishonored
          </li>
          <li>
            When a petition for seizure, provisional seizure, provisional disposition, compulsory
            execution or auction is filed
          </li>
          <li>In the event of delinquent payment of taxes or public dues</li>
          <li>
            In the event of death, or in the event of a trial for commencement of guardianship,
            conservatorship or assistance.
          </li>
          <li>In the event that any of the items in Article 3, Paragraph 3 apply.</li>
          <li>
            In the event that the registered user himself/herself requests the Company to cancel the
            registration by the method prescribed by the Company.
          </li>
          <li>
            In any other case where the Company deems it inappropriate to continue the registration
            as a registered user.
          </li>
        </ol>
      </li>
      <li class="text-xs leading-relaxed">
        If any of the reasons in the preceding paragraph apply, the registered user will naturally
        lose the benefit of time for all debts owed to the Company, and must immediately make
        payment of all debts to the Company.
      </li>
      <li class="text-xs leading-relaxed">
        The Company shall not be liable for any damages incurred by the registered user as a result
        of any action taken by the Company under this Article.
      </li>
      <li class="text-xs leading-relaxed">
        In the event that the registration of a registered user is cancelled in accordance with this
        Article, the registered user shall return, discard, or otherwise dispose of the software,
        manuals, and other items related to the Service that have been provided by the Company in
        accordance with the Company's instructions.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 17 (No Warranty and Disclaimer)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The Company makes no warranty of any kind, including, but not limited to, fitness for
        purpose, commercial usefulness, completeness, accuracy, continuity, or that the use of the
        Service will not infringe upon the rights of any third party.
      </li>
      <li class="text-xs leading-relaxed">
        Even in the event that a registered user obtains any information directly or indirectly from
        the Company regarding the Service, the Company's website, other registered users of the
        Service, persons who intend to use the hotel operated by the registered user, or any other
        matters, the Company does not make any guarantee to the registered user beyond what is
        stipulated in this Agreement.
      </li>
      <li class="text-xs leading-relaxed">
        The registered user shall investigate whether or not the use of the Service violates the
        laws and regulations applicable to the registered user, or the internal rules of industry
        organizations, etc., based on his or her own responsibility and at his or her own expense,
        and the Company does not guarantee in any way that the use of the Service by the registered
        user conforms to the laws and regulations applicable to the registered user, or the internal
        rules of industry organizations, etc.
      </li>
      <li class="text-xs leading-relaxed">
        Any transactions, communications, disputes, etc. that arise between a registered user and
        other registered users or third parties in relation to the Service or the Company's website
        shall be handled and resolved at the registered user's own risk, and the Company shall not
        be held responsible for such matters.
      </li>
      <li class="text-xs leading-relaxed">
        The Company shall not be liable for any damages incurred by the registered user in relation
        to the interruption, suspension, termination, unavailability or change in the provision of
        this service by the Company, deletion or loss of the registered user's information,
        cancellation of the registered user's registration, loss of data due to the use of this
        service, or equipment failure or damage, or any other damages incurred by the registered
        user in relation to this service.
      </li>
      <li class="text-xs leading-relaxed">
        Even when links are provided from the Company's website to other websites or from other
        websites to the Company's website, the Company assumes no responsibility for websites other
        than the Company's website or information obtained from such websites for any reason
        whatsoever.
      </li>
      <li class="text-xs leading-relaxed">
        The Company shall not be liable for any damages incurred by registered users in relation to
        this service. In addition, even if for some reason the Company is liable for damages to a
        registered user notwithstanding the provisions of this section and other provisions
        exempting the Company from liability for damages, the Company's liability for damages shall
        be limited to the total amount of usage fees actually received from the registered user
        during the past three months retroactively from the time when the reason for the damages
        occurred (provided, however, that the Company shall not be liable for damages based on the
        amount of usage fees actually received from the registered user during the past three
        months). However, if the Company has not actually received the usage fee from the registered
        user during the past three months, the Company's liability shall be limited to 1,000 yen).
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">
      Article 18 (User's Responsibility for Compensation, etc.)
    </h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        In the event that a Registered User causes damage to the Company by violating this Agreement
        or in connection with the use of the Service, the Registered User shall compensate the
        Company for such damage.
      </li>
      <li class="text-xs leading-relaxed">
        In the event that a registered user receives a complaint from another registered user,
        outside business, or any other third party in relation to the Service, or has a dispute with
        such party, the registered user shall immediately notify the Company of the content of the
        complaint or dispute, handle the complaint or dispute at the registered user's expense and
        responsibility, and report the progress and results of the dispute to the Company upon
        request from the Company.
      </li>
      <li class="text-xs leading-relaxed">
        If, in relation to the use of the Service by the Registered User, the Company receives a
        claim of any kind from another Registered User, an outside business, or any other third
        party for infringement of rights or any other reason, the Registered User shall compensate
        the Company for the amount that the Company was forced to pay to the third party based on
        such claim.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 19 (Confidentiality)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The Registered User shall keep the information disclosed by the Company in relation to this
        Agreement and the Service (hereinafter referred to as "Confidential Information") as
        confidential and shall take reasonable measures to do so, and shall not disclose or leak
        such information to any third party or use it for any purpose other than the use of the
        Service without the prior consent of the Company. However, the following information shall
        not fall under the category of "Confidential Information".
        <ol class="list-disc mx-8">
          <li>Information that is publicly known at the time it is disclosed by the Company</li>
          <li>
            Information that becomes public knowledge through no fault of the registered users after
            it is disclosed by the Company.
          </li>
          <li>
            Information that has been legally obtained from a third party without any obligation of
            confidentiality
          </li>
          <li>
            Information that has been independently developed without using information disclosed by
            the Company.
          </li>
        </ol>
      </li>
      <li class="text-xs leading-relaxed">
        Notwithstanding the provisions of Section 1, a registered user may disclose confidential
        information only in the following cases. However, in the event that disclosure is necessary,
        the user shall promptly notify the Company to that effect, and the party to whom the
        information is disclosed shall be subject to the same confidentiality obligations as those
        set forth in this document.
        <ol class="list-disc mx-8">
          <li>
            When disclosure of confidential information is required pursuant to laws, regulations,
            notices, or other administrative or judicial procedures.
          </li>
          <li>
            Rating agencies, trust companies, financial institutions, and other parties related to
            the purpose of this case
          </li>
          <li>
            Lawyers, certified public accountants, real estate appraisers, and other professionals
            who are legally obligated to maintain confidentiality.
          </li>
        </ol>
      </li>
      <li class="text-xs leading-relaxed">
        Whenever requested by the Company, the Registered User shall, without delay and in
        accordance with the Company's instructions, return or destroy the Confidential Information,
        any documents or other recorded media containing or describing the Confidential Information,
        and all copies thereof.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 20 (Handling of Personal Information)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        Registered Users shall use personal information (personal information as defined in the Act
        on the Protection of Personal Information, hereinafter referred to as "Personal
        Information") contained in business and other information provided by the Company for the
        purpose of providing the Service only within the scope of the purpose of using the Service,
        and shall not disclose or divulge such information to any third party, and shall comply with
        the relevant laws and regulations, including those related to the protection of personal
        information.
      </li>
      <li class="text-xs leading-relaxed">
        Registered users shall take the utmost security measures to protect their personal
        information from unauthorized access, loss, destruction, falsification, leakage, etc.
        (hereinafter referred to as "leakage, etc."). The registered user shall take the best safety
        measures against the risk of improper access to personal information or loss, destruction,
        falsification, leakage, etc. (hereinafter referred to as "leakage, etc.") of personal
        information, and shall bear all responsibility in the event of leakage, etc.
      </li>
      <li class="text-xs leading-relaxed">
        Regardless of whether or not a registered user falls under the category of a business
        operator handling personal information under the Act on the Protection of Personal
        Information, the registered user shall comply with the obligations as a business operator
        handling personal information stipulated in the Act and other related laws, regulations, and
        rules.
      </li>
      <li class="text-xs leading-relaxed">
        The obligation to maintain confidentiality with respect to personal information shall remain
        in effect even after the termination of this Agreement or the trial without a fixed term.
      </li>
      <li class="text-xs leading-relaxed">
        Registered Users shall obtain the prior written consent of the Company when reproducing
        documents or magnetic recording media containing confidential information, and shall
        strictly manage the reproductions in accordance with Paragraphs 1 through 4.
      </li>
      <li class="text-xs leading-relaxed">
        Whenever requested by the Company, the Registered User shall, without delay and in
        accordance with the Company's instructions, return or destroy the Confidential Information,
        any documents or other recorded media containing or describing the Confidential Information,
        and all copies thereof.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 21 (Term of Validity)</h3>
    <p class="text-sm leading-relaxed">
      The User Agreement shall take effect on the date of completion of the registration of the
      Registered User in accordance with Article 3, and shall remain in effect for the period agreed
      upon between the Company and the Registered User at the time of registration. However, if the
      registration of the said registered user is cancelled, the Terms of Use shall terminate at the
      time the registration is cancelled, and if the provision of the Service is terminated, the
      Usage Agreement shall terminate at the time the provision of the Service is terminated.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 22 (Changes to the Terms, etc.)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        The Company shall be free to change the contents of the Service.
      </li>
      <li class="text-xs leading-relaxed">
        The Company may freely change the contents of the Service. The Company may change the Terms
        (including the rules and regulations regarding the Service posted on the Company's website.
        The Company may change the Terms (including the rules and regulations regarding the Service
        posted on the Company's website; hereinafter the same in this section). The Company may
        change the Terms (including the rules and regulations for the Service posted on the
        Company's website; hereinafter the same in this section). In the event that the Company
        changes the Terms, the Company shall notify the registered user of such changes, and in the
        event that the registered user uses the Service after the notification of such changes or
        does not take procedures for cancellation of registration within the period of time
        specified by the Company, the registered user shall be deemed to have agreed to the changes
        in the Terms.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 23 (Contact / Notification</h3>
    <p class="text-sm leading-relaxed">
      Inquiries about the Service and other communications or notifications from registered users to
      the Company, as well as notifications about changes to the Terms and other communications or
      notifications from the Company to registered users, shall be made in a manner determined by
      the Company.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 24 (Transfer of this Agreement, etc.)</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        Registered Users may not assign, transfer, mortgage, or otherwise dispose of their status
        under the Usage Agreement or rights or obligations under this Agreement to a third party
        without the prior written consent of the Company.
      </li>
      <li class="text-xs leading-relaxed">
        In the event that the Company transfers the business of the Service to another company, the
        Company may transfer the status of the User Agreement, the rights and obligations under
        these Terms of Use, and the registered user's registration information and other customer
        information to the transferee of the transfer. The registered user shall be deemed to have
        agreed to such transfer in advance. The transfer of business set forth in this section shall
        include not only ordinary business transfer, but also corporate separation and any other
        cases in which business is transferred.
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">Article 25 (Complete Agreement)</h3>
    <p class="text-sm leading-relaxed">
      This Agreement constitutes the entire agreement between the Company and the Registered User
      with respect to the matters contained herein, and supersedes all prior agreements,
      representations and understandings, whether oral or written, between the Company and the
      Registered User with respect to the matters contained herein.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 26 (Severability)</h3>
    <p class="text-sm leading-relaxed">
      Even if any provision or part of this Agreement is determined to be invalid or unenforceable
      under the Consumer Contract Act or other laws and regulations, the remaining provisions of
      this Agreement and the remaining parts of the provisions that are determined to be invalid or
      unenforceable shall remain in full force and effect, and the Company and the Registered User
      shall not be liable for any loss or damage arising from such invalid or unenforceable
      provision or part. The Company and the Registered User shall endeavor to modify such invalid
      or unenforceable provisions or portions to the extent necessary to make them legal and
      enforceable, and to ensure that the purpose of such invalid or unenforceable provisions or
      portions and their legally and economically equivalent effects are achieved.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 27 (Survival Provisions)</h3>
    <p class="text-sm leading-relaxed">
      The provisions of Article 6, Article 8, Paragraph 2, Article 11, Paragraph 2, Article 12,
      Paragraph 3, Article 13, Article 14, Article 15, Article 16, Paragraphs 2, 3 and 4, Articles
      17 through 19, and Articles 24 through 28 shall remain in effect even after the termination of
      the User Agreement.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 28 (Governing Law and Court of Jurisdiction)</h3>
    <p class="text-sm leading-relaxed">
      These Terms of Use shall be governed by and construed in accordance with the laws of Japan,
      and any and all disputes arising out of or relating to these Terms of Use shall be submitted
      to the exclusive jurisdiction of the Tokyo District Court as the court of first instance.
    </p>
    <h3 class="font-semibold mt-8 mb-2">Article 29 (Consultative Resolution)</h3>
    <p class="text-sm leading-relaxed mb-8">
      In the event that any question arises regarding any matter not stipulated in this Agreement or
      the interpretation of this Agreement, the Company and the Registered User shall consult with
      each other in accordance with the principles of good faith and shall promptly attempt to
      resolve such question.
    </p>
    <p class="font-semibold text-sm mb-1">Enacted on September 27, 2019</p>
    <p class="font-semibold text-sm mb-1">Revised on November 15, 2019</p>
    <p class="font-semibold text-sm mb-1">Revised on January 8, 2020</p>
    <p class="font-semibold text-sm mb-1">Revised on October 29, 2020</p>
    <p class="font-semibold text-sm mb-1">Revised on July 28, 2021</p>
    <p class="font-semibold text-sm mb-1">Revised on August 06, 2021</p>
    <p class="font-semibold text-sm mb-1">Revised on April 15, 2022</p>
  </div>
</template>

<script>
export default {
  name: 'EnTermsOfUseHotelContent',
}
</script>
