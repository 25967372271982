<template>
  <MainLayout :title="$t('Access')" class="px-0 pt-0 pb-0" backRoute="infoMenu">
    <iframe class="w-full min-h-[400px] flex-1" :src="address_link" />
    <div class="bg-white w-full">
      <div class="p-8 max-w-[600px] mx-auto">
        <div class="mb-8 flex font-light">
          <img :src="require('@/assets/images/icon_adress.svg')" class="mr-2" alt="iconAddress" />
          <span>{{ address }}</span>
        </div>
        <div
          v-html="$i18next.getValueByLanguage(hotelGuideData.facilityAccessInfo, 'content')"
          class="html-wrap"
        />
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'

export default {
  name: 'AddressView',
  components: { MainLayout },
  computed: {
    ...mapGetters(['hotelGuideData']),
    address_link() {
      return `https://maps.google.co.jp/maps?q=${
        this.hotelGuideData.facilityAccessInfo
          ? this.hotelGuideData.facilityAccessInfo.address
          : '-'
      }&z=17&output=embed&iwloc=J&t=m`
    },
    address() {
      return this.$i18next.language === 'en'
        ? this.hotelGuideData.facilityAccessInfo.addressLatin
        : this.hotelGuideData.facilityAccessInfo.address
    },
  },
}
</script>
