<template>
  <MainLayout :title="$t('Check-in Information')" :loading="loading" backRoute="CheckInListView">
    <div v-if="userCheckinDetails" class="w-full max-w-[600px] px-8 py-6 bg-white">
      <p class="font-semibold mb-2">{{ hotel_name }}</p>
      <div class="flex justify-between items-center text-[14px] mb-2">
        <p>{{ $t('Check-in datetime') }}</p>
        <p>{{ formatted_time }}</p>
      </div>
      <div class="flex justify-between items-center text-[14px]">
        <p>{{ $t('Check-in ID') }}</p>
        <p>{{ userCheckinDetails.checkin_code }}</p>
      </div>
      <div
        class="w-full rounded-lg border border-slate-300 px-8 py-6 my-4 flex justify-center items-center"
      >
        <QRCodeView :size="235" :value="qr_code" />
      </div>
      <div v-if="has_pass_id">
        <RouterLinkControl class="w-full" :link="save_link" target="_blank">
          <img
            class="mx-auto"
            :src="$i18next.isJapanese() ? SavetoGooglePayJpImg : SavetoGooglePayEnglishImg"
          />
        </RouterLinkControl>
      </div>
    </div>
    <div
      v-if="has_ticket_plugin && !!userCheckinDetails?.ticket"
      class="w-full max-w-xl mt-4 px-8 py-6 bg-white"
    >
      <p class="text-[#D68E0F] font-lato font-bold text-sm truncate">ご利用可能チケット</p>
      <Modal
        v-model="isShowTicketModal"
        @click-outside="modalClose"
        :key="isShowTicketModal"
        content-class="relative w-[315px] flex flex-col max-h-full border rounded-lg bg-[#F2F2F2]"
      >
        <div class="m-4 mb-6">
          <p class="text-sm font-bold m-4">{{ ticket.name }}</p>
          <QRCodeView
            :size="254"
            :value="ticket.qrCode"
            :key="ticket.qrCode"
            class="relative w-[150px] flex flex-col max-h-full m-4 p-3 border rounded-lg bg-white"
          />
        </div>
        <div class="bg-white h-[70px]">
          <span class="relative flex justify-between pt-6">
            <img
              @click="openTicketModal(tickets, { previous: true })"
              class="pl-7 bg-white cursor-pointer"
              :src="
                require(ticket.no === 1
                  ? '@/assets/images/controls/arrow_left_gray.svg'
                  : '@/assets/images/controls/arrow_left.svg')
              "
            />
            <span class="text-sm font-bold"> {{ ticket.no }} / {{ ticket.maxLength }} </span>
            <img
              @click="openTicketModal(tickets, { next: true })"
              class="pr-7 bg-white cursor-pointer"
              :src="
                require(ticket.no !== ticket.maxLength
                  ? '@/assets/images/controls/arrow_right.svg'
                  : '@/assets/images/controls/arrow_right_gray.svg')
              "
            />
          </span>
        </div>
      </Modal>
      <dev
        v-for="(activeTickets, key, index) in userCheckinDetails?.ticket"
        :key="activeTickets[index]"
        class="flex-1 p-4 flex flex-col justify-between bg-[#F2F2F2] overflow-hidden rounded-xl w-full flex my-4 list-none cursor-pointer"
      >
        <div @click="openTicketModal(activeTickets)">
          <p class="text-sm font-bold mb-[6px]">{{ key }}</p>
          <p class="text-xs text-[#676767]">{{ activeTickets.length }}枚</p>
        </div>
      </dev>
    </div>
  </MainLayout>
</template>

<script>
import { omit } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import MainLayout from '@/components/layouts/MainLayout'
import SavetoGooglePayEnglish from '@/assets/images/SavetoGooglePayEnglish.svg'
import SavetoGooglePayJp from '@/assets/images/SavetoGooglePayJp.svg'
import RouterLinkControl from '@/components/controls/RouterLinkControl'
import QRCodeView from '@/components/UI/QRCodeView'
import { capitalize } from '@/utils/text'
import Modal from '@/components/UI/Modal'

export default {
  name: 'CheckinInfoView',
  components: {
    MainLayout,
    QRCodeView,
    RouterLinkControl,
    Modal,
  },
  data() {
    return {
      SavetoGooglePayEnglishImg: SavetoGooglePayEnglish,
      SavetoGooglePayJpImg: SavetoGooglePayJp,
      loading: true,
      userCheckinDetails: undefined,
      isShowTicketModal: false,
      tickets: [],
      ticket: {
        name: null,
        no: 0,
        maxLength: 0,
        qrCode: null,
      },
    }
  },
  computed: {
    ...mapGetters(['hotelGuideData']),
    hotel_info() {
      return this.hotelGuideData?.facilityBasicInfo || {}
    },
    formatted_time() {
      return moment(this.userCheckinDetails?.checkin_time).format('YYYY/MM/DD HH:mm')
    },
    qr_code() {
      const qrObject =
        typeof this.userCheckinDetails.qr_json === 'string'
          ? JSON.parse(this.userCheckinDetails.qr_json)
          : this.userCheckinDetails.qr_json
      return btoa(unescape(encodeURIComponent(JSON.stringify(omit(qrObject, ['pass_id'])))))
    },
    save_link() {
      return `${process.env.VUE_APP_PASS_PROVIDER}/passkit/${this.userCheckinDetails.qr_json?.pass_id}/download`
    },
    hotel_name() {
      return this.$i18next.isJapanese()
        ? this.userCheckinDetails.hotel_name
        : this.userCheckinDetails.hotel_name_latin
    },
    has_pass_id() {
      return !!this.userCheckinDetails.qrJson?.pass_id
    },
    has_ticket_plugin() {
      return this.hotelGuideData?.plugins?.find(element => element.code === 'TKT')?.isEnabled
    },
  },
  async mounted() {
    try {
      this.loading = true
      const params = {
        checkinId: this.$route.params.id,
        checkinCode: this.$route.query.checkinCode,
      }

      this.userCheckinDetails = await this.getUserCheckinDetails(params)
    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions(['getUserCheckinDetails', 'globalErrorHandler']),
    capitalize,
    openTicketModal(tickets, { next, previous } = {}) {
      let index = 0
      if (next) {
        if (this.ticket.no >= this.ticket.maxLength) return
        index = this.ticket.no
        this.ticket.no = index + 1
      }

      if (previous) {
        if (this.ticket.no <= 1) return
        this.ticket.no = this.ticket.no - 1
        index = this.ticket.no - 1
      }

      if (!next && !previous) {
        this.tickets = tickets
        this.ticket.no = 1
        this.ticket.maxLength = tickets.length
      }

      this.isShowTicketModal = true
      this.ticket.name = tickets[index].ticket_name
      this.ticket.qrCode = tickets[index].exchange_code
    },
    modalClose() {
      this.isShowTicketModal = false
    },
  },
}
</script>
