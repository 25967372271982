<template>
  <div class="w-full max-w-[600px] mb-6 bg-white border-[1px] border-gray-super-light rounded">
    <div class="flex justify-start items-start px-5 pt-4 border-b border-gray-super-light">
      <CheckField
        :name="`reservation[${reservation.id}]`"
        :label="label"
        class="w-[20px] h-[20px]"
        @change="$emit('onSelect', reservation)"
      />
    </div>
    <div class="px-5 pt-4 pb-2">
      <div class="flex flex-row mb-2">
        <div class="text-xs text-slate-600 w-[108px] mr-8">
          {{ capitalize($t('accommodation date')) }}
        </div>
        <div class="text-sm flex">
          <div class="ml-2">{{ date }} ({{ day }})</div>
          <div class="ml-2">
            {{ reservation.nights }} {{ reservation.nights === 1 ? $t('night') : $t('nights') }}
          </div>
        </div>
      </div>
      <div class="flex flex-row mb-2">
        <div class="text-xs text-slate-600 w-[108px] mr-8">
          {{ capitalize($t('type of room')) }}
        </div>
        <div class="text-sm">{{ $i18next.getValueByLanguage(reservation, 'plan') || '-' }}</div>
      </div>
      <div class="flex flex-row mb-2">
        <div class="text-xs text-slate-600 w-[108px] mr-8">
          {{ $t('Reservation ID') }}
        </div>
        <div class="text-sm">{{ reservation.reservationId }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from '@/utils/text'
import moment from 'moment'
import 'moment/locale/ja'

export default {
  props: {
    reservation: Object,
  },
  computed: {
    label() {
      let start = this.$t('Guest Room')

      if (this.reservation.roomNumbers?.length > 1) start = this.$t('Guest Rooms')

      return (
        start + ' ' + (this.reservation.roomNumbers ? this.reservation.roomNumbers.join(', ') : '')
      )
    },
    day() {
      if (!this.reservation) return ''
      return moment(this.reservation.checkinTime)
        .locale(this.$i18next.language || 'ja')
        .format('dd')
    },
    date() {
      if (!this.reservation) return ''
      return moment(this.reservation.checkinTime).format('MM/DD')
    },
  },
  methods: {
    capitalize,
  },
}
</script>
