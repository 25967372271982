<template>
  <MainLayout
    :title="title"
    backRoute="checkoutSearch"
    :loading="loading"
    class="flex flex-column items-center p-4"
  >
    <div class="w-full max-w-[600px] flex flex-col items-center">
      <StayReservationCard
        v-for="item of items_list"
        :key="item.id"
        :reservation="item"
        :selected="selectedItems.includes(item)"
        @onSelect="onSelectItem"
      />
      <Button @click="onSubmit" :disabled="is_disabled" :loading="loading" class="mt-2">
        {{ $t('Next') }}
      </Button>
      <RouterLinkControl componentName="checkoutSearch">
        <Button mode="outline" class="mb-4">{{ capitalize($t('re-search')) }}</Button>
      </RouterLinkControl>
    </div>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import RouterLinkControl from '@/components/controls/RouterLinkControl'
import MainLayout from '@/components/layouts/MainLayout'
import StayReservationCard from '@/components/blocks/StayReservationCard'
import { capitalize } from '@/utils/text'

export default {
  name: 'CheckoutReservationsList',
  components: {
    MainLayout,
    RouterLinkControl,
    StayReservationCard,
  },
  data() {
    return {
      loading: false,
      selectedItems: [],
      reservations: [],
      checkins: [],
      paymentSetting: undefined,
    }
  },
  computed: {
    ...mapGetters(['hasPaymentPlugin']),
    is_disabled() {
      return !this.selectedItems.length
    },
    title() {
      return this.$route.name === 'confirmCheckoutCheckins'
        ? this.$t('Select your room')
        : this.$t('Select your reservation')
    },
    is_checkins_type() {
      return this.$route.name === 'confirmCheckoutCheckins'
    },
    items_list() {
      if (this.is_checkins_type) return this.checkins
      return this.reservations
    },
  },
  async mounted() {
    try {
      this.loading = true
      const { telephone } = this.$route.query

      if (!telephone) return this.$router.push({ name: 'checkoutSearch' })

      if (this.is_checkins_type) {
        const checkins = await this.getCheckinsList({ telephone })

        this.checkins = checkins.filter(checkin => checkin.approvedStatus === 2)
      } else {
        this.reservations = await this.getStayReservations({ telephone })
      }

      this.paymentSetting = await this.getPaymentSetting()
    } catch (error) {
      this.globalErrorHandler({ error })
      this.$router.push({ name: 'checkoutSearch' })
    } finally {
      this.loading = false
    }
  },
  methods: {
    capitalize,
    ...mapActions([
      'getCheckinsList',
      'getPaymentSetting',
      'setCheckoutReservations',
      'setCheckoutCheckins',
      'getStayReservations',
      'globalErrorHandler',
    ]),
    onSelectItem(reservation) {
      const reservationIndex = this.selectedItems.findIndex(item => reservation === item)

      if (reservationIndex !== -1) {
        this.selectedItems.splice(reservationIndex, 1)
      } else {
        this.selectedItems.push(reservation)
      }
    },
    onSubmit() {
      const paymentMethodValues = []
      const checkoutPaymentSetting = this.paymentSetting?.payment_time?.find(
        item => item.value === 'CHECKOUT'
      )

      if (checkoutPaymentSetting && checkoutPaymentSetting.is_enabled) {
        for (let index = 0; index < checkoutPaymentSetting.payment_method.length; index++) {
          const paymentMethod = checkoutPaymentSetting.payment_method[index]
          if (paymentMethod.is_enabled) {
            if (paymentMethod.value === 'DIRECT') {
              paymentMethodValues.push('cash')
            } else if (paymentMethod.value === 'CREDIT_CARD') {
              paymentMethodValues.push('creditCard')
            }
          }
        }
      }

      if (this.is_checkins_type) this.setCheckoutCheckins(this.selectedItems)
      else this.setCheckoutReservations(this.selectedItems)

      if (this.hasPaymentPlugin && paymentMethodValues.length) {
        this.$router.push({
          name: 'checkoutPayment',
          query: { telephone: this.$route.query.telephone },
        })
      } else {
        this.$router.push({
          name: 'checkoutReview',
          query: { telephone: this.$route.query.telephone },
        })
      }
    },
  },
}
</script>
