import api from '@/api'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getHotelCouponsList({ getters }) {
    return api.get('store/coupons', { params: { hotelId: getters['hotelId'] } }).then(res => res.data)
  },
  getHotelCoupon({ getters }, id) {
    return api.get(`store/coupons/${id}`, { params: { hotelId: getters['hotelId'] } }).then(res => res.data)
  },
  takeHotelCoupons({ getters }, couponId) {
    return api.post(`store/coupons/${couponId}`, { params: { hotelId: getters['hotelId'] } })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
