<template>
  <div class="w-full max-w-[600px] flex flex-col items-center">
    <SpinnerLoader v-if="loading" />
    <template v-else>
      <div
        v-if="userCreditCard?.cardBrand"
        class="flex w-full max-w-[600px] items-center justify-between p-8 bg-white"
      >
        <div class="text-small font-semibold">
          <span class="mr-4">{{ userCreditCard.cardBrand }}</span>
          <span>{{ userCreditCard.cardNumber }}</span>
        </div>
        <Button @click.prevent="deleteCard" mode="icon" class="ml-4" type="button">
          &#10005;
        </Button>
      </div>
      <slot v-else name="noCard" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SpinnerLoader from '@/components/blocks/loader/Loader'

export default {
  name: 'CardList',
  components: { SpinnerLoader },
  data() {
    return { loading: true, userCreditCard: undefined }
  },
  computed: {
    ...mapGetters(['userData', 'hotelGuideData']),
  },
  mounted() {
    this.loadCard()
  },
  methods: {
    ...mapActions(['deleteCreditCard', 'getCreditCard', 'globalErrorHandler']),
    async loadCard() {
      try {
        this.loading = true

        this.userCreditCard = await this.getCreditCard({ cuicinUserId: this.userData.id })

        this.$emit('load', this.userCreditCard)
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
    async deleteCard() {
      try {
        this.loading = true

        await this.deleteCreditCard({
          cardId: this.userCreditCard.cardId,
        })

        await this.loadCard()
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
