<template>
  <vue-final-modal v-slot="{ params }" v-bind="$attrs" classes="flex justify-center items-center">
    <span class="mr-8 text-2xl font-bold">
      <slot name="title"></slot>
    </span>
    <div class="flex-grow overflow-y-auto">
      <slot :params="params"></slot>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Modal',
  inheritAttrs: false,
  emits: ['cancel', 'confirm'],
}
</script>
