<template>
  <div class="h-screen flex justify-center items-center">
    <half-circle-spinner :animation-duration="1000" :size="size" :color="color" />
  </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'
export default {
  name: 'Loader-spinner',
  props: {
    size: { type: Number, default: 60 },
    color: { type: String, default: '#D68E0F' },
  },
  components: {
    HalfCircleSpinner,
  },
}
</script>
