<template>
  <MainLayout :title="$t('Email')" class="flex flex-col items-center">
    <p class="text-xs text-slate-600 leading-relaxed mb-4">
      {{ $t('Need your aipassID to sign in') }}
    </p>
    <Form
      v-slot="{ meta }"
      @submit="onSubmit"
      class="w-full max-w-[600px] flex flex-col items-center"
    >
      <div class="w-full p-8 bg-white flex flex-col justify-start items-center">
        <TextField
          v-if="show_email_field"
          v-model="formData.email"
          :placeholder="capitalize($t('email address'))"
          :label="$t('Email')"
          :rules="
            combineFieldValidators([fieldValidators.required(), this.fieldValidators.email()])
          "
          name="email"
          type="email"
          spellcheck="false"
          autocomplete="email"
          autocorrect="off"
          autocapitalize="off"
        />
        <TextField
          v-model="formData.password"
          :placeholder="$t('Must include lowercase, uppercase letters and numbers')"
          :label="$t('Password')"
          :rules="combineFieldValidators([fieldValidators.required(), fieldValidators.password()])"
          name="password"
          type="password"
        />
        <p class="text-sm mt-3 text-slate-600 text-center mb-4">
          {{ $t('The first time you use aiPass, please set a password of your choice.') }}
        </p>
        <RouterLinkControl componentName="resetPassword" :queryParams="{ email: formData.email }">
          <p class="mx-4 my-4 font-lato text-amber text-xs">
            {{ $t('Did you forget your password?') }}
          </p>
        </RouterLinkControl>
      </div>
      <Button :loading="loadingAction" :disabled="!meta.valid" type="submit" class="mb-4">
        {{ $t('Next') }}
      </Button>
    </Form>
    <ResetPasswordModal
      :isShow="showSuccessResetPasswordModal"
      :email="formData.email"
      :mailStatus="mailStatus"
      @close-modal="() => (showSuccessResetPasswordModal = false)"
    />
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'

import MainLayout from '@/components/layouts/MainLayout'
import RouterLinkControl from '@/components/controls/RouterLinkControl.vue'
import ResetPasswordModal from '@/components/blocks/auth/ResetPasswordModal.vue'
import formValidatorsMixin from '@/mixins/formValidatorsMixin.vue'
import { capitalize } from '@/utils/text'

export default {
  name: 'EmailLoginView',
  mixins: [formValidatorsMixin],
  components: {
    MainLayout,
    ResetPasswordModal,
    RouterLinkControl,
    Form,
  },
  data() {
    return {
      formData: {
        email: undefined,
        password: undefined,
      },
      loadingAction: false,
      showSuccessResetPasswordModal: false,
      mailStatus: null,
    }
  },
  computed: {
    ...mapGetters(['loginRedirect']),
    show_email_field() {
      return !this.$route.query.email
    },
  },
  mounted() {
    if (this.$route.query?.email) {
      this.formData.email = this.$route.query.email
    }
  },
  methods: {
    capitalize,
    ...mapActions(['signIn', 'signUp', 'globalErrorHandler', 'forgotPassword', 'checkEmail']),
    async onSubmit() {
      try {
        this.loadingAction = true

        let userStatus = this.$route.query?.userStatus

        if (!userStatus) {
          userStatus = await this.checkEmail(this.formData.email)
        }

        switch (userStatus) {
          case 'exists':
            await this.signIn({ email: this.formData.email, password: this.formData.password })
            break
          case 'reset':
            await this.forgotPassword({
              email: this.formData.email,
              hotelId: this.$route.query.hotelId,
              lang: this.$route.query.lang,
            })
            this.showSuccessResetPasswordModal = true
            this.mailStatus = userStatus
            return
          default:
            await this.signUp({
              email: this.formData.email,
              password: this.formData.password,
              language: this.$i18next.language,
            })
            this.loadingAction = false
        }
        if (this.loginRedirect) {
          return this.$router.replace(this.loginRedirect)
        }

        return this.$router.replace({ name: 'myPage' })
      } catch (error) {
        this.globalErrorHandler({ error })
        this.loadingAction = false
      }
    },
  },
}
</script>
