export function capitalize(str) {
  if (typeof str !== 'string') return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function translateObject(object, key, language) {
  const langForUCC = language.substring(0, 1).toUpperCase() + language.substring(1)
  const keyByLang = `${key}${langForUCC}`
  return object[keyByLang] || object[key] || ''
}
