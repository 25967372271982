import { createStore } from 'vuex'
import { useToast } from 'vue-toastification'

import i18n from '@/i18n'
import { capitalize } from '@/utils/text'

import hotel from './hotel'
import auth from './auth'
import user from './user'
import reservation from './reservation'
import checkOut from './checkOut'
import travelGuide from './travelGuide'
import product from './product'
import coupon from './coupon'
import router from '@/router'

const toast = useToast()

export default createStore({
  state: {
    loading: true,
  },
  getters: {
    mainLoading: state => state.loading,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
  },
  actions: {
    setMainLoading({ commit }, value) {
      return commit('SET_LOADING', value)
    },
    globalErrorHandler(_, { error, skipUnauthorizedError } = {}) {
      if (process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'development') {
        console.error(error)
      }
      if (error?.response?.status === 401 && skipUnauthorizedError) return
      if (error?.response?.status === 401) return router.replace({ name: 'login' }) // TODO: Remove 401 redirect for some API call

      let errorText = error && typeof error === 'string' ? error : 'unknown error'

      if (error?.response?.data) {
        const errorData = error?.response?.data

        if (errorData.message) errorText = errorData.message
        else if (errorData.errorMessage) errorText = errorData.errorMessage
        else if (errorData.description) errorText = errorData.description

        if (typeof errorData?.errors === 'object') {
          for (const [indexTop, topErrors] of Object.values(errorData?.errors).entries()) {
            if (indexTop === 0) errorText = ''
            if (Array.isArray(topErrors)) {
              for (const [index, error] of topErrors.entries()) {
                if (typeof error === 'object') {
                  errorText +=
                    indexTop !== 0 || index !== 0
                      ? ', '
                      : '' + i18n.exists(error.message)
                      ? i18n.t(error.message, error.params)
                      : error.message
                } else if (typeof error === 'string') {
                  errorText +=
                    indexTop !== 0 || index !== 0 ? ', ' : '' + i18n.exists(errorText) ? i18n.t(error) : error
                }
              }
            }
          }
        }
      } else if (error?.policy) errorText = error.policy
      else if (error?.description) errorText = error.description
      else if (error?.message) errorText = error.message
      else if (error?.errorMessage) errorText = error.errorMessage

      if (error?.code === 'invalid_password') {
        errorText =
          'Please set the password with 8 characters or more using lowercase, uppercase characters, and number.'
      }

      if (i18n.exists(errorText)) errorText = i18n.t(errorText)

      toast.error(capitalize(errorText))
    },
    globalShowInfo(_, { text }) {
      toast.info(text)
    },
  },
  modules: {
    hotel,
    auth,
    user,
    reservation,
    checkOut,
    travelGuide,
    product,
    coupon,
  },
})
