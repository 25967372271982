<script>
export default {
  name: 'TagsList',
  props: {
    tags: {
      type: Array,
      required: true,
    },
    activeTag: {
      type: [String, Number, Object],
      required: true,
    },
    getValue: {
      type: Function,
      required: false,
    },
    getLabel: {
      type: Function,
      required: false,
    },
    getKey: {
      type: Function,
      required: false,
    },
  },
  methods: {
    clickTag(tag) {
      this.$emit('changeTags', tag)
    },
    isActiveTag(tag) {
      if (this.getKey) {
        return this.getKey(this.activeTag) === this.getKey(tag)
      }

      return this.getValue ? this.activeTag === this.getValue(tag) : this.activeTag === tag
    },
  },
}
</script>

<template>
  <ul class="flex bg-white overflow-x-auto scrollbar-none">
    <li
      v-for="(tag, index) in tags"
      :key="tag"
      :class="{
        'md:ml-auto': index === 0,
        'md:mr-auto': index === tags.length - 1,
      }"
    >
      <a
        @click="clickTag(tag)"
        class="flex flex-col items-center cursor-pointer my-2 w-16 justify-center"
      >
        <img
          :src="tag.imagePath"
          :class="{
            'border-amber': isActiveTag(tag),
            'border-white': !isActiveTag(tag),
          }"
          class="w-12 h-12 object-cover rounded-full border-2 transition-colors duration-300"
        />
        <div
          :class="{
            'bg-amber text-white rounded-full': isActiveTag(tag),
            'text-gray': !isActiveTag(tag),
          }"
          class="min-w-[42px] text-center mt-2 transition-all text-[0.7rem] duration-300 break-all line-clamp-2"
        >
          {{ getLabel ? getLabel(tag) : tag }}
        </div>
      </a>
    </li>
  </ul>
</template>
