<template>
  <div class="w-full max-w-[600px] mb-6 bg-white border-[1px] border-gray-super-light rounded">
    <div class="flex justify-start items-start px-5 pt-4 border-b border-gray-super-light">
      <CheckField
        :name="`reservation[${reservation.id}]`"
        :label="label"
        class="w-[20px] h-[20px]"
        @change="$emit('onSelect', reservation)"
      />
    </div>
    <div class="px-5 pt-4 pb-2">
      <div class="flex flex-row mb-2">
        <div class="text-xs text-slate-600 w-[108px] mr-10">
          {{ capitalize($t('type of room')) }}
        </div>
        <div class="text-sm">{{ $i18next.getValueByLanguage(reservation, 'plan') || '-' }}</div>
      </div>
      <div class="flex flex-row mb-2">
        <div class="text-xs text-slate-600 w-[108px] mr-10">
          {{ $t('Room') }}
        </div>
        <div v-if="reservation.reservationRoom?.length">
          <div v-for="room of reservation.reservationRoom" :key="room.roomType">
            {{ capitalize($i18next.getValueByLanguage(room, 'typeName')) }} / {{ room.roomNum }}
            {{ room.roomNum > 1 ? $t('Rooms') : $t('Room') }}
          </div>
        </div>
        <div v-else class="text-sm">
          {{ reservation?.roomNumber || '-' }}
        </div>
      </div>
      <div class="flex flex-row mb-2">
        <div class="text-xs text-slate-600 w-[108px] mr-10">
          {{ $t('Number of guests') }}
        </div>
        <div class="text-sm">
          {{ reservation.paxTotal || '-' }}
          <span v-if="reservation.paxTotal">
            {{ reservation.paxTotal === 1 ? $t('Guest') : $t('Guests') }}
          </span>
        </div>
      </div>
      <div class="flex flex-row mb-2">
        <div class="text-xs text-slate-600 w-[108px] mr-10">
          {{ capitalize($t('reservation holder')) }}
        </div>
        <div class="text-sm">
          {{ reservation.userName || reservation.userNameEn || '-' }}
        </div>
      </div>
      <div class="flex flex-row mb-2">
        <div class="text-xs text-slate-600 w-[108px] mr-10">
          {{ capitalize($t('confirmed guest')) }}
        </div>
        <div class="text-sm">
          {{ reservation.guestName || reservation.guestNameEn || '-' }}
        </div>
      </div>
      <div class="flex flex-row mb-2 pt-4 mt-4 -mx-5 px-5 border-t-[1px] border-gray-super-light">
        <div class="text-xs text-slate-600 w-[108px] mr-10">
          {{ $t('Reservation ID') }}
        </div>
        <div class="text-sm">{{ reservation.reservationId }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from '@/utils/text'
import moment from 'moment'

export default {
  props: {
    reservation: Object,
  },
  computed: {
    label() {
      return `${this.date} (${this.day}) ${this.reservation.nights} ${
        this.reservation.nights === 1 ? this.$t('night') : this.$t('nights')
      }`
    },
    day() {
      if (!this.reservation) return ''
      return moment(this.reservation.checkinDate)
        .locale(this.$i18next.language === 'zh' ? 'zh-cn' : this.$i18next.language || 'ja')
        .format('dd')
    },
    date() {
      if (!this.reservation) return ''
      return moment(this.reservation.checkinDate).format('MM/DD')
    },
  },
  methods: {
    capitalize,
  },
}
</script>
