import Button from '@/components/UI/Button'
import TextField from '@/components/UI/formFields/TextField'
import SelectField from '@/components/UI/formFields/SelectField'
import RadioField from '@/components/UI/formFields/RadioField'
import CheckField from '@/components/UI/formFields/CheckField'
import DateField from '@/components/UI/formFields/DateField'
import TextareaField from '@/components/UI/formFields/TextareaField'

export default {
  install(app) {
    // eslint-disable-next-line vue/multi-word-component-names
    app.component('Button', Button)
    app.component('TextField', TextField)
    app.component('SelectField', SelectField)
    app.component('RadioField', RadioField)
    app.component('CheckField', CheckField)
    app.component('DateField', DateField)
    app.component('TextareaField', TextareaField)
  },
}
