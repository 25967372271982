<script>
export default {
  props: {
    disabled: Boolean,
    label: String,
    hideError: Boolean,
    name: { type: String, required: true },
    rules: { type: [Array, Function, String], default: () => [] },
  },
  computed: {
    is_required() {
      if (this.rules?.validatorsList) {
        return this.rules?.validatorsList?.includes('required')
      }

      return this.rules?.validatorName?.includes('required')
    },
  },
}
</script>
