<template>
  <div class="mt-16 w-full max-w-[1000px] px-6 py-12 font-yuGothic">
    <h2 class="text-center font-semibold text-xl">aipassサービス利用規約｜施設運営者</h2>
    <p class="text-center text-xs my-6 text-slate-600 leading-relaxed">
      最終更新日：2022年04月15日
    </p>
    <p class="text-left text-xs my-6 text-slate-600 leading-relaxed">
      本aipassサービス利用規約（以下「本規約」といいます。）には、aipass株式会社（以下「当社」といいます。）の提供するaipassサービスのご利用にあたり、登録ユーザーの皆様に遵守していただかなければならない事項及び当社と登録ユーザーの皆様との間の権利義務関係が定められております。当該サービスを登録ユーザーとしてご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。
    </p>

    <h3 class="font-semibold mt-8 mb-2">第1条（適用）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        本規約は、本サービス（第2条に定義）の利用に関する当社と登録ユーザー（第2条に定義）との間の権利義務関係を定めることを目的とし、登録ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
      </li>
      <li class="text-xs leading-relaxed">
        当社が当社ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第2条（定義）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      本規約において使用する以下の用語は各々以下に定める意味を有するものとします。
    </p>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        「外部サービス」とは、他の事業者が提供している当社所定のサービスで、本サービスと連携するサービスを意味します。
      </li>
      <li class="text-xs leading-relaxed">
        「外部事業者」とは、外部サービスのサービス提供者を意味します。
      </li>
      <li class="text-xs leading-relaxed">
        「外部利用規約」とは、外部サービスにおける権利義務関係を定める規約を意味します。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
      </li>
      <li class="text-xs leading-relaxed">
        「当社ウェブサイト」とは、そのドメインが「aipass.jp」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又
        内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
      </li>
      <li class="text-xs leading-relaxed">
        「利用希望者」とは、第3条において定義された「利用希望者」を意味します。
      </li>
      <li class="text-xs leading-relaxed">
        「登録情報」とは、第3条において定義された「登録情報」を意味します。
      </li>
      <li class="text-xs leading-relaxed">
        「登録ユーザー」とは、第3条に基づき本サービスの利用者としての登録がなされた個人又は法人を意味します。
      </li>
      <li class="text-xs leading-relaxed">
        「本サービス」とは、当社が提供するaipassという名称のホテルチェックイン関連サービス（理由の如何を問わずサービスの名称又は内容が変更された
        場合は、当該変更後のサービスを含みます。）を意味します。
      </li>
      <li class="text-xs leading-relaxed">
        「本データ等」とは、本サービスにおいて当社が提供するデータ及び情報を意味します。
      </li>
      <li class="text-xs leading-relaxed">
        「利用契約」とは、第3条第4項に基づき当社と登録ユーザーの間で成立する、本規約の諸規定に従った本サービスの利
        用契約を意味します。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第3条（利用申込）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        本サービスの利用を希望する者（以下「利用希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報
        （以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用申込をすることができます。
      </li>
      <li class="text-xs leading-relaxed">
        利用申込は必ず本サービスを利用する個人又は法人自身が行わなければならず、原則として代理人による申込は認められないものとします。
        また、利用希望者は、利用申込にあたり、真実、正確かつ最新の情報を当社に提供しなければなりません。
      </li>
      <li class="text-xs leading-relaxed">
        当社は、第1項に基づき利用申込した者が、以下の各号のいずれかの事由に該当する場合は、申込を拒否することがあります。
      </li>
      <ol class="list-alpha px-6 mb-4">
        <li class="text-xs leading-relaxed">本規約に違反するおそれがあると当社が判断した場合</li>
        <li class="text-xs leading-relaxed">
          当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
        </li>
        <li class="text-xs leading-relaxed">
          過去に本サービスの利用の登録を取り消された者である場合
        </li>
        <li class="text-xs leading-relaxed">
          未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合
        </li>
        <li class="text-xs leading-relaxed">
          反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、
          又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
        </li>
        <li class="text-xs leading-relaxed">その他、当社が登録を適当でないと判断した場合</li>
      </ol>
      <li class="text-xs leading-relaxed">
        当社は、前項その他当社の基準に従って、利用希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を利用希望者に通知します。かかる通知により利用希望者の登録ユーザーとしての登録は完了し、本規約の諸規定に従った本サービスの利用にかかる契約（以下「利用契約」といいます。）が登録ユーザーと当社の間に成立します。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第4条（本サービスの利用）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      登録ユーザーは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第5条（業務の再委託）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      当社は、本サービスに関する業務の全部又は一部を他の第三者に再委託することができるものとします。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第6条（利用料金）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        登録ユーザーは、本サービス利用の対価として、本サービスの利用料金を支払うものとします。利用料金の金額、支払方法、支払時期等については、別途当社が料金表等の形式にて登録ユーザーに提示するものとし、登録ユーザーはこれに従って支払いを行うものとします。
      </li>
      <li class="text-xs leading-relaxed">
        利用料金の支払にかかる費用は登録ユーザーが負担するものとします。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーが利用料金の支払を遅滞した場合、登録ユーザーは年14.6％の割合による遅延損害金を当社に支払うものとします。
      </li>
      <li class="text-xs leading-relaxed">
        当社は、いかなる場合でも、一度受領した利用料金を返還しないものとします。
      </li>
      <li class="text-xs leading-relaxed">
        当社は経済情勢の変動や、その他事由により、利用料金などを改定する必要があると判断する場合には、登録ユーザーに対して改定の１ヶ月前までに通知することにより、これを変更することができるものとします。
      </li>
      <li class="text-xs leading-relaxed">
        当社は支払期日に利用料金の入金が確認できなかった場合、利用契約を解約することができます。この場合、当社は登録ユーザーに通知することなく、直ちに全ての本サービスを停止することができるものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第7条（トライアル）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当社は、本サービスの利用を検討する者に対し、本サービスの全部または一部を当社が別途定める一定の期間のみ無料で利用できるサービス（以下、「トライアル」）を行うことがあります。ただし当社がトライアルを提供する義務を負うものではありません。
      </li>
      <li class="text-xs leading-relaxed">
        当社は、トライアルにおいて提供する本サービスの機能の範囲及び使用方法などに制限を設けることがあります。
      </li>
      <li class="text-xs leading-relaxed">
        トライアルを利用する者は登録ユーザーとして本利用規約に同意の上、トライアルを利用するものとする。
      </li>
      <li class="text-xs leading-relaxed">
        トライアルを利用する者はトライアル期間満了後、本サービスを解約する場合には、期間満了日までに当社所定の方法で解約を申し出るものとします。当社はトライアルを利用する者から解約の申し出がなかった場合、本サービス利用継続と判断し、当社指定の方法で第６条に定めた利用料金を請求するものとし、トライアルを利用する者は登録ユーザーとしてこれに応じるものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第8条（パスワード及びユーザーIDの管理）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        登録ユーザーは、自己の責任において、パスワード及びユーザーIDを管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。
      </li>
      <li class="text-xs leading-relaxed">
        パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザーが負うものとし、当社は一切の責任を負いません。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、パスワード又はユーザーIDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第9条 （外部事業者のサービス）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      登録ユーザーは、本サービスと連携させて、当社が指定する外部事業者のサービスを利用できる場合があります。
      この場合、当社は、当該外部事業者のサービスを登録ユーザーに対して提示します。
      当該外部事業者のサービスの利用を希望するパートナーは、当該外部事業者のサービス利用規約に同意した上で、各サービスの提供条件等に従い、外部事業者のサービスを利用するものとします。なお、当社は、当該外部事業者のサービスに瑕疵がないこと等の保証を行いません。
      また、当社は、当該外部事業者のサービスの変更、終了等に起因する損害については責任を負いません。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第10条 （登録ユーザーの商号等の取扱い）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      当社は、当社のマーケティング等の目的で、登録ユーザーの商号・商標・ロゴマークを使用することができるものとします。
      また、当社は、登録ユーザーが本サービスの利用者である旨の情報および本サービスを用いて配信したコンテンツ、実施した施策等を一般的な表現で開示・公表することができるものとします。
      但し、登録ユーザーが事前に異議を述べた場合は、この限りではありません。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第11条（禁止行為）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
      </li>
      <ol class="list-alpha px-6 mb-4">
        <li class="text-xs leading-relaxed">
          当社、他の登録ユーザー、外部事業者その他の第三者（登録ユーザーの運営するホテルの利用者又は利用予定者を含む。以下本規約にて同じ。）の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）
        </li>
        <li class="text-xs leading-relaxed">犯罪行為に関連する行為又は公序良俗に反する行為</li>
        <li class="text-xs leading-relaxed">猥褻な情報又は青少年に有害な情報を送信する行為</li>
        <li class="text-xs leading-relaxed">異性交際に関する情報を送信する行為</li>
        <li class="text-xs leading-relaxed">
          法令又は当社若しくは登録ユーザーが所属する業界団体の内部規則に違反する行為
        </li>
        <li class="text-xs leading-relaxed">
          コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
        </li>
        <li class="text-xs leading-relaxed">本サービスに関し利用しうる情報を改ざんする行為</li>
        <li class="text-xs leading-relaxed">
          当社が定める一定のデータ容量以上のデータを本サービスを通じて送信する行為
        </li>
        <li class="text-xs leading-relaxed">
          当社による本サービスの運営を妨害するおそれのある行為
        </li>
        <li class="text-xs leading-relaxed">
          自ら本サービスと同一又は類似のサービスを行う行為及び第三者をして本サービスと同一又は類似のサービスを行わせる行為
        </li>
        <li class="text-xs leading-relaxed">
          本データ等を公開若しくは第三者に開示し、又は、譲渡、販売、配布、本サービスと同一又は類似のサービスを開発する目的などの目的で使用すること
        </li>
        <li class="text-xs leading-relaxed">その他、当社が不適切と判断する行為</li>
      </ol>
      <li class="text-xs leading-relaxed">
        当社は、本サービスにおける登録ユーザーによる行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、登録ユーザーに事前に通知することなく、本サービスの全部又は一部を停止し、当該行為により送信された情報の全部又は一部を削除することができるものとします。当社は、本項に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第12条（本サービスの停止等）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
      </li>
      <ol class="list-alpha px-6 mb-4">
        <li class="text-xs leading-relaxed">
          本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
        </li>
        <li class="text-xs leading-relaxed">コンピューター、通信回線等が事故により停止した場合</li>
        <li class="text-xs leading-relaxed">
          火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
        </li>
        <li class="text-xs leading-relaxed">本サービスのアップデートを行う場合</li>
        <li class="text-xs leading-relaxed">
          外部サービスに、トラブル、サービス提供の中断又は停止、本サービスとの連携の停止、仕様変更等が生じた場合
        </li>
        <li class="text-xs leading-relaxed">その他、当社が停止又は中断を必要と判断した場合</li>
      </ol>
      <li class="text-xs leading-relaxed">
        当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社は登録ユーザーに事前に通知するものとします。
      </li>
      <li class="text-xs leading-relaxed">
        当社は、本条に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第13条（設備の負担等）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、登録ユーザーの費用と責任において行うものとします。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。
      </li>
      <li class="text-xs leading-relaxed">
        当社は、登録ユーザーが送受信したメッセージその他の情報を運営上一定期間保存していた場合であっても、かかる情報を保存する義務を負うものではなく、当社はいつでもこれらの情報を削除できるものとします。なお、当社はかかる情報の削除に基づき登録ユーザーに生じた損害について一切の責任を負いません。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法によりソフトウェア等を登録ユーザーのコンピューター等にインストールする場合には、登録ユーザーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は登録ユーザーに発生したかかる損害について一切責任を負わないものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第14条（権利帰属）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当社ウェブサイト、本サービス及び本データ等に関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、当社ウェブサイト、本サービス又は本データ等に関する当社又は当社にライセンスを許諾している者の知的財産権の譲渡又は使用許諾を意味するものではありません。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、サービス提供を通じて取得した情報を当社がサービス改善の目的で無償利用することに予め同意します。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これに限定されません。）をしないものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第15条（本データ等の取扱い）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        登録ユーザーは、自己が運営する宿泊施設の運営のためにのみ本データ等を利用することができるものとし、それ以外の目的で本データ等を使用してはならないものとします。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーによる本サービス利用によって得たチェックイン情報及びチェックインに関するデータは、旅館業法に基づき、チェックイン日から起算して3年間分を保存します。ただし、登録ユーザーが登録を取り消した場合はこの限りでは有りません。また、これらの情報が損失しても、当社は何ら責任を負わないものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第16条（登録取消等）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当社は、登録ユーザーが以下の各号のいずれかの事由に該当する場合、事前通知又は催告することなく、当該登録ユーザーについて本サービスの利用を一時的に停止し、又は登録ユーザーの登録を取り消すことができます。
      </li>
      <ol class="list-alpha px-6 mb-4">
        <li class="text-xs leading-relaxed">本規約のいずれかの条項に違反した場合</li>
        <li class="text-xs leading-relaxed">登録情報に虚偽の事実があることが判明した場合</li>
        <li class="text-xs leading-relaxed">
          当社、他の登録ユーザー、外部事業者その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
        </li>
        <li class="text-xs leading-relaxed">手段の如何を問わず、本サービスの運営を妨害した場合</li>
        <li class="text-xs leading-relaxed">
          支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
        </li>
        <li class="text-xs leading-relaxed">
          自ら振出し、若しくは引受けた手形又は小切手につき、不渡りの処分を受けた場合
        </li>
        <li class="text-xs leading-relaxed">
          差押、仮差押、仮処分、強制執行又は競売の申立てがあった場合
        </li>
        <li class="text-xs leading-relaxed">租税公課の滞納処分を受けた場合</li>
        <li class="text-xs leading-relaxed">
          死亡した場合又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
        </li>
        <li class="text-xs leading-relaxed">第3条第3項各号に該当する場合</li>
        <li class="text-xs leading-relaxed">
          登録ユーザー自ら当社に対して当社所定の方法によって登録取消を申し出た場合
        </li>
        <li class="text-xs leading-relaxed">
          その他、当社が登録ユーザーとしての登録の継続を適当でないと判断した場合
        </li>
      </ol>
      <li class="text-xs leading-relaxed">
        前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
      </li>
      <li class="text-xs leading-relaxed">
        当社は本条に基づき当社が行った行為により登録ユーザーに生じた損害について一切責任を負いません。
      </li>
      <li class="text-xs leading-relaxed">
        本条に基づき登録ユーザーの登録が取り消された場合、登録ユーザーは、当社の指示に基づき、当社から提供を受けた本サービスに関連するソフトウェア、マニュアルその他の物につき、返還、廃棄その他の処分を行うものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第17条（無保証及び免責）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当社は本サービスの目的適合性、商業的有用性、完全性、正確性、継続性、本サービス利用が第三者の権利侵害をしないこと等を含め、一切保証を致しません。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーが当社から直接又は間接に、本サービス、当社ウェブサイト、本サービスの他の登録ユーザー、登録ユーザーの運営するホテルを利用しようとする者その他の事項に関する何らかの情報を得た場合であっても、当社は登録ユーザーに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、本サービスを利用することが、登録ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、登録ユーザーによる本サービスの利用が、登録ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
      </li>
      <li class="text-xs leading-relaxed">
        本サービス又は当社ウェブサイトに関連して登録ユーザーと他の登録ユーザーその他の第三者との間において生じた取引、連絡、紛争等については、登録ユーザーの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
      </li>
      <li class="text-xs leading-relaxed">
        当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、登録ユーザーの情報削除又は消失､登録ユーザーの登録取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して登録ユーザーが被った損害につき、賠償する責任を一切負わないものとします。
      </li>
      <li class="text-xs leading-relaxed">
        当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
      </li>
      <li class="text-xs leading-relaxed">
        当社は、本サービスに関連して登録ユーザーが被った損害について、一切賠償の責任を負いません。なお、何らかの理由により、本項その他当社の損害賠償責任を免責する規定にかかわらず当社が登録ユーザーに対して損害賠償責任を負う場合においても、当社の賠償責任は、損害の事由が生じた時点から遡って過去3ヶ月の間に登録ユーザーから現実に受領した利用料金の総額（但し、過去3ヶ月の間に登録ユーザーから利用料金を現実に受領していない場合には1000円）を上限とします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第18条（ユーザーの賠償等の責任）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        登録ユーザーは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーが、本サービスに関連して他の登録ユーザー、外部事業者その他の第三者からクレームを受け又はそれらの者との間で紛争を生じた場合には、直ちにその内容を当社に通知するとともに、登録ユーザーの費用と責任において当該クレーム又は紛争を処理し、当社からの要請に基づき、その経過及び結果を当社に報告するものとします。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーによる本サービスの利用に関連して、当社が、他の登録ユーザー、外部事業者その他の第三者から権利侵害その他の理由により何らかの請求を受けた場合は、登録ユーザーは当該請求に基づき当社が当該第三者に支払を余儀なくされた金額を賠償しなければなりません。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第19条（秘密保持）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        登録ユーザーは本契約並びに本サービスに関して当社から開示された情報(以下、「秘密情報」という)を、機密として保持すると共にそのための合理的な措置を講じ、当社の事前の承諾なく、第三者に開示及び漏洩、ならびに本サービスの利用の目的以外に使用してはならない。但し、以下の各号の情報は、「秘密情報」に該当しないものとします。
      </li>
      <ol class="list-alpha px-6 mb-4">
        <li class="text-xs leading-relaxed">当社から開示された時点で、公知である情報</li>
        <li class="text-xs leading-relaxed">
          当社から開示された後、登録ユーザーらの責によらず公知となった情報
        </li>
        <li class="text-xs leading-relaxed">
          第三者から、機密保持義務を負うことなく合法的に入手した情報
        </li>
        <li class="text-xs leading-relaxed">
          当社から開示された情報によることなく独自に開発した情報
        </li>
      </ol>
      <li class="text-xs leading-relaxed">
        第1項の定めに拘わらず、登録ユーザーは、以下に該当する場合についてのみ秘密情報を開示することができます。但し、開示が必要な場合、速やかにその旨を当社に通知するものとし、開示先に対して本書に定めるものと同様の守秘義務を負わせることとします。
      </li>
      <ol class="list-alpha px-6 mb-4">
        <li class="text-xs leading-relaxed">
          法令、通達その他の行政上又は司法上の手続に従い、秘密情報の開示を要求された場合
        </li>
        <li class="text-xs leading-relaxed">
          格付機関、信託会社、金融機関、その他本件目的に関係する者
        </li>
        <li class="text-xs leading-relaxed">
          法令上守秘義務を負う弁護士、公認会計士、不動産鑑定士等の専門家
        </li>
      </ol>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第20条（個人情報の取り扱い）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        登録ユーザーは、本サービス遂行のため当社より提供を受けた営業上その他業務上の情報に含まれる個人情報(個人情報の保護に関する法律に定める個人情報、以下「個人情報」という)を本サービス利用の目的の範囲内でのみ使用し、第三者に開示又は漏洩しないものとするとともに、個人情報に関して個人情報の保護に関することを含め関連法令を遵守するものとします。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、個人情報への不当なアクセスまたは個人情報の紛失、破壊、改竄、漏洩等（以下「漏洩等」といいます。）の危険に対して、最善の安全対策を講じなければならず、漏洩等が生じた場合には一切の責任を負うものとします。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、自己が個人情報の保護に関する法律上の個人情報取扱事業者に該当するか否かを問わず、同法に定める個人情報取扱事業者としての義務その他関連法令・諸規則等を遵守しなければなりません。
      </li>
      <li class="text-xs leading-relaxed">
        個人情報に関する機密保持義務は、本契約又はトライアル終了後も期間の定めなく有効に存続するものとします。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、秘密情報を記載した文書又は磁気記録媒体等を複製する場合には、事前に当社の書面による承諾を得ることとし、複製物の管理については第1項から第4項までに準じて厳重に行うものとします。
      </li>
      <li class="text-xs leading-relaxed">
        登録ユーザーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第21条（有効期間）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      利用契約は、登録ユーザーについて第3条に基づく登録が完了した日に効力を生じ、登録の際に当社と登録ユーザー間で合意した期間有効に存続するものとします。
      但し、当該登録ユーザーの登録が取り消された場合には登録が取り消された時点をもって利用規約は終了するものとし、
      また、本サービスの提供が終了した場合には本サービスの提供が終了した時点をもって利用契約は終了するものとします。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第22条（本規約等の変更）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      利用契約は、登録ユーザーについて第3条に基づく登録が完了した日に効力を生じ、登録の際に当社と登録ユーザー間で合意した期間有効に存続するものとします。但し、当該登録ユーザーの登録が取り消された場合には登録が取り消された時点をもって利用規約は終了するものとし、また、本サービスの提供が終了した場合には本サービスの提供が終了した時点をもって利用契約は終了するものとします。
    </p>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当社は、本サービスの内容を自由に変更できるものとします。
      </li>
      <li class="text-xs leading-relaxed">
        当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更した場合には、登録ユーザーに当該変更内容を通知するものとし、当該変更内容の通知後、登録ユーザーが本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合、登録ユーザーは本規約の変更に同意したものとみなします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第23条（連絡/通知）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から登録ユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第24条（本規約の譲渡等）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
      </li>
      <li class="text-xs leading-relaxed">
        当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第25条（完全合意）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      本規約は、本規約に含まれる事項に関する当社と登録ユーザーとの完全な合意を構成し、口頭又は書面を問わず、本規約に含まれる事項に関する当社と登録ユーザーとの事前の合意、表明及び了解に優先します。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第26条（分離可能性）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び登録ユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第27条（存続規定）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      第6条、第8条第2項、第11条第2項、第12条第3項、第13条、第14条、第15条、第16条第2項、第3項及び第4項、第17条から第19条まで、並びに第24条から第28条までの規定は利用契約の終了後も有効に存続するものとします。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第28条（準拠法及び管轄裁判所）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第29条（協議解決）</h3>
    <p class="mb-4 text-sm leading-relaxed">
      当社及び登録ユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
    </p>

    <p class="font-semibold text-sm mb-1 mt-8">2019年09月27日 制定</p>
    <p class="font-semibold text-sm mb-1">2019年11月15日 改定</p>
    <p class="font-semibold text-sm mb-1">2020年01月08日 改定</p>
    <p class="font-semibold text-sm mb-1">2020年10月29日 改定</p>
    <p class="font-semibold text-sm mb-1">2021年07月28日 改定</p>
    <p class="font-semibold text-sm mb-1">2021年08月06日 改定</p>
    <p class="font-semibold text-sm mb-1">2022年04月15日 改定</p>
  </div>
</template>

<script>
export default {
  name: 'JpTermsOfUseHotelContent',
}
</script>
