<script>
export default {
  name: 'TabsList',
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: [String, Number, Object],
      required: true,
    },
    getValue: {
      type: Function,
      required: false,
    },
    getLabel: {
      type: Function,
      required: false,
    },
    getKey: {
      type: Function,
      required: false,
    },
  },
  methods: {
    changeTab(tab) {
      this.$emit('changeTab', this.getValue ? this.getValue(tab) : tab)
    },
    isActiveTab(tab) {
      if (this.getKey) {
        return this.getKey(this.activeTab) === this.getKey(tab)
      }

      return this.getValue ? this.activeTab === this.getValue(tab) : this.activeTab === tab
    },
  },
}
</script>

<template>
  <div class="bg-white overflow-x-auto scrollbar-none">
    <ul class="flex">
      <li
        v-for="(tab, index) in tabs"
        :key="tab"
        :class="{
          'md:ml-auto': index === 0,
          'md:mr-auto': index === tabs.length - 1,
        }"
      >
        <a
          @click="changeTab(tab)"
          :class="{
            'text-amber border-amber': isActiveTab(tab),
            'text-gray-light border-white': !isActiveTab(tab),
          }"
          class="block px-4 py-2 cursor-pointer font-bold text-sm transition-all duration-300 border-b-2 max-w-[146px] truncate"
        >
          {{ getLabel ? getLabel(tab) : tab }}
        </a>
      </li>
    </ul>
  </div>
</template>
