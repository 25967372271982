<template>
  <div class="w-full flex flex-col justify-start items-center">
    <TextField
      v-model="formData.cardNumber"
      :rules="fieldValidators.required()"
      :label="$t('Card Number')"
      mask="#### #### #### ####"
      placeholder="1234 1234 1234 1234"
      name="cardNumber"
      type="tel"
    />
    <img
      :src="require('@/assets/images/payment_logo.jpg')"
      alt="paymentLogo"
      class="h-[24px] mb-4"
    />
    <TextField
      v-model="formData.cardExpire"
      :rules="fieldValidators.required()"
      mask="##/##"
      placeholder="MM/YY"
      name="cardExpire"
      type="tel"
    />
    <TextField
      v-model="formData.name"
      :rules="fieldValidators.required()"
      :label="$t('Cardholder\'s Name')"
      placeholder="TARO YAMADA"
      name="name"
    />
    <TextField
      v-model="formData.securityCode"
      :rules="fieldValidators.required()"
      :label="$t('CVV')"
      name="securityCode"
      placeholder="000"
      mask="####"
      type="tel"
    />
  </div>
</template>

<script>
import formValidatorsMixin from '@/mixins/formValidatorsMixin'

export default {
  name: 'CardForm',
  mixins: [formValidatorsMixin],
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('update:modelValue', this.formData)
      },
    },
  },
  data() {
    return {
      formData: {
        cardNumber: '',
        cardExpire: '',
        name: '',
        securityCode: '',
      },
    }
  },
}
</script>
