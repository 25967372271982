<template>
  <div
    class="min-h-screen flex flex-col justify-start align-center"
    :class="{
      'tracking-[.15rem]': $i18next.language === 'ja',
      'tracking-[.06rem]': $i18next.language !== 'ja',
    }"
  >
    <HeaderSection
      v-if="!hideHeader"
      :title="title"
      :backRoute="backRoute"
      :hideBackRoute="hideBackRoute"
      :bordered="borderedHeader"
      class="fixed"
    />
    <slot name="staticTop" />
    <div :class="class_list" class="h-full">
      <SpinnerLoader v-if="mainLoading || loading" />
      <slot v-else />
    </div>
    <FooterSection />
    <slot name="staticBottom" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import HeaderSection from '@/components/sections/Header'
import FooterSection from '@/components/sections/Footer'
import SpinnerLoader from '@/components/blocks/loader/Loader'

export default {
  name: 'MainLayout',
  components: {
    HeaderSection,
    FooterSection,
    SpinnerLoader,
  },
  props: {
    title: String,
    loading: Boolean,
    hideHeader: Boolean,
    withoutContainer: Boolean,
    backRoute: String,
    hideBackRoute: Boolean,
    borderedHeader: Boolean,
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['mainLoading']),
    class_list() {
      let classList = ''

      if (!this.withoutContainer) {
        classList += ' w-full min-h-full flex-1 bg-my-page flex flex-col items-center py-4'
      }
      if (!this.hideHeader) classList += ` mt-[56px]`
      if (this.class) classList += ` ${this.class}`

      return classList
    },
  },
}
</script>
