<template>
  <header
    v-if="staticType"
    :class="{
      'border-b border-gray-super-light': bordered,
      'shadow-md': !bordered,
    }"
    class="w-full min-h-[56px] p-4 px-16 z-40 align-start text-center bg-white"
  >
    <img :src="require('@/assets/images/aipasslogo.svg')" alt="aipasslogo" class="h-[35px]" />
  </header>
  <header
    v-else
    :class="{
      'border-b border-gray-super-light': bordered,
      'shadow-md': !bordered,
    }"
    class="w-full p-4 z-40 align-middle text-center bg-white"
  >
    <img
      v-if="!hideBackRoute"
      :src="require('@/assets/images/controls/arrow.svg')"
      @click="handleGoHome"
      class="w-10 h-10 absolute left-2 top-2 cursor-pointer"
    />
    <p class="text-small font-semibold truncate mx-8">
      {{ title }}
    </p>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HeaderSection',
  props: {
    title: {
      type: String,
      default: '',
    },
    hideBackRoute: {
      type: Boolean,
      default: false,
    },
    staticType: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    backRoute: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['hotelGuideData']),
  },
  methods: {
    handleGoHome() {
      if (!this.backRoute || window.history?.state?.back?.startsWith('/register-account')) {
        this.$router.go(-1)
      } else {
        this.$router.replace({ name: this.backRoute })
      }
    },
  },
}
</script>
