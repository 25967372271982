<template>
  <div
    class="min-h-screen flex flex-col justify-start align-center"
    :class="{
      'tracking-[.15rem]': $i18next.language === 'ja',
      'tracking-[.06rem]': $i18next.language !== 'ja',
    }"
  >
    <HeaderSection class="fixed" staticType />
    <slot name="staticTop" />
    <div class="w-full min-h-full flex-1 flex flex-col items-center p-4">
      <slot />
    </div>
    <FooterSection />
  </div>
</template>

<script>
import HeaderSection from '@/components/sections/Header'
import FooterSection from '@/components/sections/Footer'

export default {
  name: 'StaticLayout',
  components: {
    HeaderSection,
    FooterSection,
  },
}
</script>
