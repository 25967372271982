<template>
  <Modal v-model="isModalShow" @click-outside="$emit('close-modal')">
    <div class="flex flex-col justify-center items-center bg-white p-6 rounded">
      <div class="text-center font-semibold text-lg my-1">
        {{ $t('Call to front') }}
      </div>
      <div class="text-center font-semibold text-lg my-1">
        {{ modalData?.telephone }}
      </div>
      <div class="text-center text-gray text-sm mt-1 mb-4">
        {{ $t('Open') }}: {{ modalData?.frontOpenTime }} 〜
        {{ modalData?.frontCloseTime }}
      </div>
      <a :href="'tel:' + modalData?.telephone">
        <Button class="mt-0">
          {{ $t('Call') }}
        </Button>
      </a>
      <Button @click="$emit('close-modal')" mode="outline">
        {{ $t('Cancel') }}
      </Button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/UI/Modal'

export default {
  name: 'CallToFrontModal',
  components: {
    Modal,
  },
  props: {
    isShow: {
      type: Boolean,
    },
    modalData: {
      type: Object,
    },
  },
  watch: {
    isShow() {
      this.isModalShow = this.isShow
    },
  },
  data() {
    return {
      isModalShow: false,
    }
  },
}
</script>
