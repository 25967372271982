<template>
  <MainLayout :title="page_title" :loading="loading" backRoute="infoMenu">
    <div class="w-full max-w-[600px] html-wrap bg-white p-4" v-html="pageData"></div>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import { redirectTo404 } from '@/router'

export default {
  name: 'DetailInfoView',
  components: {
    MainLayout,
  },
  data() {
    return {
      loading: true,
      pageData: null,
    }
  },
  computed: {
    ...mapGetters(['facilityHotelInfo', 'hotelGuideData']),
    page_title() {
      return this.$route.meta?.getPageTitle() || 'N/A'
    },
    page_index() {
      return this.$route.meta.pageIndex
    },
  },
  watch: {
    $route() {
      this.setPageData()
    },
  },
  async mounted() {
    try {
      if (!this.page_index) return this.$router.replace({ name: 'infoMenu' })

      this.loading = true

      if (!this.facilityHotelInfo) await this.getFacilityInfo(this.hotelGuideData.hotelId)

      this.setPageData()
    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions(['getFacilityInfo', 'globalErrorHandler']),
    setPageData() {
      const filteredData = this.facilityHotelInfo?.facilityAdditionalPageInfo.find(
        i => i.page_index == this.page_index
      )

      if (!filteredData) return redirectTo404()

      this.pageData =
        filteredData[
          `${this.$i18next.language === 'ja' ? 'jp' : this.$i18next.language}_content`
        ] ||
        filteredData.jp_content ||
        filteredData.en_content
    },
  },
}
</script>
