<template>
  <MainLayout
    :title="$t('Travel guide')"
    :loading="loading"
    backRoute="home"
    style="font-family: Noto Sans JP, Lato, sans-serif"
  >
    <template #staticTop>
      <TravelGuideMenu
        v-model="selectedCategory"
        :categories="categories"
        class="fixed left-0 right-0 top-[72px] z-10"
      />
    </template>
    <div class="relative w-full">
      <div class="p-4 mt-12">
        <div
          v-if="jtb_boken && selectedCategory?.code === 'popular'"
          :data-src="jtb_boken?.dataSrc"
          class="bokunWidget pt-1"
        ></div>
        <div v-else class="flex flex-col items-center">
          <AroundSpotListCard
            v-for="aroundSpot of aroundSpotList"
            :key="aroundSpot.id"
            :aroundSpot="aroundSpot"
          />
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import TravelGuideMenu from '@/components/blocks/travelGuide/TravelGuideMenu'
import AroundSpotListCard from '@/components/blocks/travelGuide/AroundSpotListCard'
import { capitalize } from '@/utils/text'

export default {
  name: 'TravelGuideListView',
  components: {
    MainLayout,
    TravelGuideMenu,
    AroundSpotListCard,
  },
  data() {
    return {
      loading: false,
      selectedCategory: undefined,
      aroundSpotList: [],
    }
  },
  computed: {
    ...mapGetters(['hotelGuideData']),
    categories() {
      const categories = [
        { title: capitalize(this.$t('all')), code: 0 },
        { title: capitalize(this.$t('food')), code: 1 },
        { title: capitalize(this.$t('activity')), code: 2 },
        { title: capitalize(this.$t('shopping')), code: 3 },
      ]
      if (this.jtb_boken) {
        categories.unshift({ title: capitalize(this.$t('popular')), code: 'popular' })
      }

      return categories
    },
    jtb_boken() {
      if (
        this.hotelGuideData.facilityBasicInfo.boken.script &&
        this.hotelGuideData.facilityBasicInfo.boken.dataSrc
      ) {
        return this.hotelGuideData.facilityBasicInfo.boken
      }

      return undefined
    },
  },
  watch: {
    selectedCategory() {
      if (this.selectedCategory && this.selectedCategory?.code !== 'popular') {
        this.getAroundSpots()
      }

      this.$router.replace({
        query: { category: this.selectedCategory?.code },
      })
    },
  },
  async mounted() {
    try {
      if ('category' in this.$route.query) {
        this.selectedCategory = this.categories.find(
          category => category.code == this.$route.query.category
        )
      }
      if (!this.selectedCategory) this.selectedCategory = this.categories[0]

      this.loading = true

      if (this.jtb_boken) await this.appendBokenScript()
    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  beforeUnmount() {
    const bokunWidgetsScript = document.getElementById('bokun-widgets-script')
    const bokunWidgetsRoot = document.getElementById('bokun-widgets-root')

    if (bokunWidgetsScript) bokunWidgetsScript.remove()
    if (bokunWidgetsRoot) bokunWidgetsRoot.remove()
  },
  methods: {
    ...mapActions(['getAroundSpotList', 'globalErrorHandler']),
    appendBokenScript() {
      return new Promise(resolve => {
        const _script = document.createElement('script')

        _script.setAttribute('src', this.jtb_boken.script)
        _script.setAttribute('async', 'true')
        _script.onload = resolve
        _script.id = 'bokun-widgets-script'

        document.body.appendChild(_script)
      })
    },
    async getAroundSpots() {
      try {
        this.loading = true

        this.aroundSpotList = await this.getAroundSpotList({
          category: this.selectedCategory.code,
          hotelId: this.hotelGuideData.hotelId,
        })
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
