<script>
import { useField } from 'vee-validate'

import FieldMixin from '@/components/UI/formFields/mixins/FieldMixin.vue'

export default {
  mixins: [FieldMixin],
  props: {
    options: { type: Array, required: true },
    itemText: { type: String, default: 'label' },
    itemValue: { type: String, default: 'value' },
    modelValue: String,
  },
  setup(props) {
    const { errorMessage, handleChange, handleBlur, value } = useField(props.name, props.rules, {
      initialValue: props.modelValue,
    })

    return { errorMessage, handleChange, handleBlur, value }
  },
  watch: {
    value() {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>

<template>
  <div class="w-full flex flex-col mb-6">
    <label class="mb-2 text-small font-semibold" :for="name">
      {{ label }}
      <span v-if="is_required" class="text-[#272727]">*</span>
    </label>
    <div>
      <label
        v-for="(item, index) in options"
        :key="item[itemValue]"
        :class="{ 'ml-6': index > 0 }"
        class="inline-flex items-center"
      >
        <input
          @input="handleChange"
          @blur="handleBlur"
          :name="name"
          :value="item[itemValue]"
          :disabled="disabled"
          :checked="item[itemValue] === modelValue"
          class="w-[24px] h-[24px] accent-[#cb860e] font-lato"
          type="radio"
        />
        <span class="ml-2">{{ item[itemText] }}</span>
      </label>
    </div>
    <div class="mt-2 font-semibold text-red-500" v-if="errorMessage">{{ errorMessage }}</div>
  </div>
</template>
