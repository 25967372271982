<template>
  <MainLayout
    :title="$t('Check-out')"
    backRoute="checkoutSearch"
    class="flex flex-column items-center"
  >
    <div
      v-for="(item, index) of checkoutReservations"
      :key="index"
      :class="{
        'mb-4': index === checkoutReservations.length - 1,
        'border-b': index !== checkoutReservations.length - 1,
      }"
      class="w-full max-w-[600px] px-8 py-6 bg-white"
    >
      <p class="font-semibold truncate">{{ $t('Room') }}: {{ item?.room ? item.room : '-' }}</p>
      <p class="mt-2 text-xs font-semibold text-slate-600 leading-relaxed truncate">
        {{ $t('Reservation ID') }}: {{ item.reservationId ? item.reservationId : '-' }}
      </p>
    </div>
    <div class="w-full max-w-[600px] px-8 py-6 mb-4 bg-white">
      <p class="font-semibold text-center mb-4">
        {{ $t('How much do you want to recommend this stay to friends and family?') }}
      </p>
      <div class="flex flex-wrap justify-center items-center">
        <div
          v-for="(item, index) of 10"
          @click="reviewPoint = item"
          :key="index"
          :class="{ 'bg-amber': reviewPoint === item }"
          class="border border-slate-300 rounded-lg w-[23px] sm:w-[36px] text-center py-2 m-1 font-semibold text-slate-600 cursor-pointer"
        >
          <p :class="{ 'text-white': reviewPoint === item }">{{ item }}</p>
        </div>
      </div>
    </div>
    <div class="w-full max-w-[600px] px-8 py-6 bg-white">
      <p class="font-semibold text-center mb-2">
        {{ $t('Please tell us what we can improve.') }}
      </p>
      <p class="mb-4 text-xs font-semibold text-center text-slate-600 leading-relaxed">
        {{ $t('Multiple answers possible') }}
      </p>
      <div class="flex flex-wrap justify-around items-center mb-4">
        <div
          v-for="(item, index) of reviewForImprovement"
          :key="index"
          class="w-[30%] min-w-[86px] py-1 my-2 bg-my-page rounded-full text-center text-gray cursor-pointer truncate"
          :class="{ 'bg-amber': item.value }"
          @click="reviewForImprovement[index].value = !reviewForImprovement[index].value"
        >
          <p :class="{ 'text-white': item.value }">{{ capitalize(this.$t(item.name)) }}</p>
        </div>
      </div>
      <TextareaField v-model="comment" :label="$t('Add Comment')" class="mb-0" />
    </div>
    <Button @click="handleCheckout" :loading="loadingAction" class="mb-2 mt-6">
      {{ $t('Check-out') }}
    </Button>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import { capitalize } from '@/utils/text'

export default {
  name: 'CheckoutReviewView',
  components: {
    MainLayout,
  },
  data() {
    return {
      loadingAction: false,
      reviewPoint: null,
      reviewForImprovement: [
        { name: 'Staff', nameJp: '接客', value: false },
        { name: 'food', nameJp: '食事', value: false },
        { name: 'Cleanliness', nameJp: '清掃', value: false },
        { name: 'Room', nameJp: 'その他', value: false },
        { name: 'Facility', nameJp: '客室', value: false },
        { name: 'Other', nameJp: '設備', value: false },
      ],
      comment: '',
    }
  },
  computed: {
    ...mapGetters(['hotelGuideData', 'checkoutReservations', 'checkoutCheckins']),
  },
  methods: {
    capitalize,
    ...mapActions([
      'sendReservationReview',
      'checkout',
      'globalErrorHandler',
      'sendCheckinReview',
      'isEnabledPlugin',
    ]),
    async handleCheckout() {
      try {
        this.loadingAction = true

        const improvementsList = this.reviewForImprovement
          .filter(i => i.value)
          .map(i => i.nameJp)
          .join(',')

        if (this.checkoutReservations?.length) {
          for (const reservation of this.checkoutReservations) {
            const reviewPayload = {
              comment: this.comment,
              point: this.reviewPoint,
              reservationId: reservation.reservationId,
              improvement: improvementsList,
            }
            const checkoutPayload = {
              checkinId: '',
              reservationId: reservation.reservationId,
              withReview: true,
            }
            await this.sendReservationReview(reviewPayload)
            await this.checkout(checkoutPayload)
          }
        }

        if (this.checkoutCheckins?.length) {
          for (const checkin of this.checkoutCheckins) {
            const reviewPayload = {
              comment: this.comment,
              point: this.reviewPoint,
              checkinId: checkin.id,
              guestId: checkin.id,
              improvement: improvementsList,
            }
            const checkoutPayload = {
              checkinId: checkin.id,
              reservationId: '',
              withReview: true,
            }
            if (await this.isEnabledPlugin({ pluginCode: 'BR' })) {
              await this.sendReservationReview(reviewPayload)
            }
            await this.checkout(checkoutPayload)
          }
        }

        this.$router.push({ name: 'checkoutThanks' })
      } catch (error) {
        this.loadingAction = false

        this.globalErrorHandler({ error })
      }
    },
  },
}
</script>
