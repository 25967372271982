<template>
  <RouterLinkControl
    componentName="TravelGuideDetail"
    :queryParams="{ placeId: aroundSpot.placeId }"
    class="around-spot-card my-1 shadow-md cursor-pointer bg-white"
  >
    <div class="around-spot-card__content p-4">
      <div class="truncate">{{ main_facility_name }}</div>
      <div class="text-sm text-gray-400 truncate">
        {{ capitalize($t(aroundSpot.typeName)) }}
      </div>
      <div class="around-spot-card__content__icon">
        <img
          v-if="aroundSpot['category'] === 0"
          :src="require('@/assets/images/map_icon_hotel.svg')"
          alt="ホテル"
        />
        <img
          v-else-if="aroundSpot['category'] === 1"
          :src="require('@/assets/images/navbar/icon_eat.svg')"
          alt="食事"
        />
        <img
          v-else-if="aroundSpot['category'] === 2"
          :src="require('@/assets/images/navbar/icon_activity.svg')"
          alt="遊ぶ"
        />
        <img
          v-else-if="aroundSpot['category'] === 3"
          :src="require('@/assets/images/navbar/icon_buy.svg')"
          alt="買う"
        />
      </div>
    </div>

    <div class="around-spot-card__photo">
      <img :src="getGoogleApiPhoto(aroundSpot.photoReference)" :alt="aroundSpot.facilityName" />
    </div>
  </RouterLinkControl>
</template>

<script>
import RouterLinkControl from '@/components/controls/RouterLinkControl.vue'
import { getGoogleApiPhoto } from '@/utils/image'
import { capitalize } from '@/utils/text'

export default {
  name: 'AroundSpotListCard',
  components: { RouterLinkControl },
  props: { aroundSpot: Object },
  computed: {
    main_facility_name() {
      let name = ''
      if (this.$i18next.language === 'ja') {
        name = this.aroundSpot?.facilityName
      }
      if (this.$i18next.language === 'en') {
        name = this.aroundSpot?.facilityNameEn
      }
      if (this.$i18next.language === 'zh') {
        name = this.aroundSpot?.facilityNameZh
      }
      if (this.$i18next.language === 'ko') {
        name = this.aroundSpot?.facilityNameKo
      }
      if (name?.length > 12) return name.substr(0, 11)
      return name
    },
  },
  methods: {
    getGoogleApiPhoto,
    capitalize,
  },
}
</script>

<style lang="scss" scoped>
.around-spot-card {
  max-width: 340px;
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: space-between;
  &__content {
    position: relative;
    flex: 1;
    width: calc(100% - 110px);
    &__icon {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }

  &__photo {
    img {
      width: 110px;
      height: 110px;
      object-fit: cover;
    }
  }
}
</style>
