<template>
  <img v-if="bgImage" :src="bgImage" alt="main-bg" class="w-full h-[250px] object-cover" />
  <div v-else class="w-screen h-[250px] bg-head z-0"></div>
</template>

<script>
export default {
  name: 'MainHeadBlock',
  props: {
    bgImage: {
      type: String,
      default: '',
    },
  },
}
</script>
