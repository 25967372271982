<template>
  <StaticLayout>
    <EnTermsOfUseHotelContent v-if="$i18next.language === 'en'" />
    <JpTermsOfUseHotelContent v-else />
  </StaticLayout>
</template>

<script>
import StaticLayout from '@/components/layouts/StaticLayout'
import EnTermsOfUseHotelContent from '@/components/blocks/static/termsOfUseHotel/EnTermsOfUseHotelContent'
import JpTermsOfUseHotelContent from '@/components/blocks/static/termsOfUseHotel/JpTermsOfUseHotelContent'

export default {
  name: 'TermsOfUseHotelView',
  components: {
    StaticLayout,
    EnTermsOfUseHotelContent,
    JpTermsOfUseHotelContent,
  },
}
</script>
