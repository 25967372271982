<template>
  <div v-if="hotelCouponsList?.length" class="bg-[#fff7e8] -mx-10 flex">
    <div class="max-w-[320px] mx-auto py-10 w-full">
      <div class="text-center text-amber text-lg font-bold">
        {{ capitalize($t('recommended coupons')) }}
      </div>
      <TransitionGroup name="list" tag="ul">
        <li
          v-for="coupon in show_coupons_list"
          :key="coupon.id"
          class="bg-white overflow-hidden rounded-[0.625rem] w-full flex my-4 list-none cursor-pointer"
          @click="$router.push({ name: 'couponDetailView', params: { id: coupon.id } })"
        >
          <div
            class="flex-1 p-4 flex flex-col justify-between"
            style="max-width: calc(100% - 100px)"
          >
            <div class="text-amber line-clamp-2 text-sm font-bold">
              {{ getTitle(coupon) }}
            </div>
            <div class="text-xs mt-2 truncate font-bold text-gray">
              {{ getVenueName(coupon) }}
            </div>
          </div>
          <div class="w-[100px] h-[100px] bg-gray-300">
            <img
              v-if="coupon.image"
              :src="coupon.image"
              :alt="coupon.description"
              :title="coupon.title"
              class="object-cover w-full h-full"
            />
          </div>
        </li>
      </TransitionGroup>
      <div
        v-if="hotelCouponsList.length > 3"
        class="text-center text-amber text-sm cursor-pointer"
        @click="showAll = !showAll"
      >
        {{ capitalize($t(showAll ? 'close up' : 'show more')) }}
        <img
          :src="
            showAll
              ? require('@/assets/images/arrow_orange_up.svg')
              : require('@/assets/images/arrow_orange_down.svg')
          "
          class="block mx-auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from '@/utils/text'
import { mapActions } from 'vuex'

export default {
  name: 'CouponsListBlock',
  components: {},
  data() {
    return {
      loading: false,
      hotelCouponsList: [],
      showAll: false,
    }
  },
  computed: {
    hotel_info() {
      return this.hotelGuideData?.facilityBasicInfo || {}
    },
    show_coupons_list() {
      if (this.showAll) return this.hotelCouponsList
      else return this.hotelCouponsList.slice(0, 3)
    },
  },
  mounted() {
    this.loadCoupons()
  },
  methods: {
    capitalize,
    ...mapActions(['getHotelCouponsList', 'globalErrorHandler']),
    async loadCoupons() {
      try {
        this.loading = true

        const res = await this.getHotelCouponsList()
        this.hotelCouponsList = res.data
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
    getTitle(coupon) {
      if (this.$i18next.isJapanese()) return coupon?.title

      return coupon?.titleLatin || coupon?.title
    },
    getVenueName(coupon) {
      if (this.$i18next.isJapanese()) return coupon?.venueName

      return coupon?.venueNameLatin || coupon?.venueName
    },
  },
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
