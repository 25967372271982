<template>
  <div class="w-full max-w-[600px] bg-white flex flex-col justify-start items-center">
    <slot name="top" />
    <Form
      v-slot="{ meta }"
      @submit="() => handleLogin('email')"
      class="w-full border-b mb-6 p-8"
    >
      <TextField
        v-model="email"
        :placeholder="capitalize($t('email address'))"
        :label="$t('Email')"
        :rules="combineFieldValidators([fieldValidators.required(), this.fieldValidators.email()])"
        name="email"
        type="email"
        spellcheck="false"
        autocomplete="email"
        autocorrect="off"
        autocapitalize="off"
        class="mb-0"
      />
      <Button :loading="loading" :disabled="!meta.valid" class="mx-auto w-[260xp]" type="submit">
        {{ $t('Next') }}
      </Button>
    </Form>
    <div class="flex flex-col items-center">
      <button
        @click="handleLogin('facebook')"
        class="w-[260px] h-[44px] mb-6 border-blue-500 text-blue-500 text-md border rounded-full relative"
        type="button"
      >
        <img
          :src="require('@/assets/images/controls/facebook.svg')"
          alt="logo"
          class="absolute left-4 top-[9px]"
        />
        {{ $t('Facebook') }}
      </button>
      <button
        @click="handleLogin('google-oauth2')"
        class="w-[260px] h-[44px] mb-6 border-gray-500 text-gray-500 text-md border rounded-full relative"
        type="button"
      >
        <img
          :src="require('@/assets/images/controls/google.svg')"
          alt="logo"
          class="absolute left-4 top-[9px]"
        />
        {{ $t('Google') }}
      </button>
    </div>
    <button
      @click="handleLogin('line')"
      class="w-[260px] h-[44px] mb-6 text-xs border-t-[1px] pt-5 text-amber"
      type="button"
    >
      {{ $t('Guest who have logged-in on LINE') }}
    </button>
  </div>
  <div class="text-center text-xs text-slate-600 mt-4">
    <div v-if="$i18next.language === 'ja'">
      <RouterLinkControl componentName="termsOfUse" target="_blank">
        <span class="text-amber text-xs">
          {{ $t('Terms of use') }}
        </span>
      </RouterLinkControl>
      及び
      <RouterLinkControl componentName="PrivacyPolicyView" target="_blank">
        <span class="text-amber text-xs">
          {{ $t('Privacy policy') }}
        </span>
      </RouterLinkControl>
      に
      <br />
      同意の上、ご利用ください
    </div>
    <div v-if="$i18next.language === 'en'">
      I agree with
      <br />
      the
      <RouterLinkControl componentName="termsOfUse" target="_blank">
        <span class="text-amber text-xs">
          {{ $t('Terms of use') }}
        </span>
      </RouterLinkControl>
      and
      <RouterLinkControl componentName="PrivacyPolicyView" target="_blank">
        <span class="text-amber text-xs">
          {{ $t('Privacy policy') }}
        </span>
      </RouterLinkControl>
    </div>
    <div v-if="$i18next.language === 'zh'">
      请在同意
      <RouterLinkControl componentName="termsOfUse" target="_blank">
        <span class="text-amber text-xs">
          {{ $t('Terms of use') }}
        </span>
      </RouterLinkControl>
      和隐
      <RouterLinkControl componentName="PrivacyPolicyView" target="_blank">
        <span class="text-amber text-xs">
          {{ $t('Privacy policy') }}
        </span>
      </RouterLinkControl>

      后使用
    </div>
    <div v-if="$i18next.language === 'ko'">
      이용 약관
      <RouterLinkControl componentName="termsOfUse" target="_blank">
        <span class="text-amber text-xs">
          {{ $t('Terms of use') }}
        </span>
      </RouterLinkControl>
      및
      <RouterLinkControl componentName="PrivacyPolicyView" target="_blank">
        <span class="text-amber text-xs">
          {{ $t('Privacy policy') }}
        </span>
      </RouterLinkControl>
      에 동의 한 후,
      <br />
      이용하십시오
    </div>
  </div>
  <Modal
    v-model="isShow"
    @cancel="closeModal"
    content-class="relative w-[600px] p-8 flex flex-col max-h-full mx-4 p-4 border rounded bg-white"
  >
    <p class="text-center text-xl font-semibold">
      {{ $t('Notes on using LINE') }}
    </p>
    <ul class="mt-6">
      <li class="text-xs mb-2">・{{ $t('I Browser is not in secret/private mode') }}.</li>
      <li class="text-xs mb-2">・{{ $t('Email address is already set up on LINE') }}.</li>
      <li class="text-xs mb-2">・{{ $t('Permission to access your contacts on LINE') }}.</li>
    </ul>
    <div class="flex justify-center items-center mt-8 mb-6">
      <input
        v-model="checked"
        id="checked"
        type="checkbox"
        class="w-[20px] h-[20px] accent-[#cb860e]"
      />
      <label for="checked" class="text-xs ml-2">{{ $t('I checked') }}</label>
    </div>
    <div class="flex flex-col justify-center items-center pt-4">
      <button
        @click="socialSignup('line')"
        :class="get_class"
        :disabled="!checked"
        class="w-[260px] h-[44px] font-lato text-white text-sm flex justify-center items-center rounded-full"
        type="button"
      >
        {{ $t('Login') }}
      </button>
      <button
        @click="isShow = false"
        class="mt-4 w-[260px] h-[44px] font-lato text-amber text-sm flex justify-center items-center border border-amber rounded-full"
        type="button"
      >
        {{ capitalize($t('go back')) }}
      </button>
    </div>
  </Modal>
  <ResetPasswordModal
    :isShow="showSuccessResetPasswordModal"
    :email="email"
    :mailStatus="mailStatus"
    @close-modal="() => (showSuccessResetPasswordModal = false)"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'

import Modal from '@/components/UI/Modal'
import RouterLinkControl from '@/components/controls/RouterLinkControl'
import ResetPasswordModal from '@/components/blocks/auth/ResetPasswordModal.vue'
import formValidatorsMixin from '@/mixins/formValidatorsMixin.vue'
import { capitalize } from '@/utils/text'

export default {
  name: 'LoginBlock',
  mixins: [formValidatorsMixin],
  components: {
    RouterLinkControl,
    ResetPasswordModal,
    Modal,
    Form,
  },
  props: {
    loginRedirect: { name: String, query: Object, params: Object },
  },
  data() {
    return {
      loading: false,
      isShow: false,
      checked: false,
      email: undefined,
      mailStatus: null,
      showSuccessResetPasswordModal: false,
    }
  },
  computed: {
    ...mapGetters(['hotelGuideData']),
    get_class() {
      return !this.checked ? 'bg-zinc-300' : 'bg-amber'
    },
  },
  methods: {
    capitalize,
    ...mapActions([
      'socialSignup',
      'checkEmail',
      'forgotPassword',
      'storeLoginRedirect',
      'globalErrorHandler',
    ]),
    async handleLogin(type) {
      try {
        this.loading = true

        this.storeLoginRedirect({
          redirectTo: this.loginRedirect,
          redirectedFrom: this.$router.currentRoute.value,
        })

        if (type === 'email') {
          const result = await this.checkEmail(this.email)

          switch (result) {
            case 'reset':
              await this.forgotPassword({
                email: this.email,
                hotelId: this.$route.query.hotelId,
                lang: this.$route.query.lang,
              })
              this.showSuccessResetPasswordModal = true
              this.mailStatus = result;
              return
            default:
              this.$router.push({
                name: 'emailLogin',
                query: { email: this.email, userStatus: result },
              })
              break
          }
        } else if (type === 'line') {
          this.openModal()
        } else {
          await this.socialSignup(type)
        }
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
    openModal() {
      this.isShow = true
    },
    closeModal() {
      this.isShow = false
    },
  },
}
</script>
