<template>
  <div class="w-full max-w-[600px] content-container">
    <SpinnerLoader v-if="loading" />
    <template v-else>
      <div
        v-for="(item, index) of formData"
        :key="index"
        class="p-8 bg-white flex flex-col justify-start items-center border-b border-gray-super-light"
      >
        <!-- type select -->
        <SelectField
          v-if="item.inputType === 'selectBox'"
          v-model="item.value"
          :label="$i18next.getValueByLanguage(item, 'title')"
          :content="$i18next.getValueByLanguage(item, 'content')"
          :options="$i18next.getValueByLanguage(item, 'inputValue')"
          :emptyItemTitle="$t('Please select')"
          :name="`fields[${index}].value`"
          :rules="isRequired(item?.isRequired)"
        />
        <!-- type textarea -->
        <TextareaField
          v-if="item.inputType === 'textArea'"
          v-model="item.value"
          :name="`fields[${index}].value`"
          :content="$i18next.getValueByLanguage(item, 'content')"
          :label="$i18next.getValueByLanguage(item, 'title')"
          :rules="isRequired(item?.isRequired)"
        />
        <!-- no field -->
        <div v-if="item.inputType === 'none'" class="w-full flex flex-col">
          <label class="mb-4 text-base font-semibold">
            {{ $i18next.getValueByLanguage(item, 'title') }}
          </label>
          <div
            v-if="$i18next.getValueByLanguage(item, 'content')"
            v-html="$i18next.getValueByLanguage(item, 'content')"
            class="mb-4 text-sm leading-6"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import SpinnerLoader from '@/components/blocks/loader/Loader'
import formValidatorsMixin from '@/mixins/formValidatorsMixin'

export default {
  mixins: [formValidatorsMixin],
  components: {
    SpinnerLoader,
  },
  props: {
    modelValue: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      formData: [],
    }
  },
  computed: {
    ...mapGetters(['customCheckinFields', 'customCheckinData']),
  },
  async mounted() {
    try {
      this.loading = true

      await this.getCustomCheckinFields()

      this.initData()
    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', this.formData)
      },
    },
  },
  methods: {
    ...mapActions(['getCustomCheckinFields', 'setCustomCheckinData', 'globalErrorHandler']),
    initData() {
      if (this.customCheckinData && Object.values(this.customCheckinData)?.length) {
        this.formData = cloneDeep(this.customCheckinData)
      } else {
        this.formData = cloneDeep(this.customCheckinFields)
      }
    },
    isRequired(isRequired) {
      if (Boolean(isRequired) === true) return this.fieldValidators.required()
    },
  },
}
</script>

<style scoped>
.content-container::v-deep ul li {
  list-style: disc;
}
.content-container::v-deep ul {
  margin-left: 16px;
}
.content-container::v-deep ol li {
  list-style: decimal;
}
.content-container::v-deep ol {
  margin-left: 26px;
}
</style>
