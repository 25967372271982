<template>
  <Modal v-model="isModalShow" :clickToClose="false">
    <div class="flex flex-col justify-center items-center bg-white p-6 rounded max-w-[320px]">
      <div class="h-24 w-24 rounded-full flex items-center justify-center bg-amber my-10">
        <img :src="require('@/assets/images/icons8-check.svg')" alt="" />
      </div>
      <div class="text-center font-semibold text-xl mb-3 text-amber">
        {{ $t('Password Updated') }}
      </div>
      <div class="text-center text-gray text-xs mb-10">
        {{ $t('Please close this window and log in again with a new password.') }}
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/UI/Modal'

export default {
  name: 'ResetPasswordConfirmModal',
  components: {
    Modal,
  },
  props: {
    isShow: {
      type: Boolean,
    },
  },
  watch: {
    isShow() {
      this.isModalShow = this.isShow
    },
  },
  data() {
    return {
      isModalShow: false,
    }
  },
}
</script>
