<template>
  <router-view />
</template>
<script>
export default {
  watch: {
    $route() {
      const lng =
        this.$route.query.lang ||
        localStorage?.getItem('app_lang') ||
        process.env.VUE_APP_I18N_LOCALE ||
        'ja'

      this.$i18next.changeLanguage(lng)

      localStorage?.setItem('app_lang', lng)
    },
  },
}
</script>
