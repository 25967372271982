import * as Sentry from '@sentry/vue'

import api from '../api'

const state = {
  userData: null,
  userInfo: null,
}

const getters = {
  userData: state => state.userData,
}

const mutations = {
  SET_USER_DATA(state, data) {
    state.userData = data

    if (process.env.VUE_APP_SENTRY_DSN) Sentry.setUser(data)
  },
}

const actions = {
  getUserInfo({ commit }) {
    return api.get('me/account-info').then(response => {
      commit('SET_USER_DATA', response.data?.user)

      return response.data?.user
    })
  },
  getUserPayment() {
    return api.get('me/account-payment').then(response => response.data)
  },
  getInvoiceDetails(_, { cuicinUserId, orderId }) {
    return api.get('payment/usage-detail', { params: { cuicinUserId, orderId } }).then(res => res.data || [])
  },
  updateUserInfo({ dispatch }, data) {
    return api.put('me/account-info', data).then(() => dispatch('getUserInfo'))
  },
  getUserCheckinHistoryList(_, data) {
    return api.get('checkin-history', { params: data }).then(response => response.data?.checkinHistorys || [])
  },
  getUserCheckinDetails(_, data) {
    return api.get('checkin-history-detail', { params: data }).then(response => {
      return response.data.checkinHistory[0]
    })
  },
  async addCreditCard(_, data) {
    const requestParams = {
      token_api_key: process.env.VUE_APP_AIPASS_VERITRANS_API_KEY,
      card_number: data.cardNumber,
      card_expire: data.cardExpire,
      security_code: data.securityCode,
    }
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    }
    const response = await api.post('https://api.veritrans.co.jp/4gtoken', requestParams, {
      headers,
      withCredentials: true,
    })
    response.data.token
    return api
      .post('payment/cuicin-user-card', {
        cuicinUserId: data.cuicinUserId,
        cardToken: response.data.token,
      })
      .then(response => response.data.processResult)
  },
  getCreditCard(_, data) {
    return api.get('payment/cuicin-user-card', { params: data }).then(response => response.data.accountInfo)
  },
  deleteCreditCard(_, data) {
    return api.delete('payment/cuicin-user-card', { params: data })
  },
  fetchZipCode(_, zipcode) {
    return api.get('zipcode-search', { params: { zipcode } })
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
}
