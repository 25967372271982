<template>
  <button :class="class_list" :disabled="is_disabled" :type="type">
    <SpinnerLoader v-if="loading" :size="24" :color="mode === 'outline' ? '#d68e0f' : '#fff'" />
    <slot v-else />
  </button>
</template>

<script>
import SpinnerLoader from '@/components/blocks/loader/Loader'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Button',
  components: { SpinnerLoader },
  props: {
    disabled: Boolean,
    mode: {
      type: String,
      default: 'main',
    },
    loading: Boolean,
    type: { type: String, default: 'button' },
  },
  computed: {
    is_disabled() {
      return this.disabled || this.loading
    },
    class_list() {
      let classList = ''

      if (this.mode === 'main') {
        classList +=
          ' mt-8 min-w-[260px] h-[44px] font-lato text-white text-sm flex justify-center items-center rounded-full'
      } else if (this.mode === 'outline') {
        classList +=
          ' mt-4 min-w-[260px] h-[44px] font-lato text-amber text-sm flex justify-center items-center border border-amber rounded-full'
      } else if (this.mode === 'icon') {
        classList +=
          ' min-w-[30px] min-h-[30px] w-[30px] h-[30px] flex justify-center items-center rounded-full bg-my-page font-bold text-amber'
      } else if (this.mode === 'text') {
        classList += ' my-8 font-lato text-amber text-xs'
      }

      if (this.mode !== 'text' && this.mode !== 'outline' && this.mode !== 'icon') {
        if (this.disabled) classList += ' bg-zinc-300'
        else classList += ' bg-amber'
      } else {
        if (this.disabled) classList += ' text-zinc-300'
        else classList += ' text-amber'
      }

      return classList
    },
  },
}
</script>
