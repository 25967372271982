<template>
  <MainLayout :title="$t('Guidance')" :loading="loading" backRoute="confirmReservationsList">
    <Form
      v-slot="{ meta }"
      @submit="onSubmit"
      class="w-full flex flex-col justify-start items-center"
    >
      <CustomCheckinForm v-model="customForm"/>
      <Button :disabled="!meta.valid" class="mt-10 mb-6" type="submit">
        {{ $t('Next') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'

import MainLayout from '@/components/layouts/MainLayout'
import CustomCheckinForm from '@/components/blocks/CustomCheckinForm'
import { REDIRECTS_PAGES } from '@/data/pagesList'

export default {
  name: 'CheckinCustomFormView',
  components: {
    Form,
    MainLayout,
    CustomCheckinForm,
  },
  data() {
    return {
      loading: true,
      customForm: [],
      paymentSetting: null,
    }
  },
  computed: {
    ...mapGetters(['customCheckinFields','hotelGuideData', 'checkinData', 'hasPaymentPlugin']),
  },
  async mounted() {
    try {
      this.loading = true

      this.paymentSetting = await this.getPaymentSetting()

    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions([
      'setCustomCheckinData',
      'getPaymentSetting',
      'globalErrorHandler',
      'getCheckinPayments'
    ]),
    async onSubmit() {
      this.setCustomCheckinData(this.customForm)

      const paymentMethodValues = []
      const selfCheckinPaymentSetting = this.paymentSetting?.payment_time?.find(
        item => item.value === 'CHECKIN'
      )

      if (selfCheckinPaymentSetting && selfCheckinPaymentSetting.is_enabled) {
        for (let index = 0; index < selfCheckinPaymentSetting.payment_method.length; index++) {
          const paymentMethod = selfCheckinPaymentSetting.payment_method[index]
          if (paymentMethod.is_enabled) {
            if (paymentMethod.value === 'DIRECT') {
              paymentMethodValues.push('cash')
            } else if (paymentMethod.value === 'CREDIT_CARD') {
              paymentMethodValues.push('creditCard')
            }
          }
        }
      }

      let payments
      if (this.checkinData?.reservationIds) {
        payments = await this.getCheckinPayments({
            reservationIds: this.checkinData.reservationIds,
        })
      }

      if (this.hasPaymentPlugin && payments?.totalAmount > 0 && paymentMethodValues.length) {
        this.$router.push({ name: REDIRECTS_PAGES.CHECKIN_PAYMENT })
      } else {
        this.$router.push({ name: REDIRECTS_PAGES.CHECKIN_CONFIRM })
      }
    },
  },
}
</script>
