import { parse as parseDateFns, isAfter, isBefore, isSameDay, format } from 'date-fns'
import jaLocale from 'date-fns/locale/ja'

export const dateIntervalCrossesIntervals = (checkInterval, intervals) => {
  let hit = false

  for (const interval of intervals) {
    if (
      (isBefore(checkInterval.start, interval.end) || isSameDay(checkInterval.start, interval.end)) &&
      (isAfter(checkInterval.end, interval.start) || isSameDay(checkInterval.end, interval.start))
    ) {
      hit = true
    }
  }

  return hit
}

export const parse = dateString => {
  const regex = /(\d{2})\.(\d{2})\.(\d{4})(.*)/

  if (!regex.test(dateString)) return parseDateFns(dateString)

  const dateArr = dateString.match(/(\d{2})\.(\d{2})\.(\d{4})(.*)/)

  return parseDateFns(`${dateArr[3]}/${dateArr[2]}/${dateArr[1]}${dateArr[4]}`)
}

export default function formatDate(date, template, locale) {
  date = parse(date)
  let options = {}
  if (locale == 'ja' || locale == 'ja-jp') {
    options.locale = jaLocale
  }
  return format(date, template ? template : 'MMM Do, YYYY', options)
}
