<template>
  <MainLayout :title="$t('Check-in')" class="py-0" backRoute="home">
    <div class="w-full bg-[#185AA5] px-8 pt-8 pb-24 flex justify-center align-start text-center">
      <p class="text-white text-xl font-bold">{{ hotel_name }}</p>
    </div>
    <Form v-slot="{ meta }" @submit="onSubmit" class="w-full p-6">
      <div
        class="max-w-[600px] -mt-24 flex flex-col items-center w-full p-6 sm:p-8 bg-white justify-start mx-auto border border-slate-300 rounded-xl"
      >
        <SelectField
          v-model="formData.checkinDate"
          :rules="fieldValidators.required()"
          :label="$t('Arrival Date')"
          :options="dates_list"
          :emptyItemTitle="$t('Please select')"
          name="checkinDate"
        />
        <SelectField
          v-model="formData.checkinTime"
          :rules="fieldValidators.required()"
          :label="$t('Check-in Time')"
          :options="time_list"
          name="checkinTime"
        />
        <SelectField
          v-model="formData.guests"
          :rules="fieldValidators.required()"
          :label="$t('Number of guests')"
          :options="guests_list"
          name="guests"
        />
        <CheckField
          v-if="formData.guests > 1"
          v-model="formData.knowContacts"
          :label="$t('I know everyone’s contacts')"
          name="knowContacts"
        />
        <div
          v-if="formData.companions.length && !formData.knowContacts"
          class="w-full flex flex-col mb-6"
        >
          <div
            v-for="(item, index) of formData.companions"
            :key="`companions[${index}].name`"
            class="flex -mx-2"
          >
            <template v-if="$i18next.isJapanese()">
              <TextField
                v-model="item.surname"
                :placeholder="$t('Last name')"
                :rules="fieldValidators.required()"
                :name="`companions[${index}].surname`"
                class="min-w-xs mx-2"
              />
              <TextField
                v-model="item.name"
                :placeholder="$t('First name')"
                :rules="fieldValidators.required()"
                :name="`companions[${index}].name`"
                class="min-w-xs mx-2"
              />
            </template>
            <template v-else>
              <TextField
                v-model="item.name"
                :placeholder="$t('First name')"
                :rules="fieldValidators.required()"
                :name="`companions[${index}].name`"
                class="min-w-xs mx-2"
              />
              <TextField
                v-model="item.surname"
                :placeholder="$t('Last name')"
                :rules="fieldValidators.required()"
                :name="`companions[${index}].surname`"
                class="min-w-xs mx-2"
              />
            </template>

            <Button @click.prevent="handleDelete(index)" class="mt-2 order-3" mode="icon">
              &#10005;
            </Button>
          </div>
          <Button
            @click.prevent="handleAddGuest()"
            mode="text"
            class="my-2 font-lato text-amber text-xs"
          >
            &#43; {{ $t('Add entry field') }}
          </Button>
        </div>
        <TextField
          v-model="formData.telephone"
          :rules="combineFieldValidators([fieldValidators.required(), fieldValidators.phone()])"
          :label="$t('Phone number of your reservation')"
          placeholder="08012345678"
          name="telephone"
        />
      </div>
      <Button :disabled="!meta.valid" :loading="loadingAction" class="mb-2 mx-auto" type="submit">
        {{ $t('Check-in') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'
import moment from 'moment'

import MainLayout from '@/components/layouts/MainLayout'
import formValidatorsMixin from '@/mixins/formValidatorsMixin'
import { getHoursTimeRange } from '@/utils/time'
import { REDIRECTS_PAGES } from '@/data/pagesList'

export default {
  name: 'CheckinView',
  mixins: [formValidatorsMixin],
  components: {
    Form,
    MainLayout,
  },
  data() {
    return {
      loadingAction: false,
      isDisabled: false,
      formData: {
        telephone: '',
        checkinDate: '',
        checkinTime: '',
        guests: '',
        knowContacts: false,
        companions: [],
      },
    }
  },
  watch: {
    'formData.guests': {
      immediate: true,
      handler(data) {
        if (data > 1) {
          for (let i = 0; i < data; i++) {
            this.formData.companions.push({ name: '', surname: '' })
          }
          this.formData.companions.length = data - 1
        } else {
          this.formData.companions = []
        }
      },
    },
  },
  mounted() {
    this.formData.checkinTime = this.time_list[0]
    this.formData.guests = this.guests_list[0].value
  },
  computed: {
    ...mapGetters(['hotelGuideData', 'userData', 'customCheckinFields']),
    hotel_info() {
      return this.hotelGuideData?.facilityBasicInfo || {}
    },
    hotel_name() {
      return this.$i18next.isJapanese() ? this.hotel_info.hotelName : this.hotel_info.hotelNameLatin
    },
    dates_list() {
      const data = []
      for (let i = 0; i < 15; i++) {
        data.push({
          value: moment().add(i, 'days').format('YYYY/MM/DD'),
          label: moment().add(i, 'days').format('YYYY/MM/DD'),
        })
      }
      return data
    },
    time_list() {
      return getHoursTimeRange(this.hotel_info.checkinBeginTime, this.hotel_info.checkinEndTime)
    },
    guests_list() {
      let res = []
      Array.from({ length: 20 }, (i, index) => {
        res.push({
          value: (index + 1).toString(),
          label: (index + 1).toString(),
        })
      })
      return res
    },
    has_plugins() {
      const hasPaymentPlugin = this.hotelGuideData?.plugins.find(
        element => element.code === 'PA'
      )?.isEnabled
      const hasReservationPlugin = this.hotelGuideData?.plugins.filter(
        element =>
          element.code === 'TL' ||
          element.code === 'TM' ||
          element.code === 'NP' ||
          element.code === 'CN' ||
          element.code === 'RK'
      )
      return !!hasPaymentPlugin || !!hasReservationPlugin.length
    },
    has_custom_checkin_plugin() {
      return !!this.hotelGuideData?.plugins.find(
        element => element.code === 'CC'
      )?.isEnabled
    },
  },
  methods: {
    ...mapActions(['searchCheckin', 'setCheckinData', 'globalErrorHandler', 'deleteCheckinData', 'getCustomCheckinFields']),
    async onSubmit() {
      try {
        this.loadingAction = true

        this.deleteCheckinData()
        this.setCheckinData({
          inDate: this.formData.checkinDate,
          inTime: this.formData.checkinTime,
          totalMembers: this.formData.guests,
          companionsList: this.formData.companions,
          reservationTel: this.formData.telephone,
          previousAccommodation: 'Home',
          nextAccommodation: 'Home',
        })

        let isGuidanceDisplay = 0
        if (this.has_custom_checkin_plugin) {
          await this.getCustomCheckinFields()
          if (this.has_custom_checkin_plugin && this.customCheckinFields && Object.values(this.customCheckinFields)?.length) {
            isGuidanceDisplay = 1
          }
        }
        sessionStorage.setItem('isGuidanceDisplay', isGuidanceDisplay)
        const nextPage = isGuidanceDisplay ? REDIRECTS_PAGES.CHECKIN_CUSTOM_FORM : REDIRECTS_PAGES.CHECKIN_CONFIRM

        if (this.has_plugins) {
          const data = {
            lang: this.$i18next.language,
            reservationTel: this.formData.telephone,
          }
          await this.searchCheckin(data)
          this.$router.push({ name: 'confirmReservationsList' })
        } else {
          this.$router.push({ name: nextPage })
        }
      } catch (error) {
        if (error.response?.status === 404) {
          this.globalErrorHandler({
            error: this.$t('There is no reservation information. Check it again please.'),
          })
        } else this.globalErrorHandler({ error })
        this.$router.push({ name: 'reservationSearch' })
      } finally {
        this.loadingAction = false
      }
    },
    handleAddGuest() {
      this.formData.guests = Number(this.formData.guests) + 1
    },
    handleDelete(index) {
      this.formData.companions = this.formData.companions.filter((item, idx) => idx !== index)
      this.formData.guests = this.formData.guests - 1
    },
  },
}
</script>
