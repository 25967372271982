<template>
  <MainLayout :title="title" :loading="loading" style="font-family: Noto Sans JP, Lato, sans-serif">
    <div v-if="aroundSpot" class="max-w-xl w-full mx-auto">
      <div class="mb-2">
        <img :src="getGoogleApiPhoto(aroundSpot.photoReference)" />
        <div class="shadow-sm p-6 bg-white">
          <div class="flex justify-between">
            <h3 class="text-xl">
              {{ aroundSpot.facilityName }}
              <div class="text-sm text-gray-400">{{ capitalize($t(aroundSpot.typeName)) }}</div>
            </h3>
            <img
              v-if="aroundSpot.category === 1"
              :src="require('@/assets/images/navbar/icon_eat.svg')"
              class="w-6"
            />
            <img
              v-if="aroundSpot.category === 2"
              :src="require('@/assets/images/navbar/icon_activity.svg')"
              class="w-6"
            />
            <img
              v-if="aroundSpot.category === 3"
              :src="require('@/assets/images/navbar/icon_buy.svg')"
              class="w-6"
            />
          </div>
          <ul class="flex justify-center mt-4">
            <li class="mx-4">
              <a
                :href="`https://www.google.com/maps/dir/?api=1&destination=${aroundSpot.lat},${aroundSpot.lng}`"
                target="_route"
              >
                <div class="bg-gray-200 rounded-full w-12 h-12 flex items-center justify-center">
                  <img :src="require('@/assets/images/navbar/icon_root.svg')" />
                </div>
                <div class="mt-1 text-center">{{ capitalize($t('route')) }}</div>
              </a>
            </li>
            <li class="mx-4">
              <a :href="`tel:${aroundSpot.telephone}`">
                <div class="bg-gray-200 rounded-full w-12 h-12 flex items-center justify-center">
                  <img :src="require('@/assets/images/navbar/icon_call.svg')" />
                </div>
                <div class="mt-1 text-center">{{ capitalize($t('phone')) }}</div>
              </a>
            </li>
            <li class="mx-4">
              <a :href="aroundSpot.website" target="_site" class="flex flex-col items-center">
                <div class="bg-gray-200 rounded-full w-12 h-12 flex items-center justify-center">
                  <img :src="require('@/assets/images/navbar/icon_site.svg')" />
                </div>
                <div class="mt-1 text-center">{{ capitalize($t('website')) }}</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <ul class="shadow-sm px-6 py-4 bg-white my-2">
        <li class="flex items-center my-4">
          <img :src="require('@/assets/images/navbar/icon_map.svg')" class="mr-4" />
          <p>{{ aroundSpot.address }}</p>
        </li>
        <li class="my-4">
          <a :href="`tel:${aroundSpot.telephone}`" class="flex items-center">
            <img :src="require('@/assets/images/navbar/icon_call.svg')" class="mr-4" />
            <p>{{ aroundSpot.telephone }}</p>
          </a>
        </li>

        <li class="my-4">
          <a :href="aroundSpot.website" target="_website" class="flex items-center">
            <img :src="require('@/assets/images/navbar/icon_site.svg')" class="mr-4" />
            <span class="truncate">{{ aroundSpot.website }}</span>
          </a>
        </li>
        <div v-if="business_hours_list?.length">
          <li
            @click="onClickBusinessHours"
            :class="{ 'cursor-pointer': today_business_hours }"
            class="flex items-center"
          >
            <img
              :src="require('@/assets/images/navbar/icon_time.svg')"
              class="w-8 h-8 mr-3 -ml-1"
            />
            <p>
              {{ capitalize($t('open for business')) }}:
              <span v-if="today_business_hours" class="font-bold">
                {{ today_business_hours }}
              </span>
            </p>
            <div
              v-if="today_business_hours"
              :class="isOpenedBusinessHours ? 'rotate-0' : '-rotate-90'"
              class="transition-all mr-4"
            >
              <img :src="require('@/assets/images/navbar/arrow_down.svg')" />
            </div>
          </li>
          <ul v-if="isOpenedBusinessHours || !today_business_hours" class="mt-4">
            <li v-for="businessDay of business_hours_list" :key="businessDay.day">
              {{ capitalize($t(businessDay.day)) }}:
              <span v-if="!businessDay.startTime || !businessDay.endTime" class="font-bold">
                {{ capitalize($t('regular holiday')) }}
              </span>
              <span v-else class="font-bold">
                {{ businessDay.startTime }}~{{ businessDay.endTime }}
              </span>
            </li>
          </ul>
        </div>
      </ul>

      <div class="shadow-sm px-6 py-4 bg-white my-2">
        <div class="flex items-center">
          <img
            :src="require('@/assets/images/travelGuide/hotel-Recommendation.jpg')"
            class="w-16 h-16 rounded-full mr-4"
          />
          <h3 class="text-lg">{{ capitalize($t('hotel recommendations')) }}</h3>
        </div>
        <p class="mt-4">{{ aroundSpot.comment || capitalize($t('no data')) }}</p>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import MainLayout from '@/components/layouts/MainLayout'
import { getGoogleApiPhoto } from '@/utils/image'
import { capitalize } from '@/utils/text'

export default {
  name: 'TravelGuideDetail',
  components: {
    MainLayout,
  },
  data() {
    return {
      loading: false,
      aroundSpot: undefined,
      isOpenedBusinessHours: false,
      businessHours: [],
    }
  },
  computed: {
    ...mapGetters(['hotelGuideData']),
    title() {
      return this.aroundSpot?.facilityName || this.$t('Travel guide')
    },
    business_hours_list() {
      const businessHoursList = this.businessHours?.filter(item => !!item.day) || []

      if (
        this.$i18next.isJapanese() &&
        businessHoursList.length &&
        businessHoursList[0]?.day === 'monday' &&
        businessHoursList[businessHoursList.length - 1]?.day === 'sunday'
      ) {
        businessHoursList.unshift(businessHoursList.pop())
      }

      return businessHoursList
    },
    today_business_hours() {
      const dayName = moment('2023-02-07').lang('en').format('dddd')

      return this.businessHours
        .filter(
          item =>
            item.day && String(item.day).toLocaleLowerCase() === String(dayName).toLocaleLowerCase()
        )
        .map(item => `${item.startTime}~${item.endTime}`)
        .join(', ')
    },
  },
  mounted() {
    this.getAroundSpot()
  },
  watch: {
    '$route.query.placeId'() {
      this.getAroundSpot()
    },
    '$route.query.hotelId'() {
      this.getAroundSpot()
    },
    '$i18next.language'() {
      this.getAroundSpot()
    },
  },
  methods: {
    capitalize,
    getGoogleApiPhoto,
    ...mapActions(['getAroundSpotDetail', 'globalErrorHandler']),
    async getAroundSpot() {
      try {
        this.loading = true

        const response = await this.getAroundSpotDetail({
          placeId: this.$route.query.placeId,
          hotelId: this.hotelGuideData.hotelId,
          lang: this.$i18next.language,
        })
        this.aroundSpot = response.aroundSpots
        this.businessHours = response.aroundSpotBusinessHour || []
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
    onClickBusinessHours() {
      this.isOpenedBusinessHours = !this.isOpenedBusinessHours
    },
  },
}
</script>
