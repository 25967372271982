export const getHoursTimeRange = (checkinBeginTime, checkinEndTime) => {
  if (!checkinBeginTime || !checkinEndTime) return []

  const checkinBeginHour = checkinBeginTime.split(':')[0]
  const checkinEndHour = checkinEndTime.split(':')[0]

  if (checkinBeginHour < checkinEndHour) {
    return [...Array(checkinEndHour - checkinBeginHour + 1)].map(
      (_, i) => `${String(+checkinBeginHour + i).padStart(2, '0')}:00`
    )
  } else {
    return [
      ...[...Array(24 - checkinBeginHour)].map((_, i) => `${String(+checkinBeginHour + i).padStart(2, '0')}:00`),
      ...[...Array(+checkinEndHour + (checkinEndHour !== checkinBeginHour ? 1 : 0))].map((_, i) => {
        return `${String(i).padStart(2, '0')}:00`
      }),
    ]
  }
}
