<template>
  <img
    v-if="logo"
    class="w-[80px] h-[80px] mx-auto mt-[-80px] rounded-full shadow-md"
    :src="logo"
    alt="hotel logo"
  />
  <div v-else class="w-[80px] h-[80px] mx-auto mt-[-80px] rounded-full bg-white shadow-md"></div>
  <p class="text-center mt-7 font-lato font-bold text-lg">{{ title }}</p>
</template>

<script>
export default {
  name: 'MainTitleBlock',
  props: {
    logo: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
