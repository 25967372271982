<template>
  <MainLayout :title="$t('Check-in Information')" :loading="loading" backRoute="myPage">
    <div
      v-if="!userCheckinHistoryList?.length"
      class="w-full max-w-[600px] p-8 bg-white flex flex-col justify-center items-center"
    >
      <p>{{ $t('No check-in information') }}</p>
    </div>
    <template v-if="userCheckinHistoryList?.length">
      <div
        v-for="(item, index) of userCheckinHistoryList"
        :key="index"
        class="w-full max-w-[600px] px-6 my-2 py-4 bg-white flex justify-between items-center cursor-pointer"
        @click="handlePushToItem(item)"
      >
        <div class="font-lato">
          <p class="font-semibold">{{ $i18next.isJapanese() ? item.hotelName : item.hotelNameLatin }}</p>
          <p class="text-xs mt-1">{{ setTimeFormat(item.checkinTime) }}</p>
        </div>
        <img :src="arrowRightImg" />
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import MainLayout from '@/components/layouts/MainLayout'
import arrowRight from '@/assets/images/controls/arrow_right.svg'

export default {
  name: 'CheckInListView',
  components: {
    MainLayout,
  },
  data() {
    return {
      arrowRightImg: arrowRight,
      loading: true,
      userCheckinHistoryList: null,
    }
  },
  computed: {
    ...mapGetters(['userData', 'hotelGuideData']),
  },
  async mounted() {
    try {
      this.loading = true

      this.userCheckinHistoryList = await this.getUserCheckinHistoryList({
        userId: this.userData.id,
      })
    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions(['getUserCheckinHistoryList', 'globalErrorHandler']),
    setTimeFormat(time) {
      return moment(time).format('YYYY/MM/DD HH:mm')
    },
    handlePushToItem(item) {
      this.$router.push({
        name: 'checkinInfoView',
        params: {
          id: item.checkinId,
        },
        query: { checkinCode: item.checkinCode },
      })
    },
  },
}
</script>
