import { WebAuth } from 'auth0-js'

import i18next from '@/i18n'
import api from '@/api'

const _config = () => ({
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientID: process.env.VUE_APP_AUTH_CLIENT_ID,
  dbName: process.env.VUE_APP_AUTH_DBNAME,
  responseType: 'token id_token',
  redirectUri: `${window.location.origin}/`,
})

const _signoutConfig = (hotelId, lang) => ({
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientID: process.env.VUE_APP_AUTH_CLIENT_ID,
  dbName: process.env.VUE_APP_AUTH_DBNAME,
  responseType: 'token id_token',
  redirectUri: `${window.location.origin}/?hotelId=${hotelId}&lang=${lang}`,
  state: `hotelId=${hotelId}`,
})

const loginRedirectStorageKey = 'login-redirect'

const state = {
  hotelId: null,
}

const getters = {
  loginRedirect: () => {
    const loginRedirect = JSON.parse(sessionStorage.getItem(loginRedirectStorageKey))
    const storedNextLogin = loginRedirect?.redirectTo || loginRedirect?.redirectedFrom
    if (storedNextLogin?.name === 'login') {
      return { name: 'myPage' }
    }
    return storedNextLogin
  },
  loginFrom: () => {
    const loginRedirect = JSON.parse(sessionStorage.getItem(loginRedirectStorageKey))
    return loginRedirect?.redirectedFrom
  },
}

const mutations = {
  REMOVE_ACCESS_TOKEN(state) {
    state.accessToken = null
    localStorage?.removeItem('access_token')
  },
  SET_LOGIN_REDIRECT(state, data) {
    sessionStorage.setItem(loginRedirectStorageKey, JSON.stringify(data))
  },
  REMOVE_LOGIN_REDIRECT() {
    sessionStorage.removeItem(loginRedirectStorageKey)
  },
}

const actions = {
  async checkEmail(context, email) {
    return await api.post(`/auth/check-email`, { email }).then(response => response.data?.status)
  },
  async signIn(context, user) {
    return await api
      .post(`/auth/login`, user)
      .then(response => {
        localStorage?.setItem('access_token', response.data?.token)
        return response
      })
      .catch(error => {
        if (error?.code === 'access_denied' || error?.response?.status === 400) {
          throw i18next.t('Wrong email or password.')
        }
        console.error(error)

        throw i18next.t('unknown error')
      })
  },
  async signInSocial({ commit }) {
    return await api.post(`/auth/auth0-callback`).then(response => {
      localStorage?.setItem('access_token', response.data?.token)
      commit('SET_USER_DATA', response.data?.user)
      return response.data?.user
    })
  },
  async signUp(context, user) {
    const result = await api.post('/auth/register', user).then(response => response.data)
    localStorage?.setItem('access_token', result?.token)

    return result
  },
  parseToken() {
    return new Promise((resolve, reject) => {
      const { domain, clientID } = _config()
      const webAuth = new WebAuth({ domain, clientID })
      webAuth.parseHash((error, result) => {
        if (result) {
          localStorage?.setItem('access_token', result.idToken)
          resolve(result)
        } else {
          reject(error)
        }
      })
    })
  },
  socialSignup(_, name) {
    new Promise((resolve, reject) => {
      const { domain, clientID, responseType, redirectUri } = _config()
      const webAuth = new WebAuth({ domain, clientID, responseType, redirectUri })
      webAuth.authorize({ connection: name }, (error, result) => {
        if (error) return reject(error)
        return resolve(result)
      })
    })
  },
  logout({ commit, getters }) {
    return new Promise(() => {
      const hotelId = getters['hotelGuideData'].hotelId
      const lang = localStorage?.getItem('app_lang')

      const { domain, clientID, redirectUri } = _signoutConfig(hotelId, lang)
      const webAuth = new WebAuth({
        domain,
        clientID,
        state: `hotelId=${hotelId}`,
      })

      api.post('/auth/logout').then(() => {
        commit('REMOVE_ACCESS_TOKEN')
        webAuth.logout({ returnTo: redirectUri })
      })
    })
  },
  forgotPassword(_, data) {
    return api.post(`/auth/forgot-password`, data)
  },
  changePasswordConfirm(_, data) {
    return api.post(`/auth/reset-password`, data)
  },
  storeLoginRedirect({ commit }, { redirectedFrom, redirectTo }) {
    if (!redirectedFrom && !redirectTo) {
      commit('REMOVE_LOGIN_REDIRECT')
      return
    }

    commit('SET_LOGIN_REDIRECT', {
      redirectedFrom: redirectedFrom
        ? {
            name: redirectedFrom.name,
            params: redirectedFrom.params,
            query: redirectedFrom.query,
          }
        : undefined,
      redirectTo: redirectTo
        ? {
            name: redirectTo.name,
            params: redirectTo.params,
            query: redirectTo.query,
          }
        : undefined,
    })
  },
  clearLoginRedirect({ commit }) {
    commit('REMOVE_LOGIN_REDIRECT')
  },
}
export default {
  state,
  getters,
  mutations,
  actions,
}
