<template>
  <MainLayout hideHeader class="px-0 py-0">
    <div
      v-if="approvedCheckinData"
      class="w-full h-full bg-[#185AA5] p-8 flex flex-col justify-items-center items-center"
    >
      <p class="text-center text-white font-lato font-semibold text-3xl">Welcome</p>
      <p v-if="userData?.name" class="text-center text-sm font-lato text-white">
        {{ userData.name }}
        {{ $i18next.isJapanese() ? '様' : '' }}
      </p>
      <div
        class="w-full max-w-[600px] pt-8 m-4 bg-white flex flex-col justify-start items-center rounded-lg"
      >
        <div class="flex flex-col justify-center items-center">
          <QRCodeView :value="qr_code" :size="235" />
          <div class="mb-4">
            <span class="text-sm text-slate-600 font-semibold leading-relaxed"
              >{{ $t('Check-in ID') }}:</span
            >
            <span class="text-sm text-amber font-semibold leading-relaxed ml-1">{{
              approvedCheckinData.checkinCode
            }}</span>
          </div>
        </div>
        <div class="w-full flex justify-items-center items-center mt-4">
          <div class="w-[50%] border-t-2 border-r-2">
            <RouterLinkControl componentName="home">
              <p class="p-4 font-lato text-slate-600 text-sm text-center">
                {{ $t('TOP') }}
              </p>
            </RouterLinkControl>
          </div>
          <div class="w-[50%] border-t-2">
            <RouterLinkControl componentName="myPage">
              <p class="p-4 font-lato text-slate-600 text-sm text-center">
                {{ $t('My Page') }}
              </p>
            </RouterLinkControl>
          </div>
        </div>
      </div>
      <RouterLinkControl :link="save_link" target="_blank" v-if="has_pass_id">
        <img
          :src="$i18next.isJapanese() ? SavetoGooglePayJpImg : SavetoGooglePayEnglishImg"
          alt=""
        />
      </RouterLinkControl>

      <template v-if="userData">
        <p v-if="has_kiosk_plugin" class="text-white text-sm font-bold font-lato mt-2 text-center">
          {{ $t('Please hold the QR code over the check-in machine.') }}
        </p>
        <p v-if="!has_kiosk_plugin" class="text-white text-sm font-bold font-lato mt-2 text-center">
          {{ $t('Your registration information has been sent to your email address') }}
        </p>
        <p class="text-white text-sm font-bold font-lato mt-2">{{ userData?.email }}</p>
      </template>
    </div>
    <img :src="aipassLogoImg" class="w-[110px] mx-auto mt-8 mb-4" />
    <div class="w-full flex flex-col justify-items-center items-center mb-8">
      <div class="flex justify-items-start items-center w-[240px] font-lato">
        <p class="text-amber mr-2">&#10003;</p>
        <span class="text-xs font-bold text-gray">{{ $t('Quick check-in') }}</span>
      </div>
      <div class="flex justify-items-start items-center w-[240px] font-lato">
        <p class="text-amber mr-2">&#10003;</p>
        <span class="text-xs font-bold text-gray">
          {{ $t('Area info recommended by the hotel') }}
        </span>
      </div>
      <div class="flex justify-items-start items-center w-[240px] font-lato">
        <p class="text-amber mr-2">&#10003;</p>
        <span class="text-xs font-bold text-gray">{{ $t('Pre check-in') }}</span>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { omit } from 'lodash'
import { mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import RouterLinkControl from '@/components/controls/RouterLinkControl'
import SavetoGooglePayEnglish from '@/assets/images/SavetoGooglePayEnglish.svg'
import SavetoGooglePayJp from '@/assets/images/SavetoGooglePayJp.svg'
import aipasslogo from '@/assets/images/aipasslogo.svg'
import QRCodeView from '@/components/UI/QRCodeView'

export default {
  name: 'CheckinAcceptView',
  components: {
    MainLayout,
    RouterLinkControl,
    QRCodeView,
  },
  data() {
    return {
      SavetoGooglePayEnglishImg: SavetoGooglePayEnglish,
      SavetoGooglePayJpImg: SavetoGooglePayJp,
      aipassLogoImg: aipasslogo,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['approvedCheckinData', 'userData', 'hotelGuideData']),
    qr_code() {
      const qrObject =
        typeof this.approvedCheckinData.qrJson === 'string'
          ? JSON.parse(this.approvedCheckinData.qrJson)
          : this.approvedCheckinData.qrJson

      return btoa(unescape(encodeURIComponent(JSON.stringify(omit(qrObject, ['pass_id'])))))
    },
    save_link() {
      return `${process.env.VUE_APP_PASS_PROVIDER}/passkit/${this.approvedCheckinData.qrJson?.pass_id}/download`
    },
    has_pass_id() {
      return !!this.approvedCheckinData?.qrJson?.pass_id
    },
    has_kiosk_plugin() {
      return this.hotelGuideData?.plugins?.find(element => element.code === 'KSK')?.isEnabled
    },
  },
  mounted() {
    if (!this.approvedCheckinData) {
      this.$router.push({ name: 'home' })
    }
  },
}
</script>
