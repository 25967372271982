import { getSessionStorageObject, setSessionStorageObject } from '@/utils/sessionStorage'
import api from '@/api'

const state = {
  checkinData: getSessionStorageObject('checkinData'),
  customCheckinData: getSessionStorageObject('customCheckinData'),
  covidData: getSessionStorageObject('covidData'),
  searchReservations: getSessionStorageObject('searchReservations'),
  approvedCheckinData: getSessionStorageObject('approvedCheckinData'),
  checkinPaymentData: getSessionStorageObject('checkinPaymentData'),
  customCheckinFields: undefined,
}

const getters = {
  checkinData: state => state.checkinData,
  customCheckinData: state => state.customCheckinData,
  covidData: state => state.covidData,
  approvedCheckinData: state => state.approvedCheckinData,
  searchReservations: state => state.searchReservations,
  checkinPaymentData: state => state.checkinPaymentData,
  customCheckinFields: state => state.customCheckinFields,
}

const mutations = {
  SET_RESERVATIONS_LIST(state, data) {
    state.searchReservations = data
    setSessionStorageObject('searchReservations', state.searchReservations)
  },
  SET_CHECKIN_PAYMENT_DATA(state, data) {
    state.checkinPaymentData = data
    setSessionStorageObject('checkinPaymentData', state.checkinPaymentData)
  },
  SET_CHECKIN_DATA(state, data) {
    state.checkinData = data
    setSessionStorageObject('checkinData', state.checkinData)
  },
  UPDATE_CHECKIN_DATA(state, data) {
    state.checkinData = {
      ...state.checkinData,
      ...data,
    }
    setSessionStorageObject('checkinData', state.checkinData)
  },
  SET_CUSTOM_CHECKIN_DATA(state, data) {
    state.customCheckinData = data
    setSessionStorageObject('customCheckinData', state.customCheckinData)
  },
  SET_COVID_DATA(state, data) {
    state.covidData = data
    setSessionStorageObject('covidData', state.covidData)
  },
  SET_APPROVED_CHECKIN_DATA(state, data) {
    state.approvedCheckinData = data
    setSessionStorageObject('approvedCheckinData', state.approvedCheckinData)
  },
  SET_CUSTOM_CHECKIN_FIELDS(state, data) {
    state.customCheckinFields = data
  },
  DELETE_CHECKIN_DATA(state) {
    state.checkinData = undefined
    state.searchReservations = undefined
    state.customCheckinData = undefined
    state.covidData = undefined
    state.checkinPaymentData = undefined

    sessionStorage.removeItem('checkinData')
    sessionStorage.removeItem('searchReservations')
    sessionStorage.removeItem('customCheckinData')
    sessionStorage.removeItem('covidData')
    sessionStorage.removeItem('checkinPaymentData')
  },
}

const actions = {
  getCheckinPayments({ getters }, data) {
    return api.post('search-reservation', { hotelId: getters['hotelId'], ...data }).then(res => res.data)
  },
  postUserCard({ getters }, data) {
    return api.post('payment/cuicin-user-card', { hotelId: getters['hotelId'], ...data }).then(res => res.data)
  },
  searchCheckin({ commit, getters }, data) {
    const { reservationTel, reservationId, reservationGivenName, reservationFamilyName } = data
    const paramsData = {
      hotelId: getters['hotelId'],
      reservationTel,
      reservationId,
      reservationGivenName,
      reservationFamilyName,
    }

    return api
      .get('search-reservation', { params: paramsData })
      .then(res => {
        commit('SET_RESERVATIONS_LIST', res.data?.searchReservations)
        return res.data
      })
      .catch(error => {
        commit('SET_RESERVATIONS_LIST', undefined)
        throw error
      })
  },
  setCheckin({ getters }, data) {
    return api.post('search-reservation', { hotelId: getters['hotelId'], ...data })
  },
  getCustomCheckinFields({ commit, getters }) {
    return api.get('custom-checkin/custom-checkin', { params: { hotelId: getters['hotelId'] } }).then(res => {
      commit('SET_CUSTOM_CHECKIN_FIELDS', res.data?.customCheckin)
    })
  },
  getAccommodationInfo(_, checkinId) {
    return api.get('checkin-approved', { params: { checkinId } }).then(res => res.data)
  },
  sendmailCheckinInfo(_, data) {
    return api.post('frontend-sendmail/checkin-info', data)
  },
  sendmailCheckinPaymentInfo(_, data) {
    return api.post('frontend-sendmail/checkin-payment-info', data)
  },
  postCheckoutInfo({ getters }, data) {
    return api.post('payment/checkout-info', { hotelId: getters['hotelId'], ...data })
  },
  backupReceiptPDF({ getters }, checkinIdsInfo) {
    return api.post('sales-manager/backup-receipt-pdf-without-auth', {
      hotelId: getters['hotelId'],
      checkinIdsInfo,
    })
  },
  smartCheckin({ dispatch, commit, getters }, data) {
    return api.post('smart-checkin', { hotelId: getters['hotelId'], ...data }).then(res => {
      dispatch('getUserInfo')
      commit('SET_APPROVED_CHECKIN_DATA', res.data)
    })
  },
  setCheckinData({ commit }, data) {
    commit('SET_CHECKIN_DATA', data)
  },
  updateCheckinData({ commit }, data) {
    commit('UPDATE_CHECKIN_DATA', data)
  },
  deleteCheckinData({ commit }) {
    commit('DELETE_CHECKIN_DATA')
  },
  setCustomCheckinData({ commit }, data) {
    commit('SET_CUSTOM_CHECKIN_DATA', data)
  },
  setCovidData({ commit }, data) {
    commit('SET_COVID_DATA', data)
  },
  setCheckinPaymentData({ commit }, data) {
    commit('SET_CHECKIN_PAYMENT_DATA', data)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
