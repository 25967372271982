<script>
export const OCCUPATION_EMPLOYEE = '会社員'
export const OCCUPATION_SELF_EMPLOYED = '自営業'
export const OCCUPATION_STUDENT = '学生'
export const OCCUPATION_NONE = 'なし'
export const OCCUPATION_OTHER = 'その他'

export const OCCUPATION_LIST = {
  [OCCUPATION_EMPLOYEE]: 'Employee',
  [OCCUPATION_SELF_EMPLOYED]: 'Self Employed',
  [OCCUPATION_STUDENT]: 'Student',
  [OCCUPATION_NONE]: 'No Occupation',
  [OCCUPATION_OTHER]: 'Other',
}

export default {
  methods: {
    isOtherByOccupation(occupation) {
      return ![
        OCCUPATION_EMPLOYEE,
        OCCUPATION_SELF_EMPLOYED,
        OCCUPATION_STUDENT,
        OCCUPATION_NONE,
      ].includes(occupation)
    },
    isEmployeeByOccupation(occupation) {
      return (occupation === OCCUPATION_EMPLOYEE || occupation === OCCUPATION_SELF_EMPLOYED)
    },
    convertedOccupationValueToLabel({ occupation, otherOccupation, company }) {
      const occupationLabel = OCCUPATION_LIST[occupation]
      if (this.isEmployeeByOccupation(occupation)) {
        return `${this.$t(occupationLabel)} / ${company}`
      }
      if (this.isOtherByOccupation(occupation)) {
        return otherOccupation
      }
      return this.$t(occupationLabel)
    },
  },
}
</script>
