<template>
  <div class="mt-16 w-full max-w-[1000px] px-6 py-12 font-yuGothic">
    <h2 class="text-center font-semibold text-xl">aipassにおけるプライバシーポリシー</h2>
    <p class="text-center text-xs my-6 text-slate-600 leading-relaxed">最終更新日：2022年4月15日</p>
    <p class="mb-4 text-sm leading-relaxed">
      aipass株式会社（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービスaipass（アイパス）（以下,「本サービス」といいます。）における，利用者の個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第１条（個人情報）</h3>
    <p class="text-sm leading-relaxed">
      「個人情報」とは，生存する個人に関する情報であって、以下のいずれかに該当するものをいいます。
    </p>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当該情報に含まれる氏名、住所、生年月日、性別、職業、電話番号、電子メールアドレス等により特定の個人を識別することができるもの。
      </li>
      <li class="text-xs leading-relaxed">
        当該情報に含まれる氏名、住所、生年月日、性別、職業、電話番号、電子メールアドレス等により特定の個人を識別することができるもの。
      </li>
      <li class="text-xs leading-relaxed">
        個人識別符号（個人情報の保護に関する法律（以下、「個人情報保護法」といいます。）第2条第2項）が含まれるもの。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第2条（個人情報の収集方法）</h3>
    <p class="text-sm leading-relaxed">
      当社は，利用者が利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，利用者と提携先などとの間でなされた利用者の個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。）などから収集することがあります。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第3条（個人情報を収集・利用する目的）</h3>
    <p class="text-sm leading-relaxed">当社が個人情報を収集・利用する目的は，以下のとおりです。</p>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">利用者の個人認証及び利用者向け本サービスの提供</li>
      <li class="text-xs leading-relaxed">
        本サービスに関するご意見、お問い合わせ、クチコミ投稿内容の確認・回答（本人確認行うことを含む）
      </li>
      <li class="text-xs leading-relaxed">
        本サービスの利用に伴う連絡・メールマガジン・DM・各種お知らせ等の配信・送付
      </li>
      <li class="text-xs leading-relaxed">
        利用規約に違反した利用者や，不正・不当な目的でサービスを利用しようとする利用者の調査および詳細確認
      </li>
      <li class="text-xs leading-relaxed">
        利用者にご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため
      </li>
      <li class="text-xs leading-relaxed">
        利用者の承諾・申込みに基づく、本サービス利用企業等への個人情報の提供
      </li>
      <li class="text-xs leading-relaxed">
        属性情報･端末情報・位置情報・行動履歴等に基づく広告・コンテンツ等の配信・表示、本サービスの提供
      </li>
      <li class="text-xs leading-relaxed">
        本サービスの改善・新規サービスの開発・ポイント利用状況の調査・分析およびマーケティング
      </li>
      <li class="text-xs leading-relaxed">キャンペーン・アンケート・モニター・取材等の実施</li>
      <li class="text-xs leading-relaxed">有料サービスにおいて，利用者に利用料金を請求するため</li>
      <li class="text-xs leading-relaxed">上記の利用目的に付随する目的</li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第4条（利用目的の変更）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
      </li>
      <li class="text-xs leading-relaxed">
        利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により利用者に通知し，または本ウェブサイト上に公表するものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第5条（個人情報の第三者提供）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当社は、原則として、利用者本人の同意を得ずに個人情報を第三者に提供しません。提供先・提供情報内容を特定したうえで、利用者の同意を得た場合に限り提供します。提供先および目的については、本サービス利用企業等への資料請求・応募・予約・購入等のサービス提供等のためです。
      </li>
      <li class="text-xs leading-relaxed">
        提供する個人情報の項目は、利用者から取得した情報（サービス利用履歴ほか、閲覧・検索・ブックマーク等あらゆる行動履歴に該当する情報を含む）のうち、利用目的の達成に必要な範囲の情報項目とします。提供の手段又は方法は書面もしくは電磁的な方法による送付または送信とします。
      </li>
      <li class="text-xs leading-relaxed">
        以下の場合は、関係法令に反しない範囲で、利用者の同意なく個人情報を提供することがあります。
        <ol class="list-disc mx-8">
          <li>利用者が第三者に不利益を及ぼすと判断した場合</li>
          <li>
            公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であっ
            て、利用者本人の承諾を得ることが困難である場合
          </li>
          <li>
            国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、利用者本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合
          </li>
          <li>
            裁判所、検察庁、警察またはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合
          </li>
          <li>利用者本人から明示的に第三者への開示または提供を求められた場合</li>
          <li>法令により開示または提供が許容されている場合</li>
          <li>
            合併その他の事由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合
          </li>
        </ol>
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第6条（第三者提供に関する免責事項）</h3>
    <p class="text-sm leading-relaxed">
      以下の場合は、第三者による個人情報の取得に関し、当社は何らの責任を負いません。
    </p>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        利用者自らが本サービスの機能または別の手段を用いて利用企業等に個人情報を明らかにする場合（なお、利用企業等における個人情報の取扱いについては、各利用企業に直接お問合せください）
      </li>
      <li class="text-xs leading-relaxed">
        本サービスに入力した情報により、期せずして本人が特定できてしまった場合
      </li>
      <li class="text-xs leading-relaxed">
        本サービスからリンクされる外部サイトにおいて、利用者より個人情報が提供され、またそれが利用された場合
      </li>
      <li class="text-xs leading-relaxed">
        利用者本人以外が利用者個人を識別できる情報（ID・パスワード等）を入手した場合
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第7条（個人情報処理の外部委託）</h3>
    <p class="text-sm leading-relaxed">
      当社は、個人情報取扱い業務の一部または全部を外部委託することがあります。なお、委託先における個人情報の取扱いについては当社が責任を負います。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第8条（統計処理されたデータの利用）</h3>
    <p class="text-sm leading-relaxed">
      当社は、提供を受けた個人情報をもとに、個人を特定できないよう加工した統計データ（個人情報保護法第2条第9項に定めるものを意味し、同法第2条第10項に定める匿名加工情報データベース等を構成するものに限ります。以下同じ。）を作成することがあります。個人を特定できない統計データについては、個人情報保護委員会規則で定める基準に従い、個人情報を加工し、安全管理のための措置を講じるものとします。当社において当該データを作成したときは、個人情報保護委員会規則で定めるところにより、当該匿名加工情報に含まれる個人に関する情報の項目を公表し、当社は当該データを何ら制限なく利用することができるものとします。
      個人に関する情報の項目は、性別、国籍、年代、居住する都道府県、郵便番号、宿泊予約、宿泊履歴、同行者人数、取引金額等
    </p>
    <h3 class="font-semibold mt-8 mb-2">第9条（個人情報の変更等）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        原則として利用者本人に限り、「個人情報の利用目的」の通知、登録した個人情報の開示、訂正、追加または削除、利用停止、ならびに第三者への提供の停止（以下「個人情報の変更等」といいます）を求めることができるものとします。具体的な方法については下記の個人情報管理の問い合わせ先にご連絡ください｡ただし、以下の場合は個人情報の変更等に応じないことがあります。
        <ol class="list-disc mx-8">
          <li>本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合</li>
          <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
          <li>その他法令に違反することとなる場合</li>
        </ol>
      </li>
      <li class="text-xs leading-relaxed">
        前項の定めにかかわらず、履歴情報および属性情報などの個人情報以外の情報については，原則として開示いたしません。また、当該個人情報の変更等に多額の費用を要する場合､その他の、個人情報の変更等を行うことが困難な場合であって、利用者の権利・利益を保護するため必要なこれに代わるべき措置をとるときは、個人情報の変更等に応じないことがあります。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第10条（個人情報の訂正および削除）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        利用者は，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
      </li>
      <li class="text-xs leading-relaxed">
        当社は，利用者から前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
      </li>
      <li class="text-xs leading-relaxed">
        当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これを利用者に通知します。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第11条（個人情報の利用停止等）</h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
      </li>
      <li class="text-xs leading-relaxed">
        前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく当該個人情報の利用停止等を行います。
      </li>
      <li class="text-xs leading-relaxed">
        当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これを利用者に通知します。
      </li>
      <li class="text-xs leading-relaxed">
        前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，利用者の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第12条（個人情報の正確性）</h3>
    <p class="text-sm leading-relaxed">
      当社は、ご提供いただいた個人情報を正確にデータ処理するように努めます。ただし、ご提供いただいた個人情報の内容が正確かつ最新であることについては、利用者が責任を負うものとします。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第13条（本人確認について）</h3>
    <p class="text-sm leading-relaxed">
      当社は、各Webサービスへの会員登録や会員が本サービスを利用する場合、個人情報の開示、訂正、削除もしくは利用停止の求めに応じる場合など、個人を識別できる情報（氏名､住所､電話番号､生年月日､メールアドレス､会員番号､パスワードなど）により、本人であることを確認します。ただし、本人以外が個人を識別できる情報を入手し使用した場合、当社は責任を負いません。
    </p>
    <h3 class="font-semibold mt-8 mb-2">
      第14条（属性情報・端末情報・位置情報・行動履歴等の取得及び利用について）
    </h3>
    <ol class="list-decimal px-6 mb-4">
      <li class="text-xs leading-relaxed">
        属性情報・端末情報・位置情報・行動履歴等（以下「行動履歴等」といいます）のうちCookie等について、当社は、利用者のプライバシーの保護、利便性の向上、広告の配信および統計データの取得のため、Cookieを使用します。また、CookieやJavaScript等の技術を利用して、ご提供いただいた情報のうち、年齢や性別、職業、居住地域など個人が特定できない属性情報（組み合わせることによっても個人が特定できないものに限られます）や端末情報、本サービスサイト内における利用者の行動履歴（アクセスしたURL、コンテンツ、参照順等）およびスマートフォン等利用時の、利用者の承諾・申込みに基づく位置情報を取得することがあります。ただし、Cookie及び行動履歴等には個人情報は一切含まれません。
      </li>
      <li class="text-xs leading-relaxed">
        デバイス上のCookieは、利用者自ら削除するか、または一定の有効期限が切れるまで、デバイスに残ります。ほとんどのブラウザではデフォルトで自動的にCookieの受け入れを有効にしていますが、利用者がブラウザの設定を変更することによりCookieを無効にすることが可能です。ただし、Cookieを無効にした場合、一部のウェブサイトの機能が使用できなくなったり、一部のページが正しく表示されなくなる場合があります。
      </li>
      <li class="text-xs leading-relaxed">
        行動履歴等のうち以下の場合のアクセスログ等について、当社は利用者がログインして本サービスを利用した場合には、個人を特定したうえで、当該ログイン以前からの行動履歴等を用いて、広告・コンテンツ等の配信・表示および本サービスの提供をする場合があります。こちらの広告等の配信停止については、個人情報管理の問い合わせ先にご連絡ください｡
      </li>
    </ol>
    <h3 class="font-semibold mt-8 mb-2">第15条（プライバシーポリシーの変更）</h3>
    <p class="text-sm leading-relaxed">
      本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，利用者に通知することなく，変更することができるものとします。また当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
    </p>
    <h3 class="font-semibold mt-8 mb-2">第16条（お問い合わせ窓口）</h3>
    <p class="text-sm leading-relaxed">
      本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
    </p>
    <p class="text-sm leading-relaxed mt-4">
      住所 <br />
      〒150-0031 <br />
      東京都渋谷区桜丘町23-3 篠田ビル3F
    </p>
    <p class="text-sm leading-relaxed mt-4">
      社名 <br />
      aipass株式会社
    </p>
    <p class="text-sm leading-relaxed mt-4">
      Eメールアドレス <br />
      support@aipass.inc
    </p>
  </div>
</template>

<script>
export default {
  name: 'JpPrivacyPolicyContent',
}
</script>
