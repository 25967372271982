<script>
import { mapActions, mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import TabsList from '@/components/UI/TabsList'
import TagsList from '@/components/blocks/product/TagsList'
import ProductCard from '@/components/blocks/product/ProductCard'
import ProductModal from '@/components/blocks/product/ProductModal'
import { capitalize } from '@/utils/text'

export default {
  components: { MainLayout, ProductCard, ProductModal, TabsList, TagsList },
  data() {
    return {
      loading: true,
      productsList: [],
      tagsList: [],
      categoriesList: [],
      showProduct: undefined,
      activeCategory: { id: undefined, name: this.$t('all') },
      activeTag: { id: undefined, name: this.$t('all') },
    }
  },
  computed: {
    ...mapGetters(['productsCart', 'productsCartTotalCount', 'productsCartTotalSum']),
    products_list_items() {
      return (
        this.productsList.filter(
          product =>
            product.stock &&
            (this.activeCategory?.id ? product.category?.id === this.activeCategory.id : true) &&
            (this.activeTag?.id ? product.tags.some(t => this.activeTag.id === t.id) : true)
        ) || []
      )
    },
    tags_items() {
      const categoryProducts =
        this.productsList.filter(
          product =>
            product.stock &&
            (this.activeCategory?.id ? product.category?.id === this.activeCategory.id : true)
        ) || []
      const categoryTagsList = this.tagsList.filter(
        t => categoryProducts && categoryProducts.some(p => p.tags.some(pt => pt.id === t.id))
      )

      return [
        {
          id: undefined,
          name: this.$c(this.$t('all')),
          imagePath: this.activeCategory?.imagePath || this.categoriesList?.[0]?.imagePath,
        },
        ...categoryTagsList,
      ]
    },
  },
  watch: {
    activeCategory() {
      this.activeTag = { id: undefined, name: this.$t('all') }
    },
  },
  mounted() {
    this.loadProductsList()
  },
  methods: {
    capitalize,
    ...mapActions(['getProductsList', 'globalErrorHandler', 'changeProductsCartCount']),
    async loadProductsList() {
      try {
        this.loading = true

        const res = await this.getProductsList()

        this.productsList = res.products
        this.tagsList = res.tags
        this.categoriesList = res.categories
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
    onConfirm() {
      this.$router.push({ name: 'cartProductsConfirmationView' })
    },
    onClickProduct(product) {
      if (!product.isNowOnSale) return

      this.showProduct = product
    },
  },
}
</script>

<template>
  <MainLayout
    :loading="loading"
    :title="capitalize($t('order'))"
    backRoute="home"
    class="!bg-white tracking-wider"
    bordered-header
  >
    <template v-if="productsList?.length">
      <TabsList
        :tabs="[{ id: undefined, name: $c($t('all')) }, ...categoriesList]"
        :activeTab="activeCategory"
        :get-label="i => $c($i18next.getValueByLanguage(i, 'name'))"
        :get-key="i => i.id"
        @changeTab="v => (activeCategory = v)"
        class="w-full px-4 pt-2 -mt-4 shadow-md sticky top-[56px] z-20"
      />
      <TagsList
        :tags="tags_items"
        :activeTag="activeTag"
        :get-label="i => $c($i18next.getValueByLanguage(i, 'name'))"
        :get-key="i => i.id"
        @changeTags="t => (activeTag = t)"
        class="w-full border-b border-gray-super-light px-4 pt-4 sticky top-[102px] z-10"
      />
      <div class="grid grid-cols-3 md:grid-cols-4 gap-x-3 gap-y-4 max-w-2xl p-4">
        <ProductCard
          v-for="product of products_list_items"
          @click="() => onClickProduct(product)"
          :key="product.id"
          :product="product"
          :count="productsCart[product.id]?.count"
          :class="{ 'opacity-30': !product.isNowOnSale, 'cursor-pointer': product.isNowOnSale }"
        />
      </div>
    </template>
    <div
      v-if="!products_list_items.length"
      class="bg-white p-8 mx-auto border rounded-md text-gray border-gray-light py-6 w-full max-w-[340px] text-center text-xs"
    >
      {{ $t('There is no item.') }}
    </div>
    <ProductModal
      :product="showProduct"
      :count="productsCart[showProduct?.id]?.count"
      @change="changeProductsCartCount"
      @close="showProduct = undefined"
    />
    <template v-if="productsCartTotalCount" #staticBottom>
      <div class="sticky bottom-0 bg-white z-40 w-full p-4 shadow-[0_-2px_6px_0_rgba(0,0,0,0.1)]">
        <div class="max-w-[340px] m-auto flex items-center justify-between">
          <div class="flex items-center">
            <div class="px-2 py-1 min-w-[42px] text-center bg-amber text-white rounded mr-4">
              {{ productsCartTotalCount }}
            </div>
            <div class="text-xl">¥{{ productsCartTotalSum.toLocaleString() }}</div>
          </div>
          <Button @click="onConfirm" class="w-auto px-6 mt-0 min-w-0">{{ $t('Confirm') }}</Button>
        </div>
      </div>
    </template>
  </MainLayout>
</template>
