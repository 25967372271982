<script>
import { format } from 'date-fns'
import debounce from 'lodash/debounce'

import { parse } from '@/utils/date.js'

export default {
  props: {
    show: Boolean,
    modelValue: { type: [Date, String], required: false },
    outputDateFormat: { type: String, default: 'YYYY-MM-DD' },
    firstYear: Number,
    lastYear: Number,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: 1,
      currentDay: 1,
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        if (!this.modelValue) return

        let v = this.modelValue

        if (typeof this.modelValue === 'string') {
          v = new Date(this.modelValue)
        }

        this.currentYear = v.getFullYear()
        this.currentMonth = v.getMonth() + 1
        this.currentDay = v.getDate()

        this.scrollToYear()
        this.scrollToMonth()
        this.scrollToDay()
      },
    },
    currentYear() {
      if (!this.$refs['yearDateList']) return

      this.scrollToYear()
      this.emitChangeByDateList()
    },
    currentMonth() {
      if (!this.$refs['monthDateList']) return

      this.scrollToMonth()
      this.emitChangeByDateList()
    },
    currentDay() {
      if (!this.$refs['dayDateList']) return

      this.scrollToDay()
      this.emitChangeByDateList()
    },
  },
  computed: {
    years() {
      const firstYear = this.firstYear ?? new Date().getFullYear()
      const lastYear = this.lastYear ?? 1900

      return Array.from(
        { length: firstYear - lastYear + 1 },
        (_, index) => lastYear + index
      ).reverse()
    },
    months() {
      return Array.from({ length: 12 }, (_, index) => index + 1)
    },
    days() {
      const lastDay = new Date(this.currentYear, this.currentMonth, 0).getDate()
      return Array.from({ length: lastDay }, (_, index) => index + 1)
    },
  },
  methods: {
    onDateScroll: debounce(function () {
      const yearIndex = Math.round(this.$refs['yearDateList'].scrollTop / 32)
      const monthIndex = Math.round(this.$refs['monthDateList'].scrollTop / 32)
      const dayIndex = Math.round(this.$refs['dayDateList'].scrollTop / 32)

      this.currentYear = this.years[yearIndex]
      this.currentMonth = this.months[monthIndex]
      this.currentDay = this.days[dayIndex]
    }, 300),
    scrollToYear() {
      this.$nextTick(() => {
        const yearIndex = this.years.indexOf(Number(this.currentYear))

        this.$refs['yearDateList'].scrollTo(0, yearIndex * 32)
      })
    },
    scrollToMonth() {
      this.$nextTick(() => {
        const monthIndex = this.months.indexOf(Number(this.currentMonth))

        this.$refs['monthDateList'].scrollTo(0, monthIndex * 32)
      })
    },
    scrollToDay() {
      this.$nextTick(() => {
        const dayIndex = this.days.indexOf(Number(this.currentDay))

        this.$refs['dayDateList'].scrollTo(0, dayIndex * 32)
      })
    },
    selectDateYear(e) {
      this.$refs['yearDateList'].scrollTo(0, e.target.offsetTop - 32 * 4)
    },
    selectDateMonth(e) {
      this.$refs['monthDateList'].scrollTo(0, e.target.offsetTop - 32 * 4)
    },
    selectDateDay(e) {
      this.$refs['dayDateList'].scrollTo(0, e.target.offsetTop - 32 * 4)
    },
    emitChangeByDateList() {
      if (this.currentYear && this.currentMonth && this.currentDay) {
        const value = format(
          parse(
            `${this.currentYear}-${('0' + this.currentMonth).slice(-2)}-${(
              '0' + this.currentDay
            ).slice(-2)}`
          ),
          this.outputDateFormat
        )

        this.$emit('change', value)
        this.$emit('update:modelValue', value)
      }
    },
  },
}
</script>

<template>
  <div
    :class="{ 'opacity-0': !show, 'opacity-100': show, 'pointer-events-none': !show }"
    class="flex justify-between w-64 h-72 overflow-hidden bg-white shadow-md absolute z-10 transition duration-300"
  >
    <div class="h-8 border-y top-[calc(50%-16px)] w-full absolute" />
    <ul
      ref="yearDateList"
      class="overflow-y-auto flex-1 py-[50%] scroll-smooth"
      @scroll="onDateScroll"
    >
      <li
        v-for="yearItem of years"
        :key="yearItem"
        :class="{
          'text-amber font-medium': currentYear === yearItem,
          'text-gray': currentYear !== yearItem,
        }"
        class="cursor-pointer px-4 py-1 w-full text-center hover:bg-gray-50"
        @click="selectDateYear"
      >
        {{ yearItem }}
      </li>
    </ul>
    <ul
      ref="monthDateList"
      class="overflow-y-auto flex-1 py-[50%] scroll-smooth"
      @scroll="onDateScroll"
    >
      <li
        v-for="monthItem of months"
        :class="{
          'text-amber font-medium': currentMonth === monthItem,
          'text-gray': currentMonth !== monthItem,
        }"
        :key="monthItem"
        class="cursor-pointer px-4 py-1 w-full text-center hover:bg-gray-50"
        @click="selectDateMonth"
      >
        {{ monthItem }}
      </li>
    </ul>
    <ul
      ref="dayDateList"
      class="overflow-y-auto flex-1 py-[50%] scroll-smooth"
      @scroll="onDateScroll"
    >
      <li
        v-for="dayItem of days"
        :key="dayItem"
        :class="{
          'text-amber font-medium': currentDay === dayItem,
          'text-gray': currentDay !== dayItem,
        }"
        class="cursor-pointer px-4 py-1 w-full text-center hover:bg-gray-50"
        @click="selectDateDay"
      >
        {{ dayItem }}
      </li>
    </ul>
  </div>
</template>
