<template>
  <MainLayout
    :title="$t('Invoice')"
    :loading="loading"
    backRoute="invoice"
    class="flex flex-column items-center"
  >
    <div class="w-full max-w-[600px] my-2 bg-white shadow-md text-lato">
      <div class="px-8 py-6">
        <!-- invoice info -->
        <div class="w-full flex justify-between items-center mb-4">
          <div class="text-small font-semibold">
            {{ aroundSpot.pdfInfo?.name }}
          </div>
          <div class="text-xs text-gray flex flex-wrap flex-col justify-center items-end">
            <div class="flex flex-wrap justify-end items-end">
              <span>{{ $t('Date of issue') }}:</span>
              <span class="ml-1">{{ aroundSpot.pdfInfo?.date }}</span>
            </div>
            <div>No {{ aroundSpot.pdfInfo?.receiptNumber }}</div>
          </div>
        </div>
        <div class="w-full flex justify-start items-start text-xs text-gray mb-2">
          <div class="min-w-[120px]">{{ $t('Company Information') }}:</div>
          <div class="ml-1">
            <p class="mb-1">{{ aroundSpot.pdfInfo?.hotelName }}</p>
            <p class="mb-1">{{ aroundSpot.pdfInfo?.hotelAddress }}</p>
            <p class="mb-1">{{ aroundSpot.pdfInfo?.hotelTelephone }}</p>
          </div>
        </div>
        <div class="w-full flex justify-start items-start text-xs text-gray">
          <div class="min-w-[120px]">{{ $t('Transaction Number') }}:</div>
          <div class="ml-1">{{ $route.params.id }}</div>
        </div>
      </div>
      <!-- references list -->
      <div class="w-full bg-my-page flex px-8 py-2 text-gray">
        <div class="w-[20%] text-xs">{{ $t('Date') }}</div>
        <div class="w-[50%] text-xs">{{ $t('Reference') }}</div>
        <div class="w-[30%] text-xs text-end">{{ $t('Amount used') }}</div>
      </div>
      <div
        v-for="(item, index) of aroundSpot.salesList"
        :key="index"
        class="w-full font-lato border-b-2 border-my-page flex px-8 py-3"
      >
        <div class="w-[20%] text-xs">{{ getDate(item.salesDate) }}</div>
        <div class="w-[50%] text-xs">
          {{ translateObject(item, 'salesSubjectName', $i18next.language) }}
        </div>
        <div class="w-[30%] text-xs text-end">¥{{ item.salesPrice }}</div>
      </div>
      <div class="w-full max-w-[600px] px-8 pt-6">
        <div class="flex justify-between items-center font-semibold">
          <div>
            {{ $t('Amount used') }}
          </div>
          <div>¥ {{ aroundSpot.total?.totalSalesPrice }}</div>
        </div>
      </div>
      <div class="w-full max-w-[600px] px-8 pt-4 pb-6 border-b-2 border-my-page">
        <div class="flex justify-between items-center font-semibold">
          <div>
            {{ $t('Credit') }}
          </div>
          <div>¥ {{ aroundSpot.total?.totalSalesDiscount }}</div>
        </div>
        <div class="w-full flex flex-col justify-center items-end text-xs text-gray">
          <div>({{$t('discount')}}: ¥{{ aroundSpot.total?.totalSalesDiscount }})</div>
        </div>
      </div>
      <div class="w-full max-w-[600px] px-8 pt-4 pb-6">
        <div class="flex justify-between items-center font-semibold">
          <div>
            {{ $t('Total Amount(tax included)') }}
          </div>
          <div>
            ¥ {{ aroundSpot.total?.totalSalesPrice - aroundSpot.total?.totalSalesDiscount }}
          </div>
        </div>
      </div>
    </div>
    <!-- receipt -->
    <div class="w-full max-w-[600px] my-2 bg-white shadow-md">
      <div class="px-8 py-6">
        <div class="w-full flex justify-between items-center mb-4">
          <div class="text-small font-semibold">{{ $t('Receipt') }}</div>
          <div class="text-xs text-gray flex flex-wrap flex-col justify-center items-end">
            <div class="flex flex-wrap justify-end items-end">
              <span>{{ $t('Date of issue') }}:</span>
              <span class="ml-1">{{ aroundSpot.pdfInfo?.date }}</span>
            </div>
            <div>No {{ aroundSpot.pdfInfo?.receiptNumber }}</div>
          </div>
        </div>
        <div class="w-full flex justify-start items-start text-xs text-gray mb-2">
          <div class="ml-1">
            <p class="mb-1">{{ aroundSpot.pdfInfo?.hotelName }}</p>
            <p class="mb-1">{{ aroundSpot.pdfInfo?.hotelAddress }}</p>
            <p class="mb-1">{{ aroundSpot.pdfInfo?.hotelTelephone }}</p>
          </div>
        </div>
        <div class="w-full">
          <div
            class="flex justify-between items-start pb-1 mt-4 text-sm font-semibold border-b border-black"
          >
            <div>{{ $t('Name') }}</div>
            <div>{{ aroundSpot.pdfInfo?.name }}</div>
          </div>
          <div
            class="flex justify-between items-start pb-1 mt-4 text-sm font-semibold border-b border-black"
          >
            <div>{{ $t('Total Amount') }}</div>
            <div>
              ¥ {{ aroundSpot.total?.totalSalesPrice - aroundSpot.total?.totalSalesDiscount }}
            </div>
          </div>
          <div
            class="flex justify-between items-start pb-1 mt-4 text-sm font-semibold border-b border-black"
          >
            <div>{{ $t('tax') }}</div>
            <div>¥ {{ aroundSpot.total?.totalReceipt8TaxPrice + aroundSpot.total?.totalReceipt10TaxPrice }}</div>
          </div>
          <div class="text-left text-[10px] text-gray mt-2">
            {{ $t('The above amount has been received.(tax included)') }}
          </div>
          <div
            class="flex justify-between items-start pb-1 mt-4 text-sm font-semibold border-b border-black"
          >
            <div>{{ $t('In payment of') }}</div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import MainLayout from '@/components/layouts/MainLayout'
import { translateObject } from '@/utils/text'

export default {
  name: 'InvoiceDetailsView',
  components: {
    MainLayout,
  },
  data() {
    return {
      loading: true,
      aroundSpot: [],
    }
  },
  computed: {
    ...mapGetters(['userData']),
  },
  mounted() {
    this.getInvoice()
  },
  methods: {
    translateObject,
    ...mapActions(['getInvoiceDetails', 'globalErrorHandler']),
    async getInvoice() {
      try {
        this.loading = true

        this.aroundSpot = await this.getInvoiceDetails({
          cuicinUserId: this.userData.id,
          orderId: this.$route.params.id,
        })
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
    getDate(date) {
      return moment(date).format('MM/DD')
    },
  },
}
</script>
