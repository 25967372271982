<script>
import { EMAIL_REGEX, URL_REGEX, checkPassword } from '@/utils/validation'

export default {
  data() {
    return {
      fieldValidators: {
        required: ({ isRequired = true } = { isRequired: true }) => {
          const required = v => {
            const notString = typeof v !== 'string'
            const hasAnyChar = typeof v === 'string' && v.match(/\S/g)
            return (!!v && (hasAnyChar || notString)) || this.$t('This field is required')
          }
          required.validatorName = 'required'
          return isRequired ? required : () => true
        },
        requiredBoolean: ({ isRequired = true } = { isRequired: true }) => {
          const requiredBoolean = v =>
            v === true || v === false || this.$t('This field is required')
          requiredBoolean.validatorName = 'requiredBoolean'
          return isRequired ? requiredBoolean : () => true
        },
        requiredArray: ({ isRequired = true } = { isRequired: true }) => {
          const requiredArray = v => (!!v && !!v.length) || this.$t('This field is required')
          requiredArray.validatorName = 'requiredArray'
          return isRequired ? requiredArray : () => true
        },
        email: () => v =>
          !v || EMAIL_REGEX.test(v) || this.$t('Please enter a valid email address'),
        password: () => v => !v || checkPassword(v),
        phone: () => v => !v || /^[\d]+$/.test(v) || this.$t('Please input correct phone number'),
        url: v => !v || URL_REGEX.test(v) || this.$t('Must be a valid URL'),
        minCharacters:
          ({ minCount }) =>
          v =>
            !v ||
            v.length >= minCount ||
            this.$t('The value must be greater than or equal {{count}} characters.', {
              count: minCount,
            }),
        maxCharacters:
          ({ maxCount }) =>
          v =>
            !v ||
            v.length <= maxCount ||
            this.$t('The value must be less than or equal {{count}} characters.', {
              count: maxCount,
            }),
        katakana: () => v => {
          const pattern = /^[ァ-ンヴーｧ-ﾝﾞﾟ-]+$/
          return !v || pattern.test(v) || this.$t('Please input katakana only')
        },
        squareImageMin:
          ({ min }) =>
          v =>
            (v && v.width === v.height && v.width >= min) ||
            this.$t(`Must be square and at least ${min}x${min}px`),
        imageSizeMb:
          ({ maxMb = 10 } = { maxMb: 10 }) =>
          v => {
            const files = Array.isArray(v) ? v : [v]
            return (
              !v ||
              files?.every(f => f.size <= maxMb * Math.pow(2, 20)) ||
              this.$t('File size limit {count}Mb.', { count: maxMb })
            )
          },
        imageFile: () => v => {
          const files = Array.isArray(v) ? v : [v]
          return (
            !v ||
            files?.every(f => ['image/jpeg', 'image/png', 'image/heic'].includes(f.type)) ||
            this.$t('Invalid image')
          )
        },
      },
    }
  },
  methods: {
    combineFieldValidators(validatorsArray) {
      const validator = v => {
        for (const validator of validatorsArray) {
          const result = validator(v)

          if (result !== true) return result
        }

        return true
      }

      validator.validatorsList = validatorsArray.map(v => v.validatorName)

      return validator
    },
  },
}
</script>
