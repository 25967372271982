<template>
  <MainLayout :title="$t('Sign in')" class="p-4 flex flex-col items-center">
    <p class="text-xs text-slate-600 leading-relaxed mb-4">
      {{ $t('Need your aipassID to sign in') }}
    </p>
    <LoginBlock :login-redirect="redirectTo" />
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout'
import LoginBlock from '@/components/blocks/LoginBlock'

export default {
  name: 'logIn',
  components: {
    MainLayout,
    LoginBlock,
  },
  props: {
    redirectTo: { name: String, query: Object, params: Object },
  },
}
</script>
