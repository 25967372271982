<template>
  <MainLayout
    :title="$t('Payment details')"
    :loading="loading || !payments"
    :backRoute="back_route"
  >
    <Form
      v-slot="{ meta }"
      @submit="onSubmit"
      class="w-full max-w-[600px] flex flex-col items-center"
    >
      <RoomBillDetails
        v-for="(item, index) of payments.reservations"
        :key="index"
        :reservation="item"
      />

      <div class="w-full my-2 px-8 py-6 bg-white">
        <div class="flex justify-between items-center font-semibold text-amber">
          <div>
            {{ $t('Amount billed') }}
          </div>
          <div>¥ {{ (+payments.totalAmount).toLocaleString() }}</div>
        </div>
        <div class="w-full flex justify-end items-center text-xs text-gray">
          <div>({{ $t('tax') }}：¥{{ total_tax }})</div>
        </div>
      </div>
      <PaymentBlock
        @loadCreditCard="v => (userCreditCard = v)"
        @changePaymentType="v => (selectedPaymentType = v)"
        @changeCardData="v => (cardFormData = v)"
        @receiptName="v => (receiptName = v)"
        :selected-payment-type="checkinPaymentData?.paymentMethod"
        payment-place="CHECKIN"
      />
      <Button
        :disabled="!meta.valid || !selectedPaymentType"
        :loading="loadingAction"
        class="mb-4"
        type="submit"
      >
        {{ $t('Next') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'

import RoomBillDetails from '@/components/blocks/payment/RoomBillDetails'
import MainLayout from '@/components/layouts/MainLayout'
import PaymentBlock from '@/components/blocks/PaymentBlock'
import { REDIRECTS_PAGES } from '@/data/pagesList'

export default {
  name: 'CheckinPaymentView',
  components: {
    Form,
    MainLayout,
    RoomBillDetails,
    PaymentBlock,
  },
  data() {
    return {
      REDIRECTS_PAGES,
      loading: true,
      loadingAction: false,
      payments: undefined,
      userCreditCard: undefined,
      selectedPaymentType: undefined,
      cardFormData: undefined,
      receiptName: undefined,
    }
  },
  computed: {
    ...mapGetters(['userData', 'hotelGuideData', 'checkinData', 'checkinPaymentData']),
    domain_url() {
      if (process.env.VUE_APP_APP_ENV === 'develop') return `https://develop-id.aipass.jp`
      if (process.env.VUE_APP_APP_ENV === 'integ') return `https://integ-id.aipass.jp`

      return `https://id.aipass.jp`
    },
    total_tax() {
      if (!this.payments?.totalTaxPrice) return 0
      return this.payments?.totalTaxPrice.toLocaleString()
    },
    total_price() {
      return +this.payments?.totalAmount
    },
    back_route() {
      if (this.$route.query?.isEdit) return REDIRECTS_PAGES.CHECKIN_CONFIRM
      else if (!Number(sessionStorage.getItem('isGuidanceDisplay')))
        return REDIRECTS_PAGES.CONFIRM_RESERVATIONS_LIST
      else return REDIRECTS_PAGES.CHECKIN_CUSTOM_FORM
    },
  },
  async mounted() {
    this.loadPayments()
  },
  methods: {
    ...mapActions([
      'getCheckinPayments',
      'setCheckinPaymentData',
      'addCreditCard',
      'globalErrorHandler',
    ]),
    async loadPayments() {
      try {
        this.loading = true

        this.payments = await this.getCheckinPayments({
          reservationIds: this.checkinData.reservationIds,
        })

        if (this.total_price <= 0) {
          this.$router.push({
            name: 'checkinConfirm',
            query: {
              telephone: this.$route.query.telephone,
            },
          })
        }
      } catch (error) {
        this.globalErrorHandler({ error })
        this.$router.push({ name: 'checkIn' })
      } finally {
        this.loading = false
      }
    },
    async onSubmit() {
      try {
        this.loadingAction = true

        if (
          this.selectedPaymentType === 'creditCard' &&
          !this.userCreditCard?.cardId &&
          this.cardFormData
        ) {
          const processResult = await this.addCreditCard({
            cuicinUserId: this.userData.id,
            cardNumber: this.cardFormData.cardNumber.replace(/ /g, ''),
            cardExpire: this.cardFormData.cardExpire,
            securityCode: this.cardFormData.securityCode,
          })

          if (processResult?.status !== 'success') {
            this.loadingAction = false

            return this.globalErrorHandler({
              error:
                processResult.errorMessage ||
                this.$t('Cannot register credit card. Please check it again.'),
            })
          }
        }

        const paymentData = {
          paymentMethod: this.selectedPaymentType,
          paymentAmount: this.total_price,
        }
        this.setCheckinPaymentData(paymentData)
        this.$router.push({
          name: 'checkinConfirm',
          query: {
            telephone: this.$route.query.telephone,
            receiptName: this.receiptName,
          },
        })
      } catch (error) {
        this.loadingAction = false
        this.globalErrorHandler({ error })
      }
    },
  },
}
</script>
