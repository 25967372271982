<template>
  <MainLayout
    :title="$t('Payment details')"
    backRoute="checkoutSearch"
    :loading="loading || !payments"
  >
    <Form
      v-slot="{ meta }"
      @submit="onSubmit"
      class="w-full max-w-[600px] flex flex-col items-center"
    >
      <RoomBillDetails
        v-for="(item, index) of payments.reservations"
        :key="index"
        :reservation="item"
      />

      <div class="w-full my-2 px-8 py-6 bg-white mb-4">
        <div class="flex justify-between items-center font-semibold text-amber">
          <div>
            {{ $t('Amount billed') }}
          </div>
          <div>¥ {{ total_price.toLocaleString() }}</div>
        </div>
        <div class="w-full flex justify-end items-center text-xs text-gray">
          <div>({{ $t('tax') }}：¥{{ total_tax }})</div>
        </div>
      </div>
      <PaymentBlock
        @loadCreditCard="v => (userCreditCard = v)"
        @changePaymentType="v => (selectedPaymentType = v)"
        @changeCardData="v => (cardFormData = v)"
        @receiptName="v => (receiptName = v)"
        payment-place="CHECKOUT"
      />
      <Button :disabled="!meta.valid" :loading="loadingAction" class="mb-4" type="submit">
        {{ $t(selectedPaymentType === 'creditCard' ? 'Calculate' : 'Next') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'
import moment from 'moment'

import RoomBillDetails from '@/components/blocks/payment/RoomBillDetails'
import MainLayout from '@/components/layouts/MainLayout'
import PaymentBlock from '@/components/blocks/PaymentBlock'
import { REDIRECTS_PAGES } from '@/data/pagesList'

export default {
  name: 'CheckoutPaymentView',
  components: {
    Form,
    MainLayout,
    RoomBillDetails,
    PaymentBlock,
  },
  data() {
    return {
      REDIRECTS_PAGES,
      loading: true,
      loadingAction: false,
      payments: undefined,
      userCreditCard: undefined,
      selectedPaymentType: undefined,
      cardFormData: undefined,
      receiptName: undefined,
    }
  },
  computed: {
    ...mapGetters(['userData', 'hotelGuideData', 'checkoutReservations', 'checkoutCheckins']),
    total_price() {
      return +this.payments?.totalAmount
    },
    total_tax() {
      if (!this.payments?.totalTaxPrice) return 0
      return this.payments?.totalTaxPrice.toLocaleString()
    },
  },
  async mounted() {
    this.loadPayments()
  },
  methods: {
    ...mapActions([
      'addCreditCard',
      'getReservatinsPayments',
      'getCheckinsPayments',
      'creditCardPay',
      'sendmailCheckoutPaymentInfo',
      'globalErrorHandler',
      'postCheckoutInfo',
      'getStayReservation',
      'getStayReservations',
      'backupReceiptPDF',
    ]),
    onLoadCreditCard(userCreditCard) {
      this.userCreditCard = userCreditCard
    },
    async loadPayments() {
      try {
        if (!this.checkoutReservations?.length && !this.checkoutCheckins?.length) {
          return this.$router.push({ name: 'checkoutSearch' })
        }

        this.loading = true

        if (this.checkoutReservations?.length) {
          this.payments = await this.getReservatinsPayments({
            reservationIds: this.checkoutReservations?.map(
              reservation => reservation.reservationId
            ),
          })
        }

        // TODO - STRANGE LOGIC
        if (this.checkoutCheckins?.length) {
          this.payments = await this.getReservatinsPayments({
            reservationIds: this.checkoutCheckins?.map(checkin => checkin.id),
          })
        }

        if (this.total_price <= 0) {
          this.$router.push({
            name: 'checkoutReview',
            query: {
              telephone: this.$route.query.telephone,
            },
          })
        }
      } catch (error) {
        this.globalErrorHandler({ error })
        // this.$router.push({ name: 'checkoutSearch' })
      } finally {
        this.loading = false
      }
    },
    async afterPayment(paymentsResult) {
      try {
        const checkinIds = this.checkoutReservations?.map(reservation => reservation.checkinId)

        const checkoutInfoResult = await this.postCheckoutInfo({
          orderId: paymentsResult.orderId,
          checkinIds,
        })
        const saleIds = checkoutInfoResult?.data?.saleIds

        let checkinIdsInfo = []

        const reservationList = await this.getStayReservations({
          telephone: this.$route.query.telephone,
        })

        for (const checkinId of checkinIds) {
          const reservationFilterList = reservationList.filter(
            reservationItem => reservationItem.checkinId === checkinId
          )
          const reserIds = reservationFilterList.map(item => item.reservationId)

          let guestName = null
          if (this.receiptName) {
            guestName = this.receiptName
          } else {
            guestName = reservationFilterList.length > 0 ? reservationFilterList[0].guestName : ''
          }

          checkinIdsInfo.push({
            checkinId,
            reserIds,
            saleIds,
            orderId: paymentsResult.orderId,
            guestName,
            paymentPage: 'checkout',
            provision: '',
          })
        }

        await this.backupReceiptPDF(checkinIdsInfo)
      } catch (error) {
        this.globalErrorHandler({ error })
      }
    },
    async onSubmit() {
      try {
        this.loadingAction = true
        // cash pay method
        if (this.selectedPaymentType !== 'creditCard') {
          return this.$router.push({
            name: 'checkoutReview',
            query: { telephone: this.hotelGuideData.telephone },
          })
        }

        // payload if credit card data exist
        const reservationIds = this.checkoutReservations?.map(
          reservation => reservation.reservationId
        )
        let cardId = this.userCreditCard?.cardId
        if (!cardId) {
          const processResult = await this.addCreditCard({
            cuicinUserId: this.userData.id,
            cardNumber: this.cardFormData.cardNumber.replace(/ /g, ''),
            cardExpire: this.cardFormData.cardExpire,
            securityCode: this.cardFormData.securityCode,
          })
          cardId = processResult.cardId
        }

        // pay with credit card
        const paymentsResult = await this.creditCardPay({
          cardId: this.userCreditCard.cardId,
          paymentAmount: this.total_price.toString(),
          reservationIds: reservationIds ?? [],
        })

        // if pay successful send payment info and redirect to review page
        if (paymentsResult.status === 'success') {
          try {
            const paymentPayload = {
              email: this.userData.email,
              hotelNameEn: this.hotelGuideData.facilityBasicInfo.hotelNameLatin,
              hotelNameJa: this.hotelGuideData.facilityBasicInfo.hotelName,
              hotelPhoneNumber: this.hotelGuideData.facilityBasicInfo.telephone,
              name: this.userData.name,
              paymentAmount: this.total_price.toString(),
              paymentMethods: paymentsResult.cardBrand,
              receptionNumber: paymentsResult.orderId,
              receptionDate: moment().format(),
              usageDetailUrl:
                `${process.env.VUE_APP_URL}/mypage/usage-detail/${paymentsResult.orderId}` +
                `?hotelId=${this.hotelGuideData.hotelId}` +
                `&lang=${this.$i18next.language}` +
                `&cuicinUserId=${this.userData.id}`,
              checkins: this.payments.reservations.map(reservation => {
                return {
                  roomInfo: reservation.roomInfo ? reservation.roomInfo : [],
                  useAmount: reservation.totalPrice,
                  paymentAmount: reservation.totalPayment,
                  internalDiscount: reservation.totalDiscount,
                }
              }),
            }

            await this.sendmailCheckoutPaymentInfo(paymentPayload)
          } catch (error) {
            this.globalErrorHandler({ error })
          }

          await this.afterPayment(paymentsResult)

          this.$router.push({
            name: 'checkoutReview',
            query: {
              telephone: this.$route.query.telephone,
            },
          })
        }
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loadingAction = false
      }
    },
  },
}
</script>
