<template>
  <div class="bg-white flex justify-center p-4 mb-4">
    <div class="max-w-[320px] w-full flex items-center justify-between">
      <div class="text-xs text-gray-400">
        {{ $t('Amount billed (tax included)') }}
      </div>
      <div class="text-xl">¥{{ productsCartTotalSum.toLocaleString() }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['productsCartTotalSum']),
  },
}
</script>
