<template>
  <MainLayout :title="$t('Please confirm the contents')" :loading="loading" :backRoute="back_route">
    <Form
      v-slot="{ meta }"
      @submit="handleCheckIn"
      class="w-full max-w-[600px] flex flex-col items-center mb-6"
    >
      <!-- Accommodation information -->
      <div
        v-if="checkinData"
        class="w-full mb-4 p-8 bg-white flex flex-col justify-start items-start relative"
      >
        <div class="w-full text-[16px] mb-4 text-center font-semibold">
          {{ $t('Accommodation information') }}
        </div>
        <Button @click="editData" class="absolute right-8 top-8" mode="icon">
          <img :src="require('@/assets/images/edit_icon.svg')" alt="" />
        </Button>
        <div class="mb-4">
          <div class="text-xs text-slate-600 leading-relaxed mb-1">
            {{ $t('Check-in Date') }}
          </div>
          <div class="text-[16px] font-semibold">
            {{ `${checkinData.inDate} ${checkinData.inTime}` }}
          </div>
        </div>
        <div class="mb-4">
          <div class="text-xs text-slate-600 leading-relaxed mb-1">
            {{ $t('Number of guests') }}
          </div>
          <div class="text-[16px] font-semibold">
            {{ checkinData.totalMembers }}
            {{ $t(checkinData.totalMembers > 1 ? 'Guests' : 'Guest') }}
          </div>
        </div>
        <div class="mb-4">
          <div class="text-xs text-slate-600 leading-relaxed mb-1">
            {{ $t('Companions') }}
          </div>
          <div class="text-[16px] font-semibold">
            <div v-if="!checkinData.companionsList?.length" class="text-[16px] font-semibold mb-1">
              -
            </div>
            <div
              v-else
              v-for="(item, index) of checkinData.companionsList"
              :key="index"
              class="text-[16px] font-semibold mb-1"
            >
              <template v-if="$i18next.language === 'ja'">
                {{ item.surname }} {{ item.name }}
              </template>
              <template v-else> {{ item.name }} {{ item.surname }}</template>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="text-xs text-slate-600 leading-relaxed mb-1">
            {{ $t('Previous place of stay') }}
          </div>
          <div class="text-[16px] font-semibold">
            {{
              checkinData.previousAccommodation === 'Other' && checkinData.previousPlace !== 'Home'
                ? checkinData.previousPlace || $t('Other')
                : $t('Home')
            }}
          </div>
        </div>
        <div class="mb-4">
          <div class="text-xs text-slate-600 leading-relaxed mb-1">
            {{ $t('Next destination') }}
          </div>
          <div class="text-[16px] font-semibold">
            {{
              checkinData.nextAccommodation === 'Other' && checkinData.nextPlace !== 'Home'
                ? checkinData.nextPlace || $t('Other')
                : $t('Home')
            }}
          </div>
        </div>
      </div>
      <!-- Representative information -->
      <div class="w-full mb-4 p-8 bg-white flex flex-col justify-start items-start relative">
        <div class="w-full text-[16px] mb-4 text-center font-semibold">
          {{ $t('Representative information') }}
        </div>
        <Button @click="editData" class="absolute right-8 top-8" mode="icon">
          <img :src="require('@/assets/images/edit_icon.svg')" alt="editIcon" />
        </Button>
        <div v-for="(item, index) of member_data" :key="index" class="mb-4">
          <div class="text-xs text-slate-600 leading-relaxed mb-1">
            {{ $t(item.name) }}
          </div>
          <div class="text-[16px] font-semibold">{{ item.value }}</div>
        </div>
      </div>
      <!-- Health check -->
      <SpecialRequestsInfoBlock
        v-if="showSpecialRequests"
        @edit="editData"
        @display="toggleSpecialRequests"
      />
      <!-- payment details -->
      <div
        v-if="checkinPaymentData"
        class="w-full mb-4 p-8 bg-white flex flex-col justify-start items-start relative"
      >
        <div class="w-full text-[16px] mb-4 text-center font-semibold">
          {{ $t('Payment details') }}
        </div>
        <Button @click="goToPayment" class="absolute right-8 top-8" mode="icon">
          <img :src="require('@/assets/images/edit_icon.svg')" alt="editIcon" />
        </Button>
        <div class="mb-4">
          <div class="text-xs text-slate-600 leading-relaxed mb-1">
            {{ $t('Amount billed') }}
          </div>
          <div class="text-[16px] font-semibold">
            {{ checkinPaymentData.paymentAmount }}
          </div>
        </div>
        <div class="mb-4" v-if="userCreditCard">
          <div class="text-xs text-slate-600 leading-relaxed mb-1">
            {{ $t('Payment options') }}
          </div>
          <div class="text-[16px] font-semibold">{{ userCreditCard.cardNumber }}</div>
        </div>
        <div class="mb-4" v-else>
          <div class="text-xs text-slate-600 leading-relaxed mb-1">
            {{ $t('Payment options') }}
          </div>
          <div class="text-[16px] font-semibold">{{ $t('Pay at the front desk') }}</div>
        </div>
      </div>

      <Button :disabled="!meta.valid" :loading="loadingAction" class="mt-6" type="submit">
        {{ $t('Check-in') }}
      </Button>

      <div class="mt-6 px-8 text-xs" style="color: #676767">
        ※<i18next :translation="$t('Mileage Guide')">
          <template #mail>
            <a href="mailto:support@aipass.jp">support@aipass.jp</a>
          </template>
        </i18next>
      </div>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { Form } from 'vee-validate'
import moment from 'moment'
import MainLayout from '@/components/layouts/MainLayout'
import SpecialRequestsInfoBlock from '@/components/blocks/SpecialRequestsInfoBlock'
import { checkUserDataValid } from '@/utils/validation'
import userOccupationMixin from '@/mixins/userOccupationMixin.vue'
import { REDIRECTS_PAGES } from '@/data/pagesList'

export default {
  name: 'CheckinConfirmView',
  mixins: [userOccupationMixin],
  components: {
    MainLayout,
    Form,
    SpecialRequestsInfoBlock,
  },
  data() {
    return {
      loading: false,
      loadingAction: false,
      userCreditCard: null,
      localAnaNumber: undefined,
      paymentResult: undefined,
      checkinId: uuidv4(),
      receiptName: undefined,
      showSpecialRequests: true,
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'checkinData',
      'customCheckinData',
      'hotelGuideData',
      'checkinPaymentData',
      'searchReservations',
    ]),
    member_data() {
      if (!this.userData) return []

      return [
        {
          name: 'Nationality',
          value:
            this.userData?.nationality === 'NJP' ? this.$t('non-Japanese') : this.$t('Japanese'),
        },
        {
          name: 'Full name',
          value: this.userData?.name,
        },
        {
          name: 'Date of birth',
          value: this.userData?.birthDate,
        },
        {
          name: 'Gender',
          value: this.userData?.gender === 'M' ? this.$t('Male') : this.$t('Female'),
        },
        {
          name: 'Phone number',
          value: this.userData?.telephone,
        },
        {
          name: 'Address',
          value: this.userData?.address,
        },
        {
          name: 'Occupation',
          value: this.convertedOccupationValueToLabel(this.userData),
        },
      ]
    },
    checkin_id() {
      return this.checkinData?.id || this.checkinId
    },
    back_route() {
      if (this.checkinPaymentData) return REDIRECTS_PAGES.CHECKIN_PAYMENT
      else if (!Number(sessionStorage.getItem('isGuidanceDisplay')) && this.searchReservations)
        return REDIRECTS_PAGES.CONFIRM_RESERVATIONS_LIST
      else if (!Number(sessionStorage.getItem('isGuidanceDisplay'))) return REDIRECTS_PAGES.CHECK_IN
      else return REDIRECTS_PAGES.CHECKIN_CUSTOM_FORM
    },
  },
  async mounted() {
    this.checkIsDataExist()

    if (!checkUserDataValid(this.userData))
      return this.$router.replace({ name: REDIRECTS_PAGES.CHECKIN_EDIT })

    try {
      this.loading = true

      if (this.checkinPaymentData?.receiptName !== undefined)
        this.receiptName = this.checkinPaymentData.receiptName
      else if (this.$route.query?.receiptName) this.receiptName = this.$route.query.receiptName
      else this.receiptName = this.userData.name
      if (this.checkinPaymentData && this.checkinPaymentData?.paymentMethod === 'creditCard') {
        this.userCreditCard = await this.getCreditCard({ cuicinUserId: this.userData.id })
      }
    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions([
      'deleteCheckinData',
      'getCreditCard',
      'creditCardPay',
      'globalErrorHandler',
      'sendmailCheckinInfo',
      'sendmailCheckinPaymentInfo',
      'getAccommodationInfo',
      'backupReceiptPDF',
      'isEnabledPlugin',
      'smartCheckin',
    ]),
    checkIsDataExist() {
      if (!this.checkinData) {
        return this.$router.push({ name: REDIRECTS_PAGES.CHECK_IN })
      }
      if (!this.customCheckinData && Number(sessionStorage.getItem('isGuidanceDisplay'))) {
        return this.$router.push({ name: REDIRECTS_PAGES.CHECKIN_CUSTOM_FORM })
      }
    },
    editData() {
      this.$router.push({ name: REDIRECTS_PAGES.CHECKIN_EDIT })
    },
    toggleSpecialRequests(value) {
      return (this.showSpecialRequests = value)
    },
    goToPayment() {
      return this.$router.push({
        name: 'checkinPayment',
        query: { isEdit: true, receiptName: this.receiptName ?? this.userData.name },
      })
    },
    async sendEmail() {
      try {
        const accommodationInfo = await this.getAccommodationInfo(this.checkin_id)

        const sendmailRequestBody = {
          hotelId: accommodationInfo.checkin.hotelId,
          email: accommodationInfo.checkin.email,
          name: accommodationInfo.checkin.name,
          hotelName: accommodationInfo.facilityBasicInfo.hotelName,
          hotelPhoneNumber: accommodationInfo.facilityBasicInfo.telephone,
          checkinCode: accommodationInfo.checkin.checkinCode,
          checkinDateTime: accommodationInfo.checkin.checkinTime,
          facilityGuideUrl: `${process.env.VUE_APP_URL}/hotel-guide?hotelId=${accommodationInfo.checkin.hotelId}`,
          mypageUrl: `${process.env.VUE_APP_URL}/mypage/checkin-history/${accommodationInfo.checkin.checkinId}?hotelId=${accommodationInfo.checkin.hotelId}&lang=${this.$i18next.language}&checkinCode=${accommodationInfo.checkin.checkinCode}`,
        }

        if (this.checkinPaymentData && this.checkinPaymentData.paymentMethod === 'creditCard') {
          this.sendmailCheckinPaymentInfo({
            ...sendmailRequestBody,
            usageDetailUrl: `${process.env.VUE_APP_URL}/mypage/usage-detail/${this.paymentResult?.orderId}?hotelId=${accommodationInfo.checkin.hotelId}&lang=${this.$i18next.language}&cuicinUserId=${this.userData.id}`,
            receptionNumber: this.paymentResult?.orderId,
            receptionDate: moment(),
            paymentMethods: this.paymentResult?.cardBrand,
            paymentAmount: this.paymentResult?.amount,
            reservations: this.searchReservations.map((reservation, index) => ({
              reservationNumber: index + 1,
              paymentAmount: reservation.totalPayment,
              internalDiscount: reservation.totalDiscount,
              useAmount: reservation.totalPrice,
            })),
          })
        } else {
          this.sendmailCheckinInfo(sendmailRequestBody)
        }
      } catch (error) {
        this.globalErrorHandler({ error })
      }
    },
    async handleCheckIn() {
      try {
        this.loadingAction = true

        if (this.checkinPaymentData && this.checkinPaymentData.paymentMethod === 'creditCard') {
          const payload = {
            cardId: this.userCreditCard.cardId,
            checkinId: this.checkin_id,
            paymentAmount: this.checkinPaymentData.paymentAmount.toString(),
            reservationIds: this.checkinData.reservationIds,
          }
          this.paymentResult = await this.creditCardPay(payload)
        }

        const result = await this.smartCheckin({
          checkinId: this.checkin_id,
          userId: this.userData.id,
          checkin: {
            hotelId: this.hotelGuideData.hotelId,
            jpnGtdStatus: 0,
            approvedStatus: 0,
            nextPlaceToStay:
              this.checkinData.nextAccommodation === 'Other' &&
              this.checkinData.nextPlace !== 'Home'
                ? this.checkinData.nextPlace || 'その他'
                : '自宅',
            previousPlaceToStay:
              this.checkinData.previousAccommodation === 'Other' &&
              this.checkinData.previousPlace !== 'Home'
                ? this.checkinData.previousPlace || 'その他'
                : '自宅',
            userCheckinTime: moment(`${this.checkinData.inDate}, ${this.checkinData.inTime}`)
              .local()
              .unix(),
            checkinTime: moment(`${this.checkinData.inDate}, ${this.checkinData.inTime}`)
              .local()
              .unix(),
            accompany: this.checkinData.companionsList?.map(i => {
              return this.$i18next.language === 'ja'
                ? { name: `${i.surname} ${i.name}` }
                : { name: `${i.name} ${i.surname}` }
            }),
            guest: {
              ...this.userData,
              telephone: this.checkinData.reservationTel,
              totalMember: this.checkinData.totalMembers,
            },
            user: this.userData,
          },
          reservationIds: this.checkinData.reservationIds,
          checkinNote: {
            memo:
              this.customCheckinData?.reduce(
                (memo, i) => memo + `\n${i.jpTitle}:${i.value || '-'}`,
                '施設からのご案内'
              ) ?? '',
          },
          paymentType: this.checkinPaymentData?.paymentMethod || '',
        })

        if (this.checkinData.reservationIds?.length) {
          try {
            if (this.paymentResult && this.paymentResult?.orderId) {
              await this.backupReceiptPDF([
                {
                  checkinId: this.checkin_id,
                  reserIds: this.checkinData.reservationIds,
                  saleIds: result?.saleIds ?? null,
                  orderId: this.paymentResult?.orderId,
                  guestName: this.receiptName ?? this.userData.name,
                  paymentPage: 'checkin',
                  provision: '',
                },
              ])
            }
          } catch (error) {
            this.globalErrorHandler({ error })
          }
        }

        await this.sendEmail()

        this.deleteCheckinData()
        this.$router.push({ name: 'checkinAccept' })
      } catch (error) {
        this.loadingAction = false
        this.globalErrorHandler({ error })
      }
    },
  },
}
</script>
