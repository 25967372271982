<template>
  <div class="w-full py-6 flex flex-col justify-center items-center">
    <img
      v-if="userData?.picture"
      :src="userData?.picture"
      alt="user image"
      class="w-[60px] h-[60px] rounded-full"
    />
    <div
      v-else
      class="w-[60px] h-[60px] flex justify-center items-center rounded-full bg-fuchsia-300"
    >
      <img :src="require('@/assets/images/navbar/account_icon.svg')" alt="default user icon" />
    </div>
    <p class="mt-4 text-[16px] font-semibold">{{ userData?.name }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserInfo',
  computed: {
    ...mapGetters(['userData']),
  },
}
</script>
