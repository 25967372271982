<template>
  <div class="w-full p-4 flex flex-col items-center font-lato">
    <template v-if="is_front_supported">
      <p class="text-amber text-2xl text-center font-medium mt-12 mb-8 whitespace-pre">
        {{ $t('Please complete the procedure\nat the front desk.') }}
      </p>
      <img class="my-4" :src="require('@/assets/images/checkout_ng.svg')" />
      <p class="text-gray text-center whitespace-pre my-4">
        {{ $t('Please return the room key and settle the\npayment at the front desk.') }}
      </p>
    </template>
    <template v-else>
      <p class="text-amber text-4xl mt-12 font-semibold">{{ $t('THANK YOU!') }}</p>
      <img class="my-4" :src="require('@/assets/images/checkout_ok.svg')" />
      <p class="text-gray text-center">
        {{ $t('Thank you for your stay.') }}
      </p>
      <p class="text-gray text-center">
        {{ $t('Please return the key and leave.') }}
      </p>
    </template>

    <RouterLinkControl componentName="home">
      <Button mode="outline">
        {{ $t('Back to main') }}
      </Button>
    </RouterLinkControl>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RouterLinkControl from '@/components/controls/RouterLinkControl'

export default {
  name: 'CheckoutThanksView',
  components: {
    RouterLinkControl,
  },
  computed: {
    ...mapGetters(['checkoutReservations', 'existsChekcoutError']),
    is_front_supported() {
      return this.existsChekcoutError || this.checkoutReservations?.some(reservation => reservation.frontSupported)
    },
  },
}
</script>
