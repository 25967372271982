import { getSessionStorageObject, setSessionStorageObject } from '@/utils/sessionStorage'
import api from '@/api'

const state = {
  checkoutReservations: getSessionStorageObject('checkoutReservations'),
  checkoutCheckins: getSessionStorageObject('checkoutCheckins'),
  checkoutResultHistories: getSessionStorageObject('checkoutResultHistories') || {},
}

const getters = {
  checkoutReservations: state => state.checkoutReservations,
  checkoutCheckins: state => state.checkoutCheckins,
  existsChekcoutError: state => Object.values(state.checkoutResultHistories).some(isCompleted => !isCompleted),
}

const mutations = {
  SET_CHECKOUT_RESERVATIONS(state, data) {
    state.checkoutReservations = data
    setSessionStorageObject('checkoutReservations', state.checkoutReservations)
  },
  SET_CHECKOUT_CHECKINS(state, data) {
    state.checkoutCheckins = data
    setSessionStorageObject('checkoutCheckins', state.checkoutCheckins)
  },
  SET_CHECKOUT_RESULT(state, { reservationOrCheckinId, isCompleted }) {
    Object.assign(state.checkoutResultHistories, { [reservationOrCheckinId]: isCompleted })
    setSessionStorageObject('checkoutResultHistories', state.checkoutResultHistories)
  },
  CLEAR_CHECKOUT_RESULT(state) {
    state.checkoutResultHistories = {}
    setSessionStorageObject('checkoutResultHistories', state.checkoutResultHistories)
  },
}

const actions = {
  getStayReservations({ getters }, data) {
    return api
      .get('stay-reservation', { params: { hotelId: getters['hotelId'], ...data } })
      .then(res => res.data.stayReservations)
  },
  getCheckinsList({ getters }, data) {
    return api.get('checkin', { params: { hotelId: getters['hotelId'], ...data } }).then(res => res.data.checkin)
  },
  sendReservationReview({ getters }, data) {
    return api.put('reservation-review', { hotelId: getters['hotelId'], ...data })
  },
  sendCheckinReview({ getters }, data) {
    return api.put('checkin-review', { hotelId: getters['hotelId'], ...data })
  },
  checkout({ commit, getters }, data) {
    return api
      .put('checkout', { hotelId: getters['hotelId'], ...data })
      .then(() =>
        commit('SET_CHECKOUT_RESULT', {
          reservationOrCheckinId: data.reservationId || data.checkoutId,
          isCompleted: true,
        })
      )
      .catch(() =>
        commit('SET_CHECKOUT_RESULT', {
          reservationOrCheckinId: data.reservationId || data.checkoutId,
          isCompleted: false,
        })
      )
  },
  getReservatinsPayments({ getters }, data) {
    return api.post('checkout', { hotelId: getters['hotelId'], ...data }).then(res => res.data)
  },
  getCheckinsPayments({ getters }, data) {
    return api.post('v2/checkout', { hotelId: getters['hotelId'], ...data }).then(res => res.data)
  },
  creditCardPay({ getters }, data) {
    return api
      .post('payment/credit-card-payment', { hotelId: getters['hotelId'], ...data })
      .then(res => res.data.processResult)
  },
  creditCardPayForMobileRequest({ getters }, data) {
    return api
      .post('mobile-request/payment', { hotelId: getters['hotelId'], ...data })
      .then(res => ({ mobileRequestOrderId: res.data.mobileRequestOrderId, ...res.data.processResult }))
  },
  sendmailCheckoutPaymentInfo(_, data) {
    return api.post('frontend-sendmail/checkout-payment-info', data)
  },
  setCheckoutReservations({ commit }, data) {
    commit('SET_CHECKOUT_RESERVATIONS', data)
  },
  setCheckoutCheckins({ commit }, data) {
    commit('SET_CHECKOUT_CHECKINS', data)
  },
  clearCheckoutResult({ commit }) {
    commit('CLEAR_CHECKOUT_RESULT')
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
