<template>
  <MainLayout
    :title="$t('Invoice')"
    :loading="loading"
    backRoute="myPage"
    class="flex flex-column items-center"
  >
    <div
      v-if="!userPaymentInfo?.userPayment?.length"
      class="w-full max-w-[600px] p-8 bg-white flex flex-col justify-center items-center"
    >
      <p>{{ $t('No invoice') }}</p>
    </div>
    <template v-else>
      <div
        v-for="(item, index) of userPaymentInfo.userPayment"
        :key="index"
        class="w-full max-w-[600px] px-6 my-2 py-4 bg-white flex justify-between items-center cursor-pointer"
        @click="handlePushToItem(item)"
      >
        <div class="font-lato">
          <p class="font-semibold">{{ item.hotelName }}</p>
          <p class="text-xs mt-1">{{ setTimeFormat(item.createdAt) }}</p>
          <p class="text-xs mt-1 max-w-[200px] whitespace-nowrap truncate">
            {{ $t('Transaction Number') }} : {{ item.orderId }}
          </p>
        </div>
        <img :src="require('@/assets/images/controls/arrow_right.svg')" />
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import MainLayout from '@/components/layouts/MainLayout'

export default {
  name: 'InvoiceView',
  components: {
    MainLayout,
  },
  data() {
    return {
      loading: false,
      userPaymentInfo: undefined,
    }
  },
  computed: {
    ...mapGetters(['userData']),
  },
  async mounted() {
    try {
      this.loading = true

      this.userPaymentInfo = await this.getUserPayment()
    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions(['getUserPayment', 'globalErrorHandler']),
    setTimeFormat(time) {
      return moment(time).format('YYYY/MM/DD HH:mm')
    },
    handlePushToItem(item) {
      this.$router.push({
        name: 'invoiceDetails',
        params: {
          id: item.orderId,
        },
      })
    },
  },
}
</script>
