<template>
  <div v-if="payment_options?.length" class="w-full bg-white flex justify-center mt-2 px-8 py-6">
    <SelectField
      v-model="formData.paymentType"
      :label="$t('Payment options')"
      :options="payment_options"
      :emptyItemTitle="$t('Please select')"
      :rules="fieldValidators.required()"
      name="paymentType"
      class="w-full mb-1"
    />
  </div>
  <div
    v-if="formData.paymentType === 'creditCard' && userData"
    class="w-full b-2 bg-white border-t-[1px] border-my-page"
  >
    <CardList @load="userCreditCard => $emit('loadCreditCard', userCreditCard)">
      <template #noCard>
        <CardForm v-model="cardFormData" class="p-8" />
      </template>
    </CardList>
  </div>
  <LoginBlock v-if="formData.paymentType === 'creditCard' && !userData">
    <template #top>
      <p class="text-xs text-slate-600 leading-relaxed mb-4">
        {{ $t('Login is required for credit card payment') }}
      </p>
    </template>
  </LoginBlock>
  <div
    v-if="formData.paymentType === 'creditCard'"
    class="w-full bg-white justify-center mt-6 px-8 py-6"
  >
    <TextField
      v-model="receiptName"
      :label="$t('Receipt Name')"
      :placeholder="$t('Receipt Name Example')"
      :hint="$t('If you wish to split the receipt, please make the payment at the facility')"
      name="receiptName"
      type="text"
      class="mb-0"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import CardList from '@/components/sections/creditCard/CardList'
import CardForm from '@/components/blocks/CardForm'
import LoginBlock from '@/components/blocks/LoginBlock'
import formValidatorsMixin from '@/mixins/formValidatorsMixin'

export default {
  mixins: [formValidatorsMixin],
  components: { CardList, CardForm, LoginBlock },
  props: {
    selectedPaymentType: String,
    paymentPlace: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      receiptName: undefined,
      cardFormData: undefined,
      formData: {
        paymentType: undefined,
      },
    }
  },
  computed: {
    ...mapGetters(['userData', 'hasPaymentPlugin', 'paymentSetting', 'loginFrom']),
    payment_options() {
      if (!this.hasPaymentPlugin) return []

      const paymentMethods =
        this.paymentSetting?.payment_time
          ?.find(v => v.is_enabled && v.value === this.paymentPlace)
          ?.payment_method?.filter(v => v.is_enabled) ?? []

      if (!paymentMethods.length) return []

      const paymentOptions = []

      for (const item of paymentMethods) {
        if (item.value === 'DIRECT') {
          paymentOptions.push({
            label: this.$t('Payment at the front desk'),
            value: 'checkout',
          })
        } else if (item.value === 'CREDIT_CARD') {
          paymentOptions.push({
            label: this.$t('Credit Card Payment'),
            value: 'creditCard',
          })
        }
      }

      return paymentOptions
    },
  },
  watch: {
    'formData.paymentType'() {
      if (this.formData.paymentType !== 'creditCard') this.$emit('changeCardData', undefined)

      this.$emit('changePaymentType', this.formData.paymentType)
    },
    cardFormData: {
      deep: true,
      handler() {
        this.$emit('changeCardData', this.cardFormData)
      },
    },
    receiptName: {
      handler() {
        this.$emit('receiptName', this.receiptName)
      },
    },
  },
  async mounted() {
    try {
      this.loading = true

      if (!this.paymentSetting) {
        await this.getPaymentSetting()
      }

      if (!this.userData) {
        await this.getUserInfo()
      }

      if (this.$route.query.receiptName) {
        this.receiptName = this.$route.query.receiptName
      } else if (this.userData.name) {
        this.receiptName = this.userData.name
      }

      if (this.payment_options.length === 1) {
        this.formData.paymentType = this.payment_options[0].value
      } else if (this.selectedPaymentType) {
        this.formData.paymentType = this.selectedPaymentType
      }
    } catch (error) {
      this.globalErrorHandler({ error, skipUnauthorizedError: true })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions(['getPaymentSetting', 'globalErrorHandler', 'getUserInfo']),
  },
}
</script>
