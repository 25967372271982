<template>
  <template v-if="error">{{ error.toString ? error.toString() : error }}</template>
  <canvas v-else ref="canvasRef" />
</template>

<script>
import QRCode from 'qrcode'

export default {
  props: {
    value: String,
    size: { type: Number, default: 128 },
  },
  data() {
    return {
      error: undefined,
    }
  },
  mounted() {
    this.renderQR()
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.renderQR()
      },
    },
  },
  methods: {
    renderQR() {
      this.error = undefined

      if (!this.$refs['canvasRef']) return

      QRCode.toCanvas(
        this.$refs['canvasRef'],
        this.value,
        { width: this.size, height: this.size },
        error => {
          if (error) this.error = error
        }
      )
    },
  },
}
</script>
