<template>
  <div class="w-full max-w-[600px] my-2 bg-white py-1">
    <p class="font-semibold mx-8 mt-6 mb-2 truncate">
      {{ $t('Reservation ID') }} {{ reservation?.reservationId || '-' }}
    </p>
    <div class="text-sm font-semibold text-slate-600 flex mx-8 mb-8">
      {{ getDate(reservation.checkinDate) }} {{ reservation.nights }}
      {{ reservation.nights === 1 ? $t('night') : $t('nights') }}
    </div>
    <div class="w-full bg-my-page flex px-8 py-2 text-gray">
      <div class="w-[20%] text-xs whitespace-nowrap">{{ $t('Date') }}</div>
      <div class="w-[50%] text-xs whitespace-nowrap">{{ $t('Reference') }}</div>
      <div class="w-[30%] text-xs text-end whitespace-nowrap">{{ $t('Amount used') }}</div>
    </div>
    <div
      v-for="(item, index) of reservation.sales"
      :key="index"
      class="w-full font-lato border-b-2 border-my-page flex px-8 py-3"
    >
      <div class="w-[20%] text-xs">{{ getDate(item.salesDate) }}</div>
      <div class="w-[50%] text-xs">
        {{ translateObject(item, 'salesSubjectName', $i18next.language) }}
      </div>
      <div class="w-[30%] text-xs text-end">¥{{ (+item.salesPrice).toLocaleString() }}</div>
    </div>
    <div class="w-full px-8 my-4 flex justify-between items-center text-sm font-semibold">
      <div>{{ $t('Amount used') }}</div>
      <div>¥{{ (+reservation.totalPrice).toLocaleString() }}</div>
    </div>
    <div class="w-full px-8 mt-4 mb-1 flex justify-between items-center text-sm font-semibold">
      <div>{{ $t('Credit') }}</div>
      <div>¥{{ (+reservation.totalDiscount).toLocaleString() }}</div>
    </div>
    <div class="w-full px-8 mb-6 flex justify-end items-center text-xs text-gray">
      <div>({{ $t('discount') }}：¥{{ (+reservation.totalDiscount).toLocaleString() }})</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import { capitalize, translateObject } from '@/utils/text'

export default {
  name: 'RoomBillDetails',
  props: {
    reservation: {
      type: Object,
    },
  },
  methods: {
    capitalize,
    translateObject,
    getDate(date) {
      return moment(date).format('MM/DD')
    },
  },
}
</script>
