<template>
  <div v-if="!!product" class="flex bg-white flex-col relative" @click="$emit('click')">
    <div
      v-if="count"
      class="absolute -right-2 -top-2 bg-amber p-1 min-w-[28px] text-center rounded-full text-white text-sm"
    >
      {{ count }}
    </div>
    <div class="w-full aspect-[5/4] rounded overflow-hidden bg-gray-300">
      <img
        v-if="product.imagePath"
        :src="product.imagePath"
        :alt="$i18next.getValueByLanguage(product, 'name')"
        :title="$i18next.getValueByLanguage(product, 'name')"
        class="object-cover w-full h-full"
      />
    </div>
    <div class="mt-2">
      <div class="text-xs line-clamp-3 font-bold">
        {{ $i18next.getValueByLanguage(product, 'name') }}
      </div>
      <div class="text-xs text-gray font-bold">
        <div>¥{{ product.price.toLocaleString() }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: { type: Object, required: true },
    count: Number,
  },
}
</script>
