<template>
  <MainLayout :title="$t('Payment details')">
    <Form @submit="onSubmit" v-slot="{ meta }" class="max-w-[600px] w-full">
      <div class="bg-white flex justify-center mb-4 pt-10 pb-4">
        <div class="max-w-[320px] w-full">
          <div v-for="room in cartRooms" :key="room.id" class="font-bold mb-2">
            {{ $i18next.language === 'ja' ? `${room.name}様` : `Dear ${room.name}` }}
          </div>
          <span class="text-xs text-[#272727]">{{ $t('Select your room') }}</span>
          <div class="mt-4">
            <SelectField
              v-model="formData.room"
              :options="rooms_options"
              :rules="fieldValidators.required()"
              name="room"
              class="w-full"
            />
          </div>
        </div>
      </div>
      <CartListBlock />
      <CartTotalBlock />
      <PaymentBlock
        v-if="productsCartTotalSum > 0"
        @loadCreditCard="v => (userCreditCard = v)"
        @changePaymentType="v => (paymentType = v)"
        @changeCardData="v => (userCardData = v)"
        @receiptName="v => (receiptName = v)"
        payment-place="MOBILE_REQUEST"
      />
      <Button
        :loading="loadingAction"
        :disabled="!meta.valid || (paymentType === 'creditCard' && !this.userData)"
        class="mx-auto mb-4"
        type="submit"
      >
        {{ $t('Submit') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { uniq } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'
import moment from 'moment'

import MainLayout from '@/components/layouts/MainLayout'
import CartListBlock from '@/components/blocks/product/CartListBlock'
import CartTotalBlock from '@/components/blocks/product/CartTotalBlock'
import PaymentBlock from '@/components/blocks/PaymentBlock'
import formValidatorsMixin from '@/mixins/formValidatorsMixin'
import { capitalize } from '@/utils/text'
import { paymentMethodDetail } from '@/data/payment'
import { REDIRECTS_PAGES } from '@/data/pagesList'

export default {
  mixins: [formValidatorsMixin],
  components: { MainLayout, Form, CartListBlock, CartTotalBlock, PaymentBlock },
  data() {
    return {
      REDIRECTS_PAGES,
      loadingAction: false,
      paymentMethods: undefined,
      paymentResult: undefined,
      formData: {
        room: undefined,
      },
      userCreditCard: undefined,
      userCardData: undefined,
      receiptName: undefined,
    }
  },
  computed: {
    ...mapGetters([
      'productsCart',
      'notSalesProductsInCart',
      'cartRooms',
      'productsCartTotalCount',
      'productsCartTotalSum',
      'requestPaymentSetting',
      'userData',
      'hotelGuideData',
    ]),
    rooms_options() {
      return uniq(this.cartRooms.reduce((sum, room) => [...sum, ...room.roomNumber], [])).sort()
    },
    checkin_user() {
      return this.cartRooms.find(room => room.roomNumber.includes(this.formData.room))
    },
  },
  watch: {
    productsCartTotalCount: {
      immediate: true,
      handler() {
        if (!this.productsCartTotalCount) return this.$router.replace({ name: 'productsListView' })
      },
    },
  },
  async mounted() {
    if (!this.cartRooms.length) {
      return this.$router.replace({ name: 'cartProductsConfirmationView' })
    }
    this.formData.room = this.rooms_options[0]
  },
  methods: {
    capitalize,
    ...mapActions([
      'addCreditCard',
      'globalErrorHandler',
      'changeProductsCartCount',
      'makeRequestOrder',
      'makeSales',
      'creditCardPayForMobileRequest',
      'sendmailMobileRequestInfoForUser',
      'sendmailMobileRequestInfoForAdmin',
      'backupReceiptPDF',
      'getProductsList',
      'getCartProductsPastSalesHour',
    ]),

    async onSubmit() {
      try {
        this.getCartProductsPastSalesHour()

        this.loadingAction = true

        if (this.paymentType === 'creditCard') {
          let paymentInfo = {
            checkinId: this.checkin_user.checkinId,
            paymentAmount: this.productsCartTotalSum.toString(),
            productIds: Object.values(this.productsCart).map(item => item.product.id),
          }

          let cardId = this.userCreditCard?.cardId
          if (!cardId) {
            const processResult = await this.addCreditCard({
              cuicinUserId: this.userData.id,
              cardNumber: this.userCardData.cardNumber.replace(/\s+/g, ''),
              cardExpire: `${this.userCardData.cardExpire.split('/')[0]}/${
                this.userCardData.cardExpire.split('/')[1]
              }`,
              securityCode: this.userCardData.securityCode,
            })
            cardId = processResult.cardId
          }

          this.paymentResult = await this.creditCardPayForMobileRequest({ ...paymentInfo, cardId })
        }

        await this.runMakeOrder()
        this.salesResult = await this.runMakeSales()
        await this.runAfterPayment()
        await this.runSendMail()

        this.$router.replace({ name: 'cartOrderCompleteView' })
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loadingAction = false
      }
    },
    async runMakeOrder() {
      const order = {
        checkinId: this.checkin_user.checkinId,
        mobileRequestOrderId: this.paymentResult?.mobileRequestOrderId
          ? this.paymentResult?.mobileRequestOrderId
          : null,
        roomNumber: this.formData.room,
        paymentMethod:
          paymentMethodDetail[this.paymentType]?.code || paymentMethodDetail['checkout'].code,
        products: Object.values(this.productsCart).map(item => ({
          id: item.product.id,
          numberOfItems: Number(item.count),
        })),
      }

      await this.makeRequestOrder(order)
    },
    async runMakeSales() {
      const sales = Object.values(this.productsCart).map(item => ({
        checkinId: this.checkin_user.checkinId,
        quantity: item.count,
        salesDate: moment().format('YYYY-MM-DD'),
        salesPaymentPrice:
          this.paymentType && paymentMethodDetail[this.paymentType].code === 1
            ? item.product.price * item.count
            : 0,
        salesSubjectId: item.product.salesSubject?.salesSubjectMasterId || null,
        salesSubjectPrice: item.product.price,
        orderId: this.paymentResult?.orderId,
        isDiscount: 0,
        paymentMethod: this.paymentType,
      }))

      return await this.makeSales(sales)
    },
    async runSendMail() {
      try {
        if (this.userData) {
          const sendmailRequestBodyForUser = {
            orderDate: moment(),
            roomNumber: this.formData.room,
            totalPrice: this.productsCartTotalSum.toLocaleString(),
            orderPaymentMethod:
              paymentMethodDetail[this.paymentType]?.name || paymentMethodDetail['checkout'].name,
            orderPaymentMethodEn:
              paymentMethodDetail[this.paymentType]?.nameEn ||
              paymentMethodDetail['checkout'].nameEn,
            receptionNumber: this.paymentResult ? this.paymentResult.orderId : null,
            receptionDate: moment(),
            paymentMethod: this.paymentResult ? this.paymentResult.cardBrand : null,
            usageDetailUrl: this.paymentResult
              ? `${process.env.VUE_APP_URL}/mypage/usage-detail/${this.paymentResult.orderId}?hotelId=${this.hotelGuideData.hotelId}&lang=${this.$i18next.language}`
              : null,
            hotelName: this.hotelGuideData.facilityBasicInfo.hotelName,
            hotelPhoneNumber: this.hotelGuideData.facilityBasicInfo.telephone,
            products: Object.values(this.productsCart).map(orderProduct => ({
              product_name: orderProduct.product.name.ja,
              product_name_en: orderProduct.product.name.en,
              number_of_items: orderProduct.count,
            })),
            email: this.userData.email,
            guestName: this.checkin_user.name,
          }
          await this.sendmailMobileRequestInfoForUser(sendmailRequestBodyForUser)
        }
        const sendmailRequestBodyForAdmin = {
          hotelId: this.hotelGuideData.hotelId,
          hotelName: this.hotelGuideData.facilityBasicInfo.hotelName,
          orderDate: moment(),
          receptionDate: moment(),
          guestName: this.checkin_user.name,
          roomNumber: this.formData.room,
          products: Object.values(this.productsCart).map(orderProduct => ({
            product_name: orderProduct.product.name.ja,
            product_name_en: orderProduct.product.name.en,
            number_of_items: orderProduct.count,
          })),
          totalPrice: this.productsCartTotalSum.toLocaleString(),
          orderPaymentMethod:
            paymentMethodDetail[this.paymentType]?.name || paymentMethodDetail['checkout'].name,
          orderPaymentMethodEn:
            paymentMethodDetail[this.paymentType]?.nameEn || paymentMethodDetail['checkout'].nameEn,
          receptionNumber: this.paymentResult ? this.paymentResult.orderId : null,
          paymentMethod: this.paymentResult ? this.paymentResult.cardBrand : null,
          hotelPhoneNumber: this.hotelGuideData.facilityBasicInfo.telephone,
        }
        await this.sendmailMobileRequestInfoForAdmin(sendmailRequestBodyForAdmin)
      } catch (error) {
        this.globalErrorHandler({ error })
      }
    },
    async runAfterPayment() {
      try {
        await this.backupReceiptPDF([
          {
            checkinId: this.checkin_user.checkinId,
            reserIds: this.salesResult?.reservationIds,
            saleIds: this.salesResult?.saleIds,
            orderId: this.paymentResult?.orderId ?? null,
            guestName: this.receiptName ?? this.checkin_user.name,
            paymentPage: 'request',
            provision: '',
          },
        ])
      } catch (error) {
        this.globalErrorHandler({ error })
      }
    },
  },
}
</script>
