import api from '@/api'

const state = {
  iconsDisplayedList: null,
  hotelGuideData: null,
  facilityHotelInfo: null,
  paymentSetting: undefined,
}

const getters = {
  iconsDisplayedList: state => state.iconsDisplayedList,
  hotelGuideData: state => state.hotelGuideData,
  facilityHotelInfo: state => state.facilityHotelInfo,
  hasPaymentPlugin: state => state.hotelGuideData?.plugins?.find(element => element.code === 'PA')?.isEnabled,
  paymentSetting: state => state.paymentSetting,
  checkinPaymentSetting: state => {
    const checkinItem = state.paymentSetting?.payment_time?.find(item => item.value === 'CHECKIN')
    if (!checkinItem?.is_enabled) {
      return []
    }
    return (checkinItem.payment_method || []).filter(method => method.is_enabled)
  },
  requestPaymentSetting: state => state.paymentSetting?.payment_time?.find(item => item.value === 'MOBILE_REQUEST'),
  hotelId: state => state.hotelGuideData.hotelId,
  hasGuestAppPlugin: state => state.hotelGuideData?.plugins?.find(element => element.code === 'GUE')?.isEnabled,
}

const mutations = {
  SET_DISPLAYED_ICONS_LIST(state, data) {
    state.iconsDisplayedList = data
  },
  SET_HOTEL_GUIDE_DATA(state, data) {
    state.hotelGuideData = data
  },
  SET_FACILITY_INFO(state, data) {
    state.facilityHotelInfo = data
  },
  SET_PAYMENT_SETTING(state, data) {
    state.paymentSetting = data
  },
}

const actions = {
  getDisplayedIconsList({ commit, getters }) {
    return api.get('icon-display', { params: { hotelId: getters['hotelId'] } }).then(response => {
      if (!response) return

      const { data } = response

      commit('SET_DISPLAYED_ICONS_LIST', data)
    })
  },
  getHotelGuide({ commit }, id) {
    return api.get('hotel-guide', { params: { hotelId: id } }).then(response => {
      if (!response) return

      const { data } = response

      commit('SET_HOTEL_GUIDE_DATA', data)

      return data
    })
  },
  getHotelAsset(store, hotelId) {
    return api.get('guest/assets', { params: { hotelId } }).then(response => {
      if (!response) return
      const { data } = response
      return data
    }).catch(() => [])
  },
  getFacilityInfo({ commit, getters }) {
    return api.get('facility-additional-info', { params: { hotelId: getters['hotelId'] } }).then(res => {
      commit('SET_FACILITY_INFO', res.data)
    })
  },
  getPaymentSetting({ getters, commit }) {
    return api.get('payment-setting-by-hotel', { params: { hotelId: getters['hotelId'] } }).then(res => {
      commit('SET_PAYMENT_SETTING', res.data?.settings)
      return res.data?.settings
    })
  },
  isEnabledPlugin({ state }, { pluginCode }) {
    return !!state.hotelGuideData?.plugins?.find(element => element.code === pluginCode)?.isEnabled
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
