<template>
  <MainLayout class="flex bg-[#fff]" hideHeader>
    <div class="text-center m-auto">
      <h1 class="text-4xl text-amber mb-8">{{ $t('THANK YOU!') }}</h1>
      <img
        :src="require('@/assets/images/mobile-request/request.svg')"
        :alt="$t('order')"
        class="mx-auto"
      />
      <p class="text-[14px] leading-[21px] mt-[41px] ml-[58px] mr-[57px]">
        {{ $t('Thank you for your order.') }}
      </p>
      <Button @click="$router.replace({ name: 'home' })" class="mx-auto">
        {{ $t('TOP') }}
      </Button>
    </div>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: { MainLayout },
  methods: {
    ...mapActions(['clearCart']),
  },
  mounted() {
    this.clearCart()
  },
}
</script>
