<template>
  <MainLayout :title="$t('Member Information')" backRoute="myPage">
    <div class="w-full min-h-[calc(100vh_-_198px)] bg-my-page flex flex-col items-center">
      <div class="w-full max-w-[600px] p-8 bg-white flex flex-col justify-start items-start">
        <div v-for="(item, index) of member_data" :key="index" class="mb-4">
          <div v-if="item.data" class="text-sm text-slate-600 leading-relaxed mb-1">
            {{ item.name }}
          </div>
          <div v-if="item.data" class="text-[16px] font-semibold">
            {{ item.data }}
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import userOccupationMixin from '@/mixins/userOccupationMixin.vue'

export default {
  name: 'MemberInfoView',
  mixins: [userOccupationMixin],
  components: { MainLayout },
  computed: {
    ...mapGetters(['userData']),
    member_data() {
      return [
        {
          name: this.$t('Nationality'),
          data: this.userData?.nationality === 'NJP' ? 'non-Japanese' : 'Japanese',
        },
        {
          name: this.$t('Full name'),
          data: this.userData?.name,
        },
        {
          name: this.$t('Date of birth'),
          data: this.userData?.birthDate,
        },
        {
          name: this.$t('Gender'),
          data: this.userData?.gender === 'M' ? this.$t('Male') : this.$t('Female'),
        },
        {
          name: this.$t('Phone number'),
          data: this.userData?.telephone,
        },
        {
          name: this.$t('Address'),
          data: this.userData?.address,
        },
        {
          name: this.$t('Occupation'),
          data: this.convertedOccupationValueToLabel(this.userData),
        },
      ]
    },
  },
}
</script>
