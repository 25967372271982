export const getSessionStorageObject = key => {
  try {
    return JSON.parse(sessionStorage.getItem(key))
  } catch {
    return undefined
  }
}

export const setSessionStorageObject = (key, object) => {
  sessionStorage.setItem(key, JSON.stringify(object))
}
