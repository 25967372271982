<template>
  <Modal v-model="isModalShow" :clickToClose="false">
    <div class="flex flex-col justify-center items-center bg-white p-6 rounded max-w-[320px]">
      <div class="text-center font-semibold text-xl mb-3 mt-4 text-amber">
        {{
          $t(
            status === 'reset'
              ? 'Please reset your password for system migration'
              : 'Please reset your password'
          )
        }}
      </div>
      <div class="text-center text-gray text-xs mb-6 whitespace-pre-wrap">
        {{
          $t(
            'We have sent a password reset notification email to your registered email address. Please click on the reset link provided and enter your new password.'
          )
        }}
        <div>{{ $t('Sent from') }}: noreply@aipass.jp</div>
      </div>
      <Button @click="$emit('close-modal')" class="mt-0 mb-4">
        {{ $t('Change is complete') }}
      </Button>
      <Button
        @click="resetPassword"
        :loading="resetPasswordLoading"
        :disabled="resetPasswordDisabled"
        class="mt-0 mb-4"
        mode="outline"
      >
        {{ $t(!resetPasswordDisabled ? 'Resend' : 'Resend completed') }}
      </Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'

import Modal from '@/components/UI/Modal'

export default {
  name: 'ResetPasswordModal',
  components: {
    Modal,
  },
  props: {
    isShow: Boolean,
    email: String,
    mailStatus: String,
  },
  watch: {
    isShow() {
      this.isModalShow = this.isShow
      this.status = this.mailStatus

      if (this.timeout) clearTimeout(this.timeout)
      if (this.isShow) {
        this.timeout = setTimeout(() => {
          this.resetPasswordDisabled = false
        }, this.resetPasswordTimeout)
      }
    },
  },
  data() {
    return {
      isModalShow: false,
      resetPasswordLoading: false,
      resetPasswordDisabled: true,
      resetPasswordTimeout: 60000,
      status: null,
    }
  },
  methods: {
    ...mapActions(['forgotPassword', 'globalErrorHandler']),
    async resetPassword() {
      try {
        this.resetPasswordLoading = true

        await this.forgotPassword({
          email: this.email,
          hotelId: this.$route.query.hotelId,
          lang: this.$route.query.lang,
        })

        this.resetPasswordDisabled = true

        setTimeout(() => {
          this.resetPasswordDisabled = false
        }, this.resetPasswordTimeout)
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.resetPasswordLoading = false
      }
    },
  },
}
</script>
