<template>
  <div class="max-w-[240px] mx-auto my-5 flex flex-wrap align-cente justify-start">
    <NavButtonControl
      v-if="navigationItems('checkin')"
      :title="$t('Check-in')"
      icon="checkInIcon"
      component="checkIn"
    />

    <NavButtonControl
      v-if="navigationItems('checkout')"
      :title="$t('Check-out')"
      icon="checkoutIcon"
      component="checkoutSearch"
    />

    <NavButtonControl
      v-if="navigationItems('hotelGuide')"
      :title="$t('Information')"
      icon="infoIcon"
      component="infoMenu"
    />

    <NavButtonControl
      v-if="navigationItems('aroundSpot')"
      :title="$t('Travel guide')"
      icon="mapIcon"
      component="TravelGuideView"
    />

    <NavButtonControl
      v-if="navigationItems('mobileRequest')"
      :title="capitalize($t('order'))"
      icon="requestIcon"
      component="productsListView"
    />

    <NavButtonControl
      v-if="navigationItems('frontTelephone')"
      :title="$t('Front')"
      icon="callIcon"
      @open-modal="$emit('open-modal')"
    />

    <NavButtonControl
      v-if="navigationItems('wifi')"
      :title="$t('Wifi')"
      icon="wifiIcon"
      component="wifi"
    />

    <NavButtonControl :title="$t('My Page')" icon="accountIcon" component="myPage" />
  </div>
</template>

<script>
import NavButtonControl from '@/components/controls/NavButtonControl'
import { capitalize } from '@/utils/text'

export default {
  name: 'MainNavigationBlock',
  components: {
    NavButtonControl,
  },
  props: {
    iconsDisplayedList: Object,
  },
  methods: {
    capitalize,
    navigationItems(name) {
      return !this.iconsDisplayedList?.icon_display
        ? true
        : this.iconsDisplayedList?.icon_display[0][name] === 1
    },
  },
}
</script>
