<template>
  <div class="bg-white w-full p-8 flex flex-col justify-center items-center">
    <img :src="PayImage" alt="PayImage" />
    <p class="text-center mt-4 text-xs leading-5 font-semibold text-gray">
      {{ $t('You can pay for your stay and room orders.') }}
      {{ $t('Some facilities may not be available.') }}
    </p>
  </div>
  <RouterLinkControl
    componentName="registerCard"
    class="my-8 w-[260px] h-[44px] font-lato text-amber text-sm flex justify-center items-center border border-amber rounded-full"
  >
    {{ $t('Add a card') }}
  </RouterLinkControl>
</template>

<script>
import PayImage from '@/assets/images/pay_illustration.svg'
import RouterLinkControl from '@/components/controls/RouterLinkControl'
export default {
  name: 'NoCard',
  components: {
    RouterLinkControl,
  },
  data() {
    return {
      PayImage: PayImage,
    }
  },
}
</script>
