<template>
  <StaticLayout>
    <EnTermsOfUseContent v-if="$i18next.language === 'en'" />
    <JpTermsOfUseContent v-else />
  </StaticLayout>
</template>

<script>
import StaticLayout from '@/components/layouts/StaticLayout'
import EnTermsOfUseContent from '@/components/blocks/static/termsOfUse/EnTermsOfUseContent'
import JpTermsOfUseContent from '@/components/blocks/static/termsOfUse/JpTermsOfUseContent'

export default {
  name: 'TermsOfUseView',
  components: {
    StaticLayout,
    EnTermsOfUseContent,
    JpTermsOfUseContent,
  },
}
</script>
