export const REDIRECTS_PAGES = {
  CART_ORDER_CONFIRMATION: 'cartOrderConfirmationView',
  LOGIN: 'login',
  CHECK_IN: 'checkIn',
  CHECKIN_EDIT: 'checkinEdit',
  CHECKIN_CONFIRM: 'checkinConfirm',
  CONFIRM_RESERVATIONS_LIST: 'confirmReservationsList',
  CHECKIN_PAYMENT: 'checkinPayment',
  CHECKOUT_PAYMENT: 'checkoutPayment',
  CHECKIN_CUSTOM_FORM: 'checkinCustomForm',
}
