<script>
import { Field, ErrorMessage } from 'vee-validate'

import FieldMixin from '@/components/UI/formFields/mixins/FieldMixin.vue'

export default {
  mixins: [FieldMixin],
  components: { Field, ErrorMessage },
  props: {
    options: { type: Array, required: true },
    itemText: { type: String, default: 'label' },
    itemValue: { type: String, default: 'value' },
    inputAttrs: Object,
    placeholder: String,
    emptyItemTitle: String,
    content: String,
  },
  computed: {
    is_strings_options() {
      return this.options.some(v => typeof v === 'string')
    },
  },
}
</script>

<template>
  <div class="w-full flex flex-col mb-6">
    <label v-if="label" :for="name" class="mb-2 text-small font-semibold">
      {{ label }}
      <span v-if="is_required" class="text-[#272727]">*</span>
    </label>
    <div v-if="content" class="mt-4 mb-2 text-sm leading-6" v-html="content" />
    <div class="flex flex-wrap items-stretch w-full relative">
      <Field
        v-slot="{ value }"
        v-bind="{ ...$attrs, ...inputAttrs }"
        :class="{
          'form-select': !inputAttrs?.multiple,
          'form-multiselect': inputAttrs?.multiple,
        }"
        :name="name"
        :rules="rules"
        :placeholder="placeholder"
        :disabled="disabled"
        as="select"
        class="w-full h-[44px] pr-9 pl-6 border focus:outline-none border-slate-300 rounded-full touch-none appearance-none"
      >
        <option v-if="emptyItemTitle" disabled value="">
          {{ emptyItemTitle }}
        </option>
        <option
          v-for="item in options"
          :key="is_strings_options ? item : item[itemValue]"
          :value="is_strings_options ? item : item[itemValue]"
          :selected="
            value === item ||
            (value?.includes && value.includes(is_strings_options ? item : item[itemValue]))
          "
        >
          {{ is_strings_options ? item : item[itemText] }}
        </option>
      </Field>
      <span
        class="w-[15px] h-[15px] text-xs absolute flex justify-center align-center top-[14px] right-[16px] text-amber cursor-pointer"
        >&#x25BC;</span
      >
    </div>
    <ErrorMessage class="mt-2 font-semibold text-red-500" :name="name" />
  </div>
</template>
