<template>
  <MainLayout hideHeader withoutContainer>
    <SpinnerLoader v-if="loading" />
    <div v-else>
      <MainHeadBlock :bgImage="hotel_info.extImageId" />
      <div class="p-10 mt-[-120px] relative rounded-t-[20px] z-40 bg-white">
        <MainTitleBlock :logo="hotel_info.logoImageId" :title="hotel_name" />
        <MainNavigationBlock
          :iconsDisplayedList="iconsDisplayedList"
          @open-modal="isModalShow = true"
        />
        <CouponsListBlock v-if="has_coupons_plugin" />
        <TravelGuideBlock :activityData="hotelGuideData.facilityBasicInfo" />
      </div>
    </div>
    <CallToFrontModal
      :is-show="isModalShow"
      :modal-data="hotelGuideData.facilityBasicInfo"
      @close-modal="this.isModalShow = false"
    />
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import TravelGuideBlock from '@/components/blocks/main/TravelGuideBlock'
import MainNavigationBlock from '@/components/blocks/main/MainNavigationBlock'
import MainTitleBlock from '@/components/blocks/main/MainTitleBlock'
import MainHeadBlock from '@/components/blocks/main/MainHeadBlock'
import SpinnerLoader from '@/components/blocks/loader/Loader'
import CallToFrontModal from '@/components/blocks/main/CallToFrontModal'
import CouponsListBlock from '@/components/blocks/coupons/CouponsListBlock'

export default {
  name: 'HomeView',
  components: {
    MainLayout,
    MainHeadBlock,
    SpinnerLoader,
    MainTitleBlock,
    MainNavigationBlock,
    TravelGuideBlock,
    CallToFrontModal,
    CouponsListBlock,
  },
  data() {
    return {
      loading: false,
      isModalShow: false,
    }
  },
  computed: {
    ...mapGetters(['iconsDisplayedList', 'hotelGuideData']),
    hotel_info() {
      return this.hotelGuideData?.facilityBasicInfo || {}
    },
    hotel_name() {
      return this.$i18next.isJapanese() ? this.hotel_info.hotelName : this.hotel_info.hotelNameLatin
    },
    has_coupons_plugin() {
      return this.hotelGuideData?.plugins?.find(element => element.code === 'CU')?.isEnabled
    },
  },
  async mounted() {
    try {
      this.loading = true

      if (!this.iconsDisplayedList) await this.getDisplayedIconsList()

      this.clearLoginRedirect()
    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions(['getDisplayedIconsList', 'globalErrorHandler', 'clearLoginRedirect']),
  },
}
</script>
