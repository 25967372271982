<template>
  <MainLayout :title="$t('Reset your password')" class="flex flex-col items-center">
    <Form
      v-slot="{ meta }"
      @submit="onSubmit"
      class="w-full flex flex-col justify-start items-center"
    >
      <div class="w-full max-w-[600px] p-8 bg-white flex flex-col justify-start items-center">
        <TextField
          v-model="formData.password"
          :label="$t('New Password')"
          :rules="combineFieldValidators([fieldValidators.required(), fieldValidators.password()])"
          name="password"
          type="password"
        />
        <p class="text-gray-500 text-sm mb-8 -mt-4">
          {{
            $t(
              '*Please enter at least 8 characters including lower case letters, upper case letters and numbers.'
            )
          }}
        </p>
        <TextField
          v-model="formData.repeatPassword"
          :label="$t('Re-enter new password')"
          :rules="
            combineFieldValidators([
              fieldValidators.required(),
              fieldValidators.password(),
              v => v === this.formData.password || $t('Passwords do not match'),
            ])
          "
          name="repeatPassword"
          type="password"
        />
      </div>
      <Button :loading="loading" :disabled="!meta.valid" type="submit">
        {{ $t('Resetting') }}
      </Button>
    </Form>
    <ResetPasswordConfirmModal :isShow="success" />
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'
import { Form } from 'vee-validate'

import MainLayout from '@/components/layouts/MainLayout'
import ResetPasswordConfirmModal from '@/components/blocks/auth/ResetPasswordConfirmModal'
import formValidatorsMixin from '@/mixins/formValidatorsMixin.vue'
import { capitalize } from '@/utils/text'

export default {
  name: 'ResetPasswordConfirmView',
  mixins: [formValidatorsMixin],
  components: {
    MainLayout,
    ResetPasswordConfirmModal,
    Form,
  },
  data() {
    return {
      loading: false,
      success: false,
      formData: {
        password: '',
        repeatPassword: '',
      },
    }
  },
  computed: {
    token() {
      return this.$route.params.token
    },
    email() {
      return this.$route.query.email
    },
  },
  methods: {
    capitalize,
    ...mapActions(['changePasswordConfirm', 'globalErrorHandler']),
    async onSubmit() {
      try {
        this.loading = true

        await this.changePasswordConfirm({
          token: this.token,
          email: this.email,
          password: this.formData.password,
          password_confirmation: this.formData.repeatPassword,
        })

        this.success = true
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
