<template>
  <RouterLinkControl
    componentName="memberInfoView"
    class="w-full max-w-[600px] bg-white px-8 py-4 flex justify-start items-center mb-2"
  >
    <img :src="icons.account" alt="icon" />
    <p class="ml-5 text-[14px] font-semibold">
      {{ $t('Member Information') }}
    </p>
  </RouterLinkControl>

  <RouterLinkControl
    componentName="CheckInListView"
    class="w-full max-w-[600px] bg-white px-8 py-4 flex justify-start items-center mb-2"
  >
    <img :src="icons.checkin_icon" alt="icon" />
    <p class="ml-5 text-[14px] font-semibold">
      {{ $t('Check-in Information') }}
    </p>
  </RouterLinkControl>

  <RouterLinkControl
    componentName="creditCard"
    class="w-full max-w-[600px] bg-white px-8 py-4 flex justify-start items-center mb-2"
  >
    <img :src="icons.card_icon" alt="icon" />
    <p class="ml-5 text-[14px] font-semibold">{{ $t('Credit Card') }}</p>
  </RouterLinkControl>

  <RouterLinkControl
    componentName="invoice"
    class="w-full max-w-[600px] bg-white px-8 py-4 flex justify-start items-center mb-6"
  >
    <img :src="icons.invoice_icon" alt="icon" />
    <p class="ml-5 text-[14px] font-semibold">{{ $t('Invoice') }}</p>
  </RouterLinkControl>

  <RouterLinkControl
    link="https://aipass.zendesk.com/hc/ja/categories/900001310303-%E3%81%94%E5%AE%BF%E6%B3%8A%E3%81%AE%E6%96%B9%E3%81%B8"
    target="_blank"
    class="w-full max-w-[600px] bg-white px-8 py-4 flex justify-start items-center mb-2"
  >
    <img :src="icons.help_icon" alt="icon" />
    <p class="ml-5 text-[14px] font-semibold">{{ $t('System help') }}</p>
  </RouterLinkControl>

  <RouterLinkControl
    link="https://aipass.inc/"
    target="_blank"
    class="w-full max-w-[600px] bg-white px-8 py-4 flex justify-start items-center mb-2"
  >
    <img :src="icons.company_icon" alt="icon" />
    <p class="ml-5 text-[14px] font-semibold">{{ $t('Service Provider') }}</p>
  </RouterLinkControl>

  <RouterLinkControl
    componentName="termsOfUse"
    target="_blank"
    class="w-full max-w-[600px] bg-white px-8 py-4 flex justify-start items-center mb-2"
  >
    <img :src="icons.termsOfUse_icon" alt="icon" />
    <p class="ml-5 text-[14px] font-semibold">{{ $t('Terms of use') }}</p>
  </RouterLinkControl>

  <RouterLinkControl
    componentName="PrivacyPolicyView"
    target="_blank"
    class="w-full max-w-[600px] bg-white px-8 py-4 flex justify-start items-center mb-2"
  >
    <img :src="icons.privacyPolicy_icon" alt="icon" />
    <p class="ml-5 text-[14px] font-semibold">{{ $t('Privacy policy') }}</p>
  </RouterLinkControl>

  <RouterLinkControl
    componentName="SecurityPolicyView"
    target="_blank"
    class="w-full max-w-[600px] bg-white px-8 py-4 flex justify-start items-center mb-2"
  >
    <img :src="icons.security_icon" alt="icon" />
    <p class="ml-5 text-[14px] font-semibold">{{ $t('Security policy') }}</p>
  </RouterLinkControl>
</template>

<script>
import account from '@/assets/images/navbar/account_icon.svg'
import checkin_icon from '@/assets/images/navbar/checkin_icon.svg'
import card_icon from '@/assets/images/navbar/card_icon.svg'
import invoice_icon from '@/assets/images/navbar/invoice_icon.svg'
import help_icon from '@/assets/images/navbar/help_icon.svg'
import company_icon from '@/assets/images/navbar/company_icon.svg'
import termsOfUse_icon from '@/assets/images/navbar/terms-of-use_icon.svg'
import privacyPolicy_icon from '@/assets/images/navbar/privacy-policy_icon.svg'
import security_icon from '@/assets/images/navbar/security_icon.svg'

import RouterLinkControl from '@/components/controls/RouterLinkControl'
export default {
  name: 'MyPageNavigationBlock',
  components: {
    RouterLinkControl,
  },
  data() {
    return {
      icons: {
        account: account,
        checkin_icon: checkin_icon,
        card_icon: card_icon,
        invoice_icon: invoice_icon,
        help_icon: help_icon,
        company_icon: company_icon,
        termsOfUse_icon: termsOfUse_icon,
        privacyPolicy_icon: privacyPolicy_icon,
        security_icon: security_icon,
      },
    }
  },
}
</script>
