<template>
  <Modal
    v-model="show_modal"
    @click-outside="$emit('close')"
    content-class="w-full max-w-[320px] flex flex-col bg-white rounded overflow-hidden max-h-[calc(100vh-64px)] rounded-xl"
  >
    <div v-if="product" class="max-h-[calc(100vh-64px)] flex flex-col">
      <div class="w-full h-[180px] bg-gray-300">
        <img
          v-if="product.imagePath"
          :src="product.imagePath"
          :alt="$i18next.getValueByLanguage(product, 'name')"
          :title="$i18next.getValueByLanguage(product, 'name')"
          class="object-cover w-full h-full"
        />
      </div>
      <div class="overflow-y-auto">
        <div class="p-4 flex-1 flex flex-col border-b border-gray-super-light">
          <div class="font-bold">
            {{ $i18next.getValueByLanguage(product, 'name').substr(0, 34) }}
          </div>
          <div class="text-gray text-sm font-bold">¥{{ product.price.toLocaleString() }}</div>
          <div class="flex flex-wrap mt-3 -mx-2">
            <div
              v-for="tag of product.tags"
              :key="tag.id"
              class="px-2 py-1 rounded-full border border-gray-light mx-1 my-0.5 text-xs text-gray font-bold"
            >
              {{ $i18next.getValueByLanguage(tag, 'name') }}
            </div>
          </div>
        </div>
        <div class="p-4 flex text-sm">
          {{ $i18next.getValueByLanguage(product, 'description') }}
        </div>
        <div class="p-4 flex">
          <QuantityCounter v-model="formData.count" :max-count="product.stock" class="mx-auto" />
        </div>
      </div>
      <div class="p-4 flex items-center justify-between bg-gray-super-light">
        <Button @click="onCancel" mode="outline" class="w-full px-2 mr-1 mt-0 min-w-0 font-bold">
          {{ $t('Cancel') }}
        </Button>
        <Button @click="onSave" class="w-full px-2 ml-1 mt-0 min-w-0 font-bold">
          {{ $t('Add to cart') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/UI/Modal'
import QuantityCounter from '@/components/UI/QuantityCounter'

export default {
  components: { Modal, QuantityCounter },
  props: {
    product: Object,
    count: Number,
  },
  data() {
    return {
      formData: {
        count: 1,
      },
    }
  },
  computed: {
    show_modal: {
      get() {
        return !!this.product
      },
      set() {
        if (!this.product) this.$emit('close')
      },
    },
  },
  watch: {
    product() {
      this.formData.count = this.count || 1
    },
  },
  methods: {
    onCancel() {
      this.$emit('close')
    },
    onSave() {
      this.$emit('change', { product: this.product, count: this.formData.count })
      this.$emit('close')
    },
  },
}
</script>
