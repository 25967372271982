<template>
  <RouterLinkControl v-if="component" :componentName="component" class="my-4 basis-1/2">
    <div
      class="h-[60px] w-[60px] mx-auto rounded-full bg-gray-200 flex flex-column items-center justify-center"
    >
      <img :src="icons[icon]" class="w-[28px] h-[28px]" />
    </div>
    <p class="mt-2 font-lato text-xs font-bold text-center text-gray">
      {{ title }}
    </p>
  </RouterLinkControl>
  <div v-else class="my-4 basis-1/2 cursor-pointer" @click="$emit('open-modal')">
    <div
      class="h-[60px] w-[60px] mx-auto rounded-full bg-gray-200 flex flex-column items-center justify-center"
    >
      <img :src="icons[icon]" class="w-[28px] h-[28px]" />
    </div>
    <p class="mt-2 font-lato text-xs font-bold text-center text-gray">
      {{ title }}
    </p>
  </div>
</template>

<script>
import check_in from '@/assets/images/navbar/checkin_icon.svg'
import account from '@/assets/images/navbar/account_icon.svg'
import call from '@/assets/images/navbar/call_icon.svg'
import checkout from '@/assets/images/navbar/checkout_icon.svg'
import info from '@/assets/images/navbar/info_icon.svg'
import request from '@/assets/images/navbar/request_icon.svg'
import wifi from '@/assets/images/navbar/wifi_icon.svg'
import RouterLinkControl from '@/components/controls/RouterLinkControl'
import icon_map from '@/assets/images/navbar/icon_map.svg'
export default {
  name: 'NavButtonControl',
  components: {
    RouterLinkControl,
  },
  props: {
    icon: {
      type: String,
      default: 'accountIcon',
    },
    title: {
      type: String,
      default: '',
    },
    component: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      icons: {
        checkInIcon: check_in,
        accountIcon: account,
        callIcon: call,
        checkoutIcon: checkout,
        infoIcon: info,
        mapIcon: icon_map,
        requestIcon: request,
        wifiIcon: wifi,
      },
    }
  },
}
</script>
