<template>
  <StaticLayout>
    <EnPrivacyPolicyContent v-if="$i18next.language === 'en'" />
    <JpPrivacyPolicyContent v-else />
  </StaticLayout>
</template>

<script>
import StaticLayout from '@/components/layouts/StaticLayout'
import EnPrivacyPolicyContent from '@/components/blocks/static/privacyPolicy/EnPrivacyPolicyContent'
import JpPrivacyPolicyContent from '@/components/blocks/static/privacyPolicy/JpPrivacyPolicyContent'

export default {
  name: 'PrivacyPolicyView',
  components: {
    StaticLayout,
    EnPrivacyPolicyContent,
    JpPrivacyPolicyContent,
  },
}
</script>
