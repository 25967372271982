<template>
  <MainLayout :title="$t('Reset your password')" class="flex flex-col items-center">
    <Form
      v-slot="{ meta }"
      @submit="onSubmitMailCheck"
      class="w-full flex flex-col justify-start items-center"
    >
      <div class="w-full max-w-[600px] p-8 bg-white flex flex-col justify-start items-center">
        <TextField
          v-model="formData.email"
          :placeholder="capitalize($t('email address'))"
          :label="$t('Email')"
          :rules="
            combineFieldValidators([fieldValidators.required(), this.fieldValidators.email()])
          "
          name="email"
          type="email"
          spellcheck="false"
          autocomplete="email"
          autocorrect="off"
          autocapitalize="off"
        />
        <p class="text-sm text-slate-600 leading-relaxed mb-4">
          {{ $t('Please enter your e-mail address that you registered.') }}
        </p>
      </div>
      <Button :loading="loading" :disabled="!meta.valid" type="submit">{{ $t('Next') }}</Button>
    </Form>
    <ResetPasswordModal
      :isShow="showSuccessResetPasswordModal"
      :email="formData.email"
      :mailStatus="mailStatus"
      @close-modal="
        () => {
          showSuccessResetPasswordModal = false
          $router.replace({ name: 'emailLogin', query: { email } })
        }
      "
    />
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'
import { Form } from 'vee-validate'

import MainLayout from '@/components/layouts/MainLayout'
import ResetPasswordModal from '@/components/blocks/auth/ResetPasswordModal'
import formValidatorsMixin from '@/mixins/formValidatorsMixin.vue'
import { capitalize } from '@/utils/text'

export default {
  name: 'ResetPasswordView',
  mixins: [formValidatorsMixin],
  components: {
    MainLayout,
    ResetPasswordModal,
    Form,
  },
  data() {
    return {
      loading: false,
      showSuccessResetPasswordModal: false,
      mailStatus: null,
      formData: {
        email: '',
        password: '',
      },
    }
  },
  mounted() {
    if (this.$route.query.email) {
      this.formData.email = this.$route.query.email
    }
  },
  methods: {
    capitalize,
    ...mapActions(['forgotPassword', 'signIn', 'globalErrorHandler', 'checkEmail']),
    async onSubmitMailCheck() {
      try {
        this.loading = true

        await this.forgotPassword({
          email: this.formData.email,
          hotelId: this.$route.query.hotelId,
          lang: this.$route.query.lang,
        })

        const mailStatus = await this.checkEmail(this.formData.email)
        this.mailStatus = mailStatus

        this.showSuccessResetPasswordModal = true
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
