<template>
  <MainLayout :title="$t('Information')" :loading="loading" backRoute="home">
    <router-link
      v-if="isActiveLink('10')"
      :to="{ name: 'checkinOutInfo' }"
      class="w-full max-w-[600px] bg-white px-8 py-4 mb-2 flex justify-start items-center"
    >
      <img :src="require('@/assets/images/navbar/icon_inout.svg')" alt="icon" />
      <p class="ml-5 text-[14px] font-semibold">{{ $t('Check-in / Check-out') }}</p>
    </router-link>
    <router-link
      v-if="isActiveLink('3')"
      :to="{ name: 'wifiInfo' }"
      class="w-full max-w-[600px] bg-white px-8 py-4 mb-2 flex justify-start items-center"
    >
      <img :src="require('@/assets/images/navbar/wifi_icon.svg')" alt="icon" />
      <p class="ml-5 text-[14px] font-semibold">{{ $t('Wi-Fi') }}</p>
    </router-link>
    <router-link
      v-if="isActiveLink('7')"
      :to="{ name: 'foodInfo' }"
      class="w-full max-w-[600px] bg-white px-8 py-4 mb-2 flex justify-start items-center"
    >
      <img :src="require('@/assets/images/navbar/icon_eat.svg')" alt="icon" />
      <p class="ml-5 text-[14px] font-semibold">
        {{ $t('Breakfast and dinner venue') }}
      </p>
    </router-link>
    <router-link
      v-if="isActiveLink('8')"
      :to="{ name: 'bathInfo' }"
      class="w-full max-w-[600px] bg-white px-8 py-4 mb-2 flex justify-start items-center"
    >
      <img :src="require('@/assets/images/navbar/info_bath.svg')" alt="icon" />
      <p class="ml-5 text-[14px] font-semibold">{{ $t('Public bath') }}</p>
    </router-link>
    <router-link
      v-if="isActiveLink('9')"
      :to="{ name: 'facilityInfo' }"
      class="w-full max-w-[600px] bg-white px-8 py-4 mb-2 flex justify-start items-center"
    >
      <img :src="require('@/assets/images/navbar/info_facility.svg')" alt="icon" />
      <p class="ml-5 text-[14px] font-semibold">{{ $t('Facility') }}</p>
    </router-link>
    <router-link
      v-if="isActiveLink('5')"
      :to="{ name: 'floorGuide' }"
      class="w-full max-w-[600px] bg-white px-8 py-4 mb-2 flex justify-start items-center"
    >
      <img :src="require('@/assets/images/navbar/info_floor.svg')" alt="icon" />
      <p class="ml-5 text-[14px] font-semibold">{{ $t('Floor Guide') }}</p>
    </router-link>
    <router-link
      v-if="is_active_address"
      :to="{ name: 'address' }"
      class="w-full max-w-[600px] bg-white px-8 py-4 mb-2 flex justify-start items-center"
    >
      <img :src="require('@/assets/images/navbar/icon_access.svg')" alt="icon" />
      <p class="ml-5 text-[14px] font-semibold">{{ $t('Access') }}</p>
    </router-link>
    <router-link
      v-if="isActiveLink('4')"
      :to="{ name: 'notices' }"
      class="w-full max-w-[600px] bg-white px-8 py-4 mb-2 flex justify-start items-center"
    >
      <img :src="require('@/assets/images/navbar/info_notice.svg')" alt="icon" />
      <p class="ml-5 text-[14px] font-semibold">{{ $t('Notice') }}</p>
    </router-link>
    <router-link
      v-if="isActiveLink('6')"
      :to="{ name: 'hotelTermsOfUse' }"
      class="w-full max-w-[600px] bg-white px-8 py-4 mb-2 flex justify-start items-center"
    >
      <img :src="require('@/assets/images/navbar/info_agreement.svg')" alt="icon" />
      <p class="ml-5 text-[14px] font-semibold">
        {{ $t('Terms and Conditions') }}
      </p>
    </router-link>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'

export default {
  name: 'InfoMenuView',
  components: {
    MainLayout,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters(['facilityHotelInfo', 'hotelGuideData']),
    is_active_address() {
      return this.hotelGuideData?.facilityAccessInfo?.viewStatus
    },
  },
  async mounted() {
    try {
      this.loading = true
      if (!this.facilityHotelInfo) await this.getFacilityInfo()
    } catch (error) {
      this.globalErrorHandler({ error })
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions(['getFacilityInfo', 'globalErrorHandler']),
    isActiveLink(id) {
      return (
        this.facilityHotelInfo?.facilityAdditionalPageInfo?.find(i => i.page_index === id)
          ?.view_status === 1
      )
    },
  },
}
</script>
