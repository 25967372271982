<template>
  <StaticLayout>
    <EnSecurityPolicyContent v-if="$i18next.language === 'en'" />
    <JpSecurityPolicyContent v-else />
  </StaticLayout>
</template>

<script>
import StaticLayout from '@/components/layouts/StaticLayout'
import EnSecurityPolicyContent from '@/components/blocks/static/securityPolicy/EnSecurityPolicyContent'
import JpSecurityPolicyContent from '@/components/blocks/static/securityPolicy/JpSecurityPolicyContent'

export default {
  name: 'SecurityPolicyView',
  components: {
    StaticLayout,
    EnSecurityPolicyContent,
    JpSecurityPolicyContent,
  },
}
</script>
\
