<script>
import { Field, ErrorMessage } from 'vee-validate'

import FieldMixin from '@/components/UI/formFields/mixins/FieldMixin.vue'

export default {
  mixins: [FieldMixin],
  components: { Field, ErrorMessage },
  props: {
    modelValue: { type: [Date, String], required: false },
  },
}
</script>

<template>
  <div class="flex w-full mb-4">
    <Field
      v-bind="$attrs"
      :name="name"
      :rules="rules"
      :value="true"
      :disabled="disabled"
      :checked="modelValue"
      class="form-group mr-2 accent-[#cb860e] checked:border-white h-4 w-4 mt-[2px]"
      type="checkbox"
    />
    <label class="block text-gray-700 text-sm font-bold" :for="name">
      <slot name="label" />
      <span v-if="label" class="text-sm">
        {{ label }}
        <span v-if="is_required" class="text-[#272727]">*</span>
      </span>
    </label>
  </div>
  <ErrorMessage class="mt-2 font-semibold text-red-500" :name="name" />
</template>
