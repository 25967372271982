<template>
  <MainLayout :title="$t('My Page')" backRoute="home">
    <UserInfo />
    <MyPageNavigationBlock />
    <Button @click="onLogout" class="mt-8 mb-5" mode="text">
      {{ $t('Sign Out') }}
    </Button>
  </MainLayout>
</template>

<script>
import { mapActions } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import UserInfo from '@/components/blocks/myPage/UserInfoBlock'
import MyPageNavigationBlock from '@/components/blocks/myPage/MyPageNavigationBlock'

export default {
  name: 'MyPageView',
  components: {
    MainLayout,
    UserInfo,
    MyPageNavigationBlock,
  },
  methods: {
    ...mapActions(['logout']),
    onLogout() {
      this.logout()
    },
  },
}
</script>
