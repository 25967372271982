<template>
  <a class="inline-block" v-if="link" :href="link" :target="target">
    <slot></slot>
  </a>
  <router-link v-else-if="componentName" :to="router_params" :target="target">
    <slot></slot>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RouterLinkControl',
  props: {
    title: {
      type: String,
      default: '',
    },
    componentName: {
      type: String,
      required: false,
    },
    params: {
      type: Object,
      required: false,
    },
    queryParams: {
      type: Object,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  computed: {
    ...mapGetters(['hotelGuideData']),
    router_params() {
      return {
        name: this.componentName,
        params: this.params,
        query: this.queryParams,
      }
    },
  },
}
</script>

<style scoped></style>
