import axios from 'axios'
import { useMockUrls } from '@/api/mock'

axios.defaults.headers.post['Content-Type'] = 'application/json'

export const baseURL = process.env.VUE_APP_BASE_URL

const api = axios.create({ baseURL })

if (process.env.VUE_APP_ENV === 'development' || process.env.VUE_APP_ENV === 'local') {
  const MockAdapter = require('axios-mock-adapter')
  const mock = new MockAdapter(api, { delayResponse: 2000, onNoMatch: 'passthrough' })

  useMockUrls(mock)
}

api.interceptors.request.use(
  function (config) {
    const token = localStorage?.getItem('access_token') || ''
    if (token) config.headers['Authorization'] = token

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default api
