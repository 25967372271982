<template>
  <MainLayout
    :title="$t('Select your reservation')"
    :loading="loading"
    backRoute="checkIn"
    class="flex flex-col items-center p-4"
  >
    <div class="w-full flex flex-col justify-center items-center">
      <ReservationCard
        v-for="reservation of searchReservations"
        :key="reservation.id"
        :reservation="reservation"
        :selected="selectedReservations.includes(reservation)"
        @onSelect="onSelectReservation"
      />
      <Button
        v-if="searchReservations?.length"
        @click="onSubmit"
        :disabled="is_disabled"
        :loading="loadingAction"
        class="mt-4"
      >
        {{ $t('Next') }}
      </Button>
      <RouterLinkControl
        componentName="reservationSearch"
        class="mt-4 w-[260px] h-[44px] font-lato text-amber text-sm flex justify-center items-center border border-amber rounded-full mb-6"
      >
        {{ $t('Search your reservation') }}
      </RouterLinkControl>
    </div>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import RouterLinkControl from '@/components/controls/RouterLinkControl'
import ReservationCard from '@/components/blocks/ReservationCard'
import Button from '@/components/UI/Button.vue'
import { capitalize } from '@/utils/text'
import { REDIRECTS_PAGES } from '@/data/pagesList'

export default {
  name: 'ChecinReservationsListView',
  components: {
    MainLayout,
    RouterLinkControl,
    ReservationCard,
    Button,
  },
  data() {
    return {
      loading: false,
      loadingAction: false,
      payments: undefined,
      isGuidanceDisplay: undefined,
      selectedReservations: [],
      paymentSetting: undefined,
    }
  },
  computed: {
    ...mapGetters([
      'searchReservations',
      'checkinData',
      'hotelGuideData',
      'customCheckinFields',
      'hasPaymentPlugin',
      'checkinPaymentSetting',
    ]),
    is_disabled() {
      return !this.selectedReservations.length
    },
    next_page_url() {
      if (Number(sessionStorage.getItem('isGuidanceDisplay'))) {
        return REDIRECTS_PAGES.CHECKIN_CUSTOM_FORM
      }
      if (
        this.hasPaymentPlugin &&
        this.payments?.totalAmount > 0 &&
        this.checkinPaymentSetting?.length
      ) {
        return REDIRECTS_PAGES.CHECKIN_PAYMENT
      }
      return REDIRECTS_PAGES.CHECKIN_CONFIRM
    },
  },
  async mounted() {
    try {
      this.loading = true
      const { hotelId, lang } = this.$route.query

      if (!this.checkinData) return this.$router.replace({ name: 'checkIn' })

      const { reservationTel, reservationId, reservationGivenName, reservationFamilyName } =
        this.checkinData

      if (!this.searchReservations) {
        await this.searchCheckin({
          hotelId,
          lang,
          reservationTel,
          reservationId,
          reservationGivenName,
          reservationFamilyName,
        })
      }
      this.paymentSetting = await this.getPaymentSetting()
    } catch (error) {
      if (error.response?.status === 404) {
        this.globalErrorHandler(
          this.$t('There is no reservation information. Check it again please.')
        )
      } else this.globalErrorHandler({ error })
      this.$router.push({ name: 'checkIn' })
    } finally {
      this.loading = false
    }
  },
  methods: {
    capitalize,
    ...mapActions([
      'searchCheckin',
      'setCheckin',
      'updateCheckinData',
      'globalErrorHandler',
      'getCheckinPayments',
      'getCustomCheckinFields',
      'getPaymentSetting',
    ]),
    onSelectReservation(reservation) {
      const reservationIndex = this.selectedReservations.findIndex(item => reservation === item)

      if (reservationIndex !== -1) {
        this.selectedReservations.splice(reservationIndex, 1)
      } else {
        this.selectedReservations.push(reservation)
      }
    },
    async onSubmit() {
      try {
        this.loadingAction = true

        const reservationIds = this.selectedReservations.map(i => i.reservationId)

        this.payments = await this.getCheckinPayments({
          reservationIds: reservationIds,
        })

        sessionStorage.setItem('selectedReservation', JSON.stringify(this.selectedReservations[0]))

        this.updateCheckinData({ reservationIds, id: this.selectedReservations[0].id })

        await this.setCheckin({ reservationIds })

        this.$router.push({ name: this.next_page_url })
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loadingAction = false
      }
    },
  },
}
</script>
