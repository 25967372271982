import api from '../api'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getAroundSpotList(_, { hotelId, category }) {
    return api.get('around-spot', { params: { hotelId, category } }).then(res => res.data?.aroundSpots || [])
  },
  getAroundSpotDetail(_, { hotelId, placeId, lang = 'ja' }) {
    return api.get('around-spot-detail', { params: { hotelId, placeId, lang } }).then(res => res.data)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
