<template>
  <MainLayout
    :title="$t('Credit Card Registration')"
    backRoute="myPage"
    class="flex flex-column items-center"
  >
    <Form
      v-slot="{ meta }"
      @submit="onSubmit"
      class="w-full max-w-[600px] bg-white p-8 flex flex-col items-center"
    >
      <CardForm v-model="formData" />
      <Button :disabled="!meta.valid" :loading="loadingAction" type="submit">
        {{ $t('Save') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'

import MainLayout from '@/components/layouts/MainLayout'
import formValidatorsMixin from '@/mixins/formValidatorsMixin'
import CardForm from '@/components/blocks/CardForm'

export default {
  name: 'RegisterCardView',
  mixins: [formValidatorsMixin],
  components: {
    Form,
    MainLayout,
    CardForm,
  },
  data() {
    return {
      loadingAction: false,
      formData: undefined,
    }
  },
  computed: {
    ...mapGetters(['userData']),
  },
  methods: {
    ...mapActions(['addCreditCard', 'globalErrorHandler']),
    async onSubmit() {
      try {
        this.loadingAction = true

        const processResult = await this.addCreditCard({
          cuicinUserId: this.userData.id,
          cardNumber: this.formData.cardNumber.replace(/ /g, ''),
          cardExpire: this.formData.cardExpire,
          securityCode: this.formData.securityCode,
        })

        if (processResult?.status === 'success') {
          this.$router.push({ name: 'creditCard' })
        } else {
          this.globalErrorHandler({
            error:
              processResult.errorMessage ||
              this.$t('Cannot register credit card. Please check it again.'),
          })
        }
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loadingAction = false
      }
    },
  },
}
</script>
