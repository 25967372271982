<template>
  <MainLayout
    :title="$t('Edit registration information')"
    :backRoute="user_data_valid ? 'checkinConfirm' : 'checkinCustomForm'"
  >
    <Form
      v-slot="{ meta }"
      @submit="onSubmit"
      class="w-full flex flex-col justify-start items-center mb-8"
    >
      <!--Accommodation information-->
      <div
        class="w-full max-w-[600px] p-8 mb-4 bg-white flex flex-col justify-start items-start border-b border-gray-super-light"
      >
        <p class="w-full text-center font-semibold text-lg mb-4">
          {{ $t('Accommodation information') }}
        </p>
        <!--checkin date-->
        <SelectField
          v-model="checkinDataFields.checkinDate"
          :rules="fieldValidators.required()"
          :label="$t('Check-in Date')"
          :options="dates_list"
          name="checkinDate"
        />
        <!--checkin time-->
        <SelectField
          v-model="checkinDataFields.checkinTime"
          :rules="fieldValidators.required()"
          :label="$t('Check-in Time')"
          :options="time_list"
          name="checkinTime"
        />
        <!--number of guests-->
        <SelectField
          v-model="checkinDataFields.guests"
          :rules="fieldValidators.required()"
          :label="$t('Number of guests')"
          :options="guests_list"
          name="guests"
        />
        <!-- know contacts -->
        <div
          v-if="checkinDataFields.guests > 1"
          class="w-full mb-4 flex justify-start items-center"
        >
          <CheckField
            v-if="checkinDataFields.guests > 1"
            v-model="checkinDataFields.knowContacts"
            :label="$t('I know everyone’s contacts')"
            name="knowContacts"
          />
        </div>
        <!-- companions -->
        <div
          v-if="checkinDataFields.companions.length && !checkinDataFields.knowContacts"
          class="w-full flex flex-col mb-6"
        >
          <div
            v-for="(item, index) of checkinDataFields.companions"
            :key="index"
            class="flex -mx-2"
          >
            <template v-if="$i18next.language === 'ja'">
              <TextField
                v-model="item.surname"
                :placeholder="$t('Last name')"
                :rules="fieldValidators.required()"
                :name="`companions[${index}].surname`"
                class="min-w-xs mx-1"
              />
              <TextField
                v-model="item.name"
                :placeholder="$t('First name')"
                :rules="fieldValidators.required()"
                :name="`companions[${index}].name`"
                class="min-w-xs mx-1"
              />
            </template>
            <template v-else>
              <TextField
                v-model="item.name"
                :placeholder="$t('First name')"
                :rules="fieldValidators.required()"
                :name="`companions[${index}].name`"
                class="min-w-xs mx-1"
              />
              <TextField
                v-model="item.surname"
                :placeholder="$t('Last name')"
                :rules="fieldValidators.required()"
                :name="`companions[${index}].surname`"
                class="min-w-xs mx-1"
              />
            </template>
            <Button @click.prevent="handleDelete(index)" mode="icon" class="order-3 mt-2">
              &#10005;
            </Button>
          </div>
          <Button @click.prevent="handleAddGuest()" mode="text">
            &#43; {{ $t('Add entry field') }}
          </Button>
        </div>
        <!-- previous place-->
        <SelectField
          v-model="checkinDataFields.previousAccommodation"
          :rules="fieldValidators.required()"
          :label="$t('Previous Accommodation')"
          :options="[
            { value: 'Home', label: $t('Home') },
            { value: 'Other', label: $t('Other') },
          ]"
          name="previousAccommodation"
        />
        <TextField
          v-if="checkinDataFields.previousAccommodation === 'Other'"
          v-model="checkinDataFields.previousPlace"
          placeholder="Tokyo/Hotel name"
          name="previousPlace"
        />
        <!--next destination-->
        <SelectField
          v-model="checkinDataFields.nextAccommodation"
          :rules="fieldValidators.required()"
          :label="$t('Next destination')"
          :options="[
            { value: 'Home', label: $t('Home') },
            { value: 'Other', label: $t('Other') },
          ]"
          name="nextAccommodation"
        />
        <TextField
          v-if="checkinDataFields.nextAccommodation === 'Other'"
          v-model="checkinDataFields.nextPlace"
          placeholder="Tokyo/Hotel name"
          name="nextPlace"
        />
      </div>
      <!--Representative information-->
      <UserForm v-model="userFormData">
        <template #top>
          <p class="w-full text-center font-semibold text-lg mb-4">
            {{ $t('Representative information') }}
          </p>
        </template>
      </UserForm>
      <!--Health check-->
      <div class="w-full max-w-[600px] mb-4 bg-white flex flex-col justify-start items-start">
        <CustomCheckinForm v-model="customFields" />
      </div>
      <!--submit-->
      <Button :disabled="!meta.valid" :loading="loadingAction" type="submit">
        {{ $t('Confirm') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'
import moment from 'moment'

import MainLayout from '@/components/layouts/MainLayout'
import UserForm from '@/components/blocks/UserForm.vue'
import CustomCheckinForm from '@/components/blocks/CustomCheckinForm'
import formValidatorsMixin from '@/mixins/formValidatorsMixin'
import { checkUserDataValid } from '@/utils/validation'
import { capitalize } from '@/utils/text'
import { getHoursTimeRange } from '@/utils/time'
import { REDIRECTS_PAGES } from '@/data/pagesList'

export default {
  name: 'CheckinEditReservationView',
  mixins: [formValidatorsMixin],
  components: {
    MainLayout,
    Form,
    UserForm,
    CustomCheckinForm,
  },
  data() {
    return {
      loadingAction: false,
      customFields: [],
      userFormData: {},
      checkinDataFields: {
        //  checkin info
        checkinDate: '',
        checkinTime: '',
        guests: null,
        knowContacts: '',
        previousAccommodation: '',
        previousPlace: '',
        nextAccommodation: '',
        nextPlace: '',
        companions: [],
      },
    }
  },
  watch: {
    $route() {
      this.customFields = []
      this.initFieldsData()
    },
    'checkinDataFields.guests': {
      immediate: true,
      handler(data) {
        if (data > 1) {
          for (let i = 0; i < data; i++) {
            this.checkinDataFields.companions?.push({ name: '', surname: '' })
          }
          this.checkinDataFields.companions.length = data - 1
        } else {
          this.checkinDataFields.companions = []
        }
      },
    },
  },
  //TODO: move all duplicate methods in other components to mixins
  computed: {
    ...mapGetters(['hotelGuideData', 'customCheckinData', 'userData', 'checkinData']),
    hotel_info() {
      return this.hotelGuideData?.facilityBasicInfo || {}
    },
    dates_list() {
      const data = []
      for (let i = 0; i < 15; i++) {
        data.push({
          value: moment().add(i, 'days').format('YYYY/MM/DD'),
          label: moment().add(i, 'days').format('YYYY/MM/DD'),
        })
      }
      return data
    },
    time_list() {
      return getHoursTimeRange(this.hotel_info.checkinBeginTime, this.hotel_info.checkinEndTime)
    },
    guests_list() {
      let res = []
      Array.from({ length: 20 }, (i, index) => {
        res.push({
          value: (index + 1).toString(),
          label: (index + 1).toString(),
        })
      })
      return res
    },
    user_data_valid() {
      return checkUserDataValid(this.userData)
    },
  },
  async mounted() {
    if (!this.checkinData) {
      return this.$router.push({ name: REDIRECTS_PAGES.CHECK_IN })
    }
    if (!this.customCheckinData && Number(sessionStorage.getItem('isGuidanceDisplay'))) {
      return this.$router.push({ name: REDIRECTS_PAGES.CHECKIN_CUSTOM_FORM })
    }

    this.initFieldsData()
  },
  methods: {
    capitalize,
    ...mapActions([
      'updateUserInfo',
      'setCheckinData',
      'setCustomCheckinData',
      'globalErrorHandler',
    ]),
    customOptionsList(index) {
      const res = []
      this.customFields[index].fieldOptions.map(i => res.push({ value: i, label: i }))
      return res
    },
    handleAddGuest() {
      this.onFieldChange({ key: 'guests', data: this.checkinDataFields.guests + 1 })
    },
    handleDelete(index) {
      this.checkinDataFields.companions = this.checkinDataFields.companions.filter(
        (item, idx) => idx !== index
      )
      this.onFieldChange({ key: 'guests', data: this.checkinDataFields.guests - 1 })
    },
    initFieldsData() {
      this.checkinDataFields.checkinDate = this.checkinData.inDate
      this.checkinDataFields.checkinTime = this.checkinData.inTime
      this.checkinDataFields.guests = this.checkinData.totalMembers
      this.checkinDataFields.companions = this.checkinData.companionsList
      this.checkinDataFields.previousAccommodation = this.checkinData.previousAccommodation
      this.checkinDataFields.previousPlace = this.checkinData.previousPlace
        ? this.checkinData.previousPlace
        : ''
      this.checkinDataFields.nextAccommodation = this.checkinData.nextAccommodation
      this.checkinDataFields.nextPlace = this.checkinData.nextPlace
        ? this.checkinData.nextPlace
        : ''
    },
    onFieldChange(val) {
      this.checkinDataFields[val.key] = val.data
    },
    async onSubmit() {
      try {
        this.loadingAction = true

        const userDataPayload = {
          user: {
            ...this.userFormData,
            name:
              this.$i18next.language === 'ja'
                ? `${this.userFormData.surname} ${this.userFormData.name}`
                : `${this.userFormData.name} ${this.userFormData.surname}`,
            nameKana: `${this.userFormData.familyNameKana} ${this.userFormData.givenNameKana}`,
            telephone: this.userFormData.telephone.toString(),
          },
        }

        //checkin info
        const checkinData = this.checkinData

        checkinData.inDate = this.checkinDataFields.checkinDate
        checkinData.inTime = this.checkinDataFields.checkinTime
        checkinData.companionsList = this.checkinDataFields.companions
        checkinData.totalMembers = this.checkinDataFields.guests
        checkinData.previousAccommodation = this.checkinDataFields.previousAccommodation
        checkinData.nextAccommodation = this.checkinDataFields.nextAccommodation
        checkinData.previousPlace =
          this.checkinDataFields.previousAccommodation === 'Home'
            ? 'Home'
            : this.checkinDataFields.previousPlace
        checkinData.nextPlace =
          this.checkinDataFields.nextAccommodation === 'Home'
            ? 'Home'
            : this.checkinDataFields.nextPlace

        this.setCustomCheckinData(this.customFields)
        this.setCheckinData(checkinData)

        await this.updateUserInfo(userDataPayload)

        this.$router.push({ name: REDIRECTS_PAGES.CHECKIN_CONFIRM })
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loadingAction = false
      }
    },
  },
}
</script>
