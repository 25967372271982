<script>
import { format, isValid, isWithinRange, isAfter, isBefore, isSameDay } from 'date-fns'
import { useField } from 'vee-validate'
import { OnClickOutside } from '@vueuse/components'

import FieldMixin from '@/components/UI/formFields/mixins/FieldMixin.vue'
import DateSelect from '@/components/UI/DateSelect.vue'
import { parse } from '@/utils/date.js'

export default {
  mixins: [FieldMixin],
  components: { OnClickOutside, DateSelect },
  props: {
    allowedDates: { type: Array, required: false },
    maxDate: { type: [Date, String], required: false },
    minDate: { type: [Date, String], required: false },
    showDate: { type: [Date, String], required: false },
    mode: { type: String, required: false, default: 'date' },
    modelValue: { type: [Date, String], required: false },
    outputDateFormat: { type: String, default: 'YYYY-MM-DD' },
    withCalendar: { type: Boolean, default: true },
    placeholder: String,
    hint: String,
  },
  data() {
    const getLocalRules = () => {
      if (this.mode === 'time') {
        return [v => !v || this.checkValidTime(v) || this.$t('Please enter a valid time')]
      } else if (this.mode === 'datetime') {
        return [
          v =>
            !v ||
            (this.checkValidDateTime(v) && this.checkDatePeriod(v)) ||
            this.$t('Please enter a valid date and time'),
        ]
      } else {
        return [
          v => {
            return (
              !v ||
              (this.checkValidDate(v) && this.checkDatePeriod(v)) ||
              this.$t('Please enter a valid date')
            )
          },
        ]
      }
    }

    const {
      meta,
      errorMessage,
      handleChange,
      handleBlur,
      value: inputValue,
    } = useField(
      this.name,
      [...(Array.isArray(this.rules) ? [...this.rules] : [this.rules]), ...getLocalRules()],
      {
        initialValue: this.modelValue,
        syncVModel: false,
      }
    )

    return {
      meta,
      errorMessage,
      handleChange,
      handleBlur,
      inputValue,
      showCalendar: false,
      datepickerValue: undefined,
    }
  },
  created() {
    this.changeLocalByValue()
  },
  watch: {
    modelValue() {
      this.changeLocalByValue()
    },
    is_japanese() {
      if (!this.inputValue) return

      if (this.mode === 'date') {
        this.inputValue = format(parse(this.inputValue), this.date_format)
      }

      if (this.mode === 'datetime') {
        this.inputValue = format(parse(this.inputValue), this.date_format + ' HH:mm')
      }
    },
    inputValue() {
      if (!this.inputValue) {
        return this.$emit('update:modelValue', this.inputValue)
      }

      if (this.mode === 'time') {
        if (this.checkValidTime(this.inputValue)) {
          return this.$emit('update:modelValue', this.inputValue)
        }
      } else if (this.mode === 'datetime') {
        if (this.checkValidDateTime(this.inputValue)) {
          return this.$emit(
            'update:modelValue',
            format(parse(this.inputValue), this.outputDateFormat + ' HH:mm')
          )
        }
      } else {
        if (this.checkValidDate(this.inputValue)) {
          return this.$emit(
            'update:modelValue',
            format(parse(this.inputValue), this.outputDateFormat)
          )
        }
      }
    },
  },
  computed: {
    is_japanese() {
      return this.$i18next.isJapanese()
    },
    date_format() {
      return 'YYYY/MM/DD'
    },
    preview_format() {
      if (this.mode === 'time') return 'HH:mm'

      const withTime = this.mode === 'datetime' ? ' HH:mm' : ''

      return 'yyyy/MM/dd' + withTime
    },
    mask() {
      if (this.mode === 'time') return '##:##'
      else if (this.mode === 'datetime') {
        return '####/##/## ##:##'
      } else {
        return '####/##/##'
      }
    },
    field_placeholder() {
      if (this.placeholder) return this.placeholder
      if (this.mode === 'time') return 'HH:mm'
      if (this.mode === 'datetime') return 'YYYY/MM/DD HH:mm'
      return 'YYYY/MM/DD'
    },
    enable_time_picker() {
      return this.mode === 'time' || this.mode === 'datetime'
    },
    datepicker_value() {
      return parse(this.inputValue)
    },
    locale() {
      return this.is_japanese ? 'ja-JP' : undefined
    },
    maxlength() {
      if (this.mode === 'time') return 5
      if (this.mode === 'datetime') return 16
      return 10
    },
  },
  methods: {
    changeLocalByValue() {
      if (!this.modelValue) {
        const showDateValue = parse(this.showDate)

        if (isValid(showDateValue)) this.datepickerValue = this.showDate

        return
      }

      if (this.mode === 'time') {
        this.inputValue = this.modelValue
      } else {
        const dateValue = parse(this.modelValue)
        const withTime = this.mode === 'datetime' ? ' HH:mm' : ''

        if (!isValid(dateValue)) return

        this.inputValue = format(this.modelValue, this.date_format + withTime)
        this.datepickerValue = this.modelValue
      }
    },
    checkValidDate(date) {
      return /\d{4}\/\d{2}\/\d{2}/.test(date) && isValid(new Date(date))
    },
    checkValidDateTime(dateTime) {
      return /\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}/.test(dateTime) && isValid(new Date(dateTime))
    },
    checkValidTime(time) {
      if (!time) return false

      const hours = time.substring(0, 2)
      const minutes = time.substring(3, 5)

      if (isNaN(hours) || isNaN(minutes) || hours > 23 || minutes > 59 || time.length !== 5) {
        return false
      }

      return true
    },
    checkDatePeriod(dateTime) {
      const formattedDate = parse(dateTime)

      if (this.allowedDates) {
        let result = false

        for (const allowedDate of this.allowedDates) {
          if (isSameDay(formattedDate, allowedDate)) result = true
        }

        return result
      }

      if (this.maxDate && !this.minDate) {
        return isBefore(formattedDate, this.maxDate) || isSameDay(formattedDate, this.maxDate)
      }

      if (this.minDate && !this.maxDate) {
        return isAfter(formattedDate, this.minDate) || isSameDay(formattedDate, this.minDate)
      }

      if (this.minDate && this.maxDate) {
        if (this.minDate > this.maxDate) return false
        return isWithinRange(formattedDate, this.minDate, this.maxDate)
      }

      return true
    },
    onChangeDatepicker(date) {
      if (!date) return this.$emit('update:modelValue', date)

      if (this.mode === 'time') {
        return this.$emit('update:modelValue', format(date, 'HH:mm'))
      } else if (this.mode === 'datetime') {
        return this.$emit('update:modelValue', format(date, this.outputDateFormat + ' HH:mm'))
      } else {
        return this.$emit('update:modelValue', format(date, this.outputDateFormat))
      }
    },
    showDateSelect() {
      this.showCalendar = true

      if (!this.modelValue) this.onChangeDatepicker(this.datepickerValue)
    },
  },
}
</script>

<template>
  <div class="w-full flex flex-col mb-6">
    <label class="mb-2 text-small font-semibold" :for="name">
      {{ label }}
      <span v-if="is_required" class="text-[#272727]">*</span>
    </label>
    <OnClickOutside @trigger="() => (showCalendar = false)" class="date-field-container">
      <input
        v-mask="mask"
        @input="handleChange"
        @blur="handleBlur"
        @focus="showDateSelect"
        :value="inputValue"
        :placeholder="field_placeholder"
        :class="{ 'border-red-500': meta.validated && !meta.valid }"
        :name="name"
        :id="name"
        :maxlength="maxlength"
        class="w-full h-[44px] px-6 border focus:outline-none border-slate-300 rounded-full touch-none"
        readonly
      />
      <button v-if="withCalendar" @click="showDateSelect" class="calendar-btn" type="button">
        <img :src="require('@/assets/images/navbar/icon_calendar.svg')" />
      </button>
      <DateSelect
        :show="showCalendar"
        v-model="datepickerValue"
        @change="onChangeDatepicker"
        @close="() => (showCalendar = false)"
      />
    </OnClickOutside>
    <div v-if="hint" class="form-hint">{{ hint }}</div>
    <div v-if="errorMessage" class="mt-2 font-semibold text-red-500">{{ errorMessage }}</div>
  </div>
</template>

<style lang="css" scoped>
.date-field-container {
  position: relative;
}
.calendar-btn {
  position: absolute;
  right: 18px;
  top: 13px;
  width: 18px;
  height: 18px;
  cursor: text;
}
</style>
