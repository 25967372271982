/* eslint-disable */

import i18next from '@/i18n'
import { isEmpty } from 'lodash'

const MAX_PASSWORD_LENGTH = 8

export const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$/

export const PASSWORD_NUMBER_REGEX = /(?=.*[0-9])/
export const PASSWORD_LOWER_CASE_REGEX = /(?=.*[a-z])/
export const PASSWORD_UPPER_CASE_REGEX = /(?=.*[A-Z])/

export const checkPassword = value => {
  const result = []

  if (!PASSWORD_UPPER_CASE_REGEX.test(value)) {
    result.push(i18next.t('upper-case alphabet'))
  }

  if (!PASSWORD_LOWER_CASE_REGEX.test(value)) {
    result.push(i18next.t('lower-case alphabet'))
  }

  if (!PASSWORD_NUMBER_REGEX.test(value)) {
    result.push(i18next.t('number'))
  }

  if (isEmpty(result)) {
    if (value.length >= MAX_PASSWORD_LENGTH) {
      return true
    }

    return i18next.t('Please enter at least 8 characters')
  }

  if (value.length >= MAX_PASSWORD_LENGTH) {
    return `${i18next.t('Please include {{textType}}', { textType: result.join('・') })}`
  }

  return `${i18next.t('Please enter at least 8 characters include {{textType}}', { textType: result.join('・') })}`
}

export const checkUserDataValid = (userData = {}) => {
  if (!userData) return false

  let validByNationality = true

  return !!(
    userData['name'] &&
    userData['birthDate'] &&
    userData['gender'] &&
    userData['address'] &&
    userData['telephone'] &&
    validByNationality
  )
}
