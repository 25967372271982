<template>
  <div @click.stop="" class="flex items-center cursor-default">
    <button
      @click="onClickMinus"
      :disabled="disabled_minus"
      class="border-[1px] rounded-full w-6 h-6 transition-colors"
      :class="{
        'text-gray-400 border-gray-400': disabled_minus,
        'text-amber border-amber': !disabled_minus,
      }"
      type="button"
    >
      <span class="text-xl leading-4 relative top-[-1px]"> - </span>
    </button>
    <div class="min-w-[24px] mx-2 text-center">{{ modelValue }}</div>
    <button
      @click="onClickPlus"
      :disabled="disabled_plus"
      class="border-[1px] rounded-full w-6 h-6 transition-colors"
      :class="{
        'text-gray-400 border-gray-400': disabled_plus,
        'text-amber border-amber': !disabled_plus,
      }"
      type="button"
    >
      <span class="text-xl leading-4 relative top-[-1px]">+</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { type: Number, default: 1 },
    maxCount: Number,
  },
  computed: {
    disabled_minus() {
      return this.modelValue <= 0
    },
    disabled_plus() {
      return this.modelValue >= this.maxCount
    },
  },
  methods: {
    onClickMinus() {
      this.$emit('update:modelValue', +this.modelValue - 1)
    },
    onClickPlus() {
      this.$emit('update:modelValue', +this.modelValue + 1)
    },
  },
}
</script>
