<template>
  <div class="bg-white flex justify-center pt-4 mb-4">
    <div class="max-w-[320px] w-full">
      <div v-for="cartItem in productsCart" :key="cartItem.product.id" class="flex flex-col mb-4">
        <div class="flex justify-between w-full">
          <div>
            <div class="text-sm">{{ $i18next.getValueByLanguage(cartItem.product, 'name') }}</div>
            <div class="text-sm">¥{{ cartItem.product.price.toLocaleString() }}</div>
          </div>
          <div class="flex items-center">
            <SelectField
              :value="cartItem.count"
              :options="getProductCountList(cartItem.product)"
              :name="`productsCart[${cartItem.product.id}].count`"
              @change="
                e => changeProductsCartCount({ product: cartItem.product, count: e.target.value })
              "
              class="w-[100px] mb-0"
              :disabled="inNotSalesProductsInCart(cartItem.product.id)"
            />
            <Button
              @click="changeProductsCartCount({ product: cartItem.product, count: 0 })"
              mode="icon"
              class="ml-4"
            >
              <img :src="require('@/assets/images/navbar/request_delete.svg')" />
            </Button>
          </div>
        </div>
        <div v-if="inNotSalesProductsInCart(cartItem.product.id)" class="text-xs text-red-500">
          {{ $t('This product is out of sale time') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      formData: {
        telephone: '',
      },
    }
  },
  computed: {
    ...mapGetters(['productsCart', 'notSalesProductsInCart']),
  },
  methods: {
    ...mapActions(['changeProductsCartCount']),
    getProductCountList(product) {
      return [...new Array(product.stock || 0)].map((_, index) => ({
        value: index + 1,
        label: index + 1,
      }))
    },
    inNotSalesProductsInCart(id) {
      return this.notSalesProductsInCart.some(({ product }) => product.id === id)
    },
  },
}
</script>
