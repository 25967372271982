<template>
  <MainLayout
    :title="capitalize($t('coupon'))"
    :loading="loading"
    :class="{
      'bg-[#185AA5]': !isUsed,
      'bg-gray-300': isUsed,
      'tracking-[.01rem]': $i18next.language === 'ja',
    }"
    class="px-6 pt-6 pb-6"
    backRoute="home"
  >
    <div
      v-if="hotelCoupon"
      class="bg-white w-full max-w-[320px] rounded-xl overflow-hidden text-gray-600"
    >
      <div class="w-full h-[100px]">
        <img
          v-if="hotelCoupon.image"
          :src="hotelCoupon.image"
          :alt="hotelCoupon.description"
          :title="title"
          class="w-full h-full object-cover"
        />
      </div>
      <div class="p-6">
        <div class="text-amber text-xl font-medium leading-[1.3rem] mb-3">
          {{ title }}
        </div>
        <div class="font-bold text-xs text-gray leading-[1.3rem] mb-3">
          {{ venue_name }}
        </div>
        <div class="text-justify text-xs leading-[1.3rem] mb-3">
          {{ $i18next.getValueByLanguage(hotelCoupon, 'description') }}
        </div>
        <div class="text-xs mb-4">{{ $t('Expiration Date') }}: {{ valid_dates }}</div>
        <div>
          <a :href="hotelCoupon.url" target="_blank" class="text-xs text-amber">
            {{ capitalize($t('click here for store details')) }}
          </a>
        </div>
      </div>
    </div>
    <div class="text-white my-6 max-w-[320px] text-center leading-[1.3rem]">
      <span v-if="$i18next.language === 'ja'">
        店舗スタッフに<br />
        この画面を掲示してください
      </span>
      <span v-else>{{ capitalize($t('show this screen to store staff')) }}</span>
    </div>
    <Button
      @click="useCoupon"
      :disabled="isUsed"
      :loading="actionLoading"
      :class="{ 'border border-white': isUsed }"
      class="mt-0 mb-4"
    >
      {{ capitalize($t(isUsed ? 'already used' : 'mark as used')) }}
    </Button>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import MainLayout from '@/components/layouts/MainLayout'
import { capitalize } from '@/utils/text'

export default {
  name: 'CouponDetailView',
  components: {
    MainLayout,
  },
  data() {
    return {
      loading: false,
      actionLoading: false,
      hotelCoupon: undefined,
      isUsed: false,
    }
  },
  mounted() {
    this.loadCoupon()
  },
  computed: {
    ...mapGetters(['userData']),
    valid_dates() {
      if (!this.hotelCoupon.validFrom || !this.hotelCoupon.validTo) return '-'
      return `${moment(this.hotelCoupon.validFrom).format('YYYY/MM/DD')} ~ 
        ${moment(this.hotelCoupon.validTo).format('YYYY/MM/DD')}`
    },
    title() {
      if (this.$i18next.isJapanese()) return this.hotelCoupon?.title

      return this.hotelCoupon?.titleLatin || this.hotelCoupon?.title
    },
    venue_name() {
      if (this.$i18next.isJapanese()) return this.hotelCoupon?.venueName

      return this.hotelCoupon?.venueNameLatin || this.hotelCoupon?.venueName
    },
  },
  methods: {
    capitalize,
    ...mapActions(['getHotelCoupon', 'globalErrorHandler', 'takeHotelCoupons']),
    async loadCoupon() {
      try {
        this.loading = true

        this.hotelCoupon = await this.getHotelCoupon(this.$route.params.id)
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.loading = false
      }
    },
    async useCoupon() {
      try {
        this.actionLoading = true

        await this.takeHotelCoupons(this.$route.params.id)

        this.isUsed = true
      } catch (error) {
        this.globalErrorHandler({ error })
      } finally {
        this.actionLoading = false
      }
    },
  },
}
</script>
