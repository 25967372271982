import i18next from 'i18next'

import en from '@/locales/en.json'
import ja from '@/locales/ja.json'
import ko from '@/locales/ko.json'
import zh from '@/locales/zh.json'

import { capitalize } from './utils/text'

i18next.init({
  lng: process.env.VUE_APP_I18N_LOCALE || 'ja',
  fallbackLng: false,
  keySeparator: ':::',
  saveMissing: true,
  resources: {
    en: { translation: en },
    ja: { translation: ja },
    ko: { translation: ko },
    zh: { translation: zh },
  },
})

i18next.getValueByLanguage = (object, key, { defaultKey, strict } = {}) => {
  if (!object) return

  const checkEmptyField = (o, k) => (strict ? k in object : o[k] !== undefined && o[k] !== null)

  if (checkEmptyField(object, `${key}${capitalize(i18next.language)}`)) {
    return object[`${key}${capitalize(i18next.language)}`]
  } else if (checkEmptyField(object, `${i18next.language}${capitalize(key)}`)) {
    return object[`${i18next.language}${capitalize(key)}`]
  } else if (i18next.language === 'ja' && checkEmptyField(object, `${key}Jp`)) {
    return object[`${key}Jp`]
  } else if (i18next.language === 'ja' && checkEmptyField(object, `jp${capitalize(key)}`)) {
    return object[`jp${capitalize(key)}`]
  } else if (strict) return

  if (defaultKey && object[defaultKey]) return object[defaultKey]

  if (object?.[key]?.[i18next.language] !== undefined) return object[key][i18next.language]

  return object[key]
}

i18next.showFullName = (fullName = '') => {
  if (i18next.language !== 'ja') return fullName
  if (!fullName) return ''

  return fullName
    .split(' ')
    .map(item => item.trim())
    .reverse()
    .join(' ')
}

i18next.isJapanese = () => {
  return i18next.language === 'ja'
}

export default i18next
