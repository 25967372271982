<template>
  <div class="w-full mb-4 p-8 bg-white flex flex-col justify-start items-start relative">
    <div class="w-full text-[16px] mb-4 text-center font-semibold">
      {{ $t('Guidance') }}
    </div>
    <Button class="absolute right-8 top-8" mode="icon" @click="() => $emit('edit')">
      <img :src="require('@/assets/images/edit_icon.svg')" alt="editIcon" />
    </Button>
    <div
      v-for="(item, index) of special_requests_data.filter(i => i.value)"
      :key="index"
      class="mb-4"
    >
      <div v-if="item.value" class="text-xs text-slate-600 leading-relaxed mb-1">
        {{ $i18next.getValueByLanguage(item, 'title') }}
      </div>
      <div v-if="item.value" class="text-[16px] font-semibold">{{ item.value }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  emits: ['display'],
  computed: {
    ...mapGetters(['customCheckinData']),
    special_requests_data() {
      if (!Array.isArray(this.customCheckinData) || !this.customCheckinData?.length) {
        this.$emit('display', false)
        return []
      } else {
        return [...this.customCheckinData]
      }
    },
  },
}
</script>
