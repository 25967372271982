<template>
  <div class="w-full h-full flex justify-center items-center">
    <SpinnerLoader />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SpinnerLoader from '@/components/blocks/loader/Loader'
import { redirectTo404 } from '@/router'
import { checkUserDataValid } from '@/utils/validation'

export default {
  name: 'Auth0Callback',
  components: {
    SpinnerLoader,
  },
  computed: {
    ...mapGetters(['hotelGuideData', 'userData', 'loginRedirect']),
  },
  async mounted() {
    try {
      const token = location.hash.replace(/#access_token=/g, '').split('&')[0]

      if (token) {
        await this.parseToken()
        await this.signInSocial({ token })

        if (!checkUserDataValid(this.userData)) {
          return this.$router.replace({ name: 'registrationForm' })
        }

        if (this.loginRedirect) {
          return this.$router.replace(this.loginRedirect)
        }

        return this.$router.replace({ name: 'myPage' })
      } else {
        if (!this.hotelGuideData.hotelId && !this.$route.query.hotelId) redirectTo404()

        this.$router.replace({ name: 'home' })
      }
    } catch (error) {
      this.globalErrorHandler({ error })
      this.$router.replace({ name: 'login' })
    }
  },
  methods: {
    ...mapActions(['signInSocial', 'parseToken', 'globalErrorHandler']),
  },
}
</script>
