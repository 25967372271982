<template>
  <footer class="w-full p-4 shadow-inner z-100 flex flex-col justify-center items-center">
    <LanguageSwitchControl />
    <img class="w-30 my-2" :src="poweredByIcon" />
    <div class="flex flex-wrap justify-center items-center">
      <template v-for="(item, index) of links" :key="index">
        <RouterLinkControl
          v-if="$route.name === 'home'"
          :link="item.link"
          :componentName="item.component"
          :target="item.target"
        >
          <p class="mx-4 my-2 font-lato text-amber text-xs">
            {{ $t(item.title) }}
          </p>
        </RouterLinkControl>
      </template>
    </div>
    <p class="text-xs mt-2">©2022 aipass Inc.</p>
  </footer>
</template>

<script>
import RouterLinkControl from '@/components/controls/RouterLinkControl'
import LanguageSwitchControl from '@/components/controls/LanguageSwitchControl'
import powered_by from '@/assets/images/powered_by.svg'

export default {
  name: 'FooterSection',
  components: {
    RouterLinkControl,
    LanguageSwitchControl,
  },
  data() {
    return {
      poweredByIcon: powered_by,
      links: [
        {
          title: 'Service Provider',
          link: 'https://aipass.inc/',
          target: '_blank',
        },
        {
          title: 'Terms of use',
          component: 'termsOfUse',
          target: '_blank',
        },
        {
          title: 'Privacy policy',
          component: 'PrivacyPolicyView',
          target: '_blank',
        },
        {
          title: 'Security policy',
          component: 'SecurityPolicyView',
          target: '_blank',
        },
      ],
    }
  },
}
</script>
