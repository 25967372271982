<template>
  <div class="flex flex-col items-center justify-between h-[100vh]">
    <div class="flex flex-col items-center">
      <img class="w-[180px] pt-[96px]" :src="require('@/assets/images/exclamation.svg')" />
      <div class="text-center pt-[40px] text-amber text-[21px] font-bold">
        このページは<br />ご利用いただけません
      </div>
      <p class="text-center pt-[32px] text-[14px]">
        リンクに問題があるか、<br />
        ページが削除された可能性があります。
      </p>
      <p v-if="!!hotelId" class="text-center text-[14px]">
        お手数をおかけしますが、<br />トップページからアクセスください。
      </p>
    </div>
    <RouterLinkControl
      v-if="!!hotelId"
      componentName="home"
      :queryParams="{hotelId}"
      class="mb-[40px] w-[260px] h-[44px] font-lato text-amber text-sm flex justify-center items-center border border-amber rounded-full"
    >
      {{ $t('TOP') }}
    </RouterLinkControl>
  </div>
</template>

<script>
import RouterLinkControl from '@/components/controls/RouterLinkControl'

export default {
  name: 'NotFound',
  components: {
    RouterLinkControl,
  },
  computed: {
    hotelId() {
      return this.$route.query.hotelId || localStorage?.getItem('hotel_id')
    },
  },
}
</script>

<style scoped></style>
