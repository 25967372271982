<template>
  <MainLayout
    :title="$t('Search scheduled reservation')"
    backRoute="home"
    class="flex flex-col items-center"
  >
    <p class="text-xs text-slate-600 leading-relaxed mb-4">
      {{ $t('Please enter your reservation information') }}
    </p>
    <Form
      v-slot="{ meta }"
      @submit="onSubmit"
      class="w-full flex flex-col justify-start items-center"
    >
      <div class="w-full max-w-[600px] bg-white">
        <div class="w-full border-b-2 py-4 px-8 border-my-page">
          <label class="flex justify-start items-start">
            <input
              type="radio"
              name="nationality"
              value="phone"
              v-model="searchType"
              class="w-[24px] h-[24px] accent-[#cb860e] font-lato"
            />
            <span class="ml-2 font-semibold">{{ $t('Phone number of your reservation') }}</span>
          </label>
          <TextField
            v-if="searchType === 'phone'"
            v-model="telephone"
            :rules="combineFieldValidators([fieldValidators.required(), fieldValidators.phone()])"
            class="mt-4 mb-0"
            placeholder="08012345678"
            name="telephone"
          />
        </div>
        <div class="w-full border-b-2 py-4 px-8 border-my-page">
          <label class="flex justify-start items-start">
            <input
              type="radio"
              name="nationality"
              value="id"
              v-model="searchType"
              class="w-[24px] h-[24px] accent-[#cb860e] font-lato"
            />
            <span class="ml-2 font-semibold">{{ $t('Reservation ID') }}</span>
          </label>
          <TextField
            v-if="searchType === 'id'"
            v-model="reservationId"
            :rules="fieldValidators.required()"
            :placeholder="$t('aiPass001')"
            class="mt-4 mb-0"
            name="reservationId"
          />
        </div>
        <div class="w-full py-4 px-8">
          <label class="flex justify-start items-start">
            <input
              type="radio"
              name="nationality"
              value="name"
              v-model="searchType"
              class="w-[24px] h-[24px] accent-[#cb860e] font-lato"
            />
            <span class="ml-2 font-semibold">
              {{ capitalize($t('reservation name or guest name')) }}
            </span>
          </label>
          <div v-if="searchType === 'name'" class="flex justify-between mt-4 items-start">
            <template v-if="$i18next.language === 'ja'">
              <div class="w-[48%]">
                <TextField
                  v-model="surname"
                  :rules="fieldValidators.required()"
                  :placeholder="$t('Last name')"
                  name="surname"
                  class="mb-0"
                />
              </div>
              <div class="w-[48%]">
                <TextField
                  v-model="name"
                  :rules="fieldValidators.required()"
                  :placeholder="$t('First name')"
                  name="name"
                  class="mb-0"
                />
              </div>
            </template>
            <template v-else>
              <div class="w-[48%]">
                <TextField
                  v-model="name"
                  :rules="fieldValidators.required()"
                  :placeholder="$t('First name')"
                  name="name"
                  class="mb-0"
                />
              </div>
              <div class="w-[48%]">
                <TextField
                  v-model="surname"
                  :rules="fieldValidators.required()"
                  :placeholder="$t('Last name')"
                  name="surname"
                  class="mb-0"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
      <Button :disabled="!meta.valid" :loading="loadingAction" type="submit" class="mb-4">
        {{ $t('Search') }}
      </Button>
    </Form>
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Form } from 'vee-validate'

import MainLayout from '@/components/layouts/MainLayout'
import formValidatorsMixin from '@/mixins/formValidatorsMixin'
import { capitalize } from '@/utils/text'

export default {
  name: 'CheckinReservationSearchView',
  mixins: [formValidatorsMixin],
  components: {
    MainLayout,
    Form,
  },
  data() {
    return {
      loadingAction: false,
      searchType: '',
      telephone: '',
      reservationId: '',
      name: '',
      surname: '',
    }
  },
  watch: {
    searchType: {
      immediate: true,
      handler() {
        this.telephone = ''
        this.reservationId = ''
        this.name = ''
        this.surname = ''
      },
    },
  },
  computed: {
    ...mapGetters(['checkinData', 'hotelGuideData']),
  },
  methods: {
    capitalize,
    ...mapActions(['searchCheckin', 'updateCheckinData', 'globalErrorHandler']),
    async onSubmit() {
      try {
        this.loadingAction = true

        const data = {
          hotelId: this.hotelGuideData.hotelId,
          lang: this.$i18next.language,
          reservationTel: this.telephone,
          reservationId: this.reservationId,
          reservationGivenName: this.name,
          reservationFamilyName: this.surname,
        }
        if (this.searchType === 'phone') {
          this.updateCheckinData({ reservationTel: data.reservationTel })
        } else if (this.searchType === 'id') {
          this.updateCheckinData({ reservationId: data.reservationId })
        } else if (this.searchType === 'name') {
          this.updateCheckinData({
            reservationGivenName: data.reservationGivenName,
            reservationFamilyName: data.reservationFamilyName,
          })
        }
        await this.searchCheckin(data)

        this.$router.push({ name: 'confirmReservationsList' })
      } catch (error) {
        if (error.response?.status === 404) {
          this.globalErrorHandler({
            error: this.$t('There is no reservation information. Check it again please.'),
          })
        } else this.globalErrorHandler({ error })
      } finally {
        this.loadingAction = false
      }
    },
  },
}
</script>
