<template>
  <MainLayout
    :title="$t('Credit Card')"
    :loading="loading"
    backRoute="myPage"
    class="flex flex-column items-center"
  >
    <CardList>
      <template #noCard><NoCard /> </template>
    </CardList>
  </MainLayout>
</template>

<script>
import MainLayout from '@/components/layouts/MainLayout'
import CardList from '@/components/sections/creditCard/CardList'
import NoCard from '@/components/sections/creditCard/NoCard'

export default {
  name: 'CreditCardView',
  components: {
    MainLayout,
    CardList,
    NoCard,
  },
}
</script>
