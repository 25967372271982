<template>
  <button
    @click="isSheet = true"
    class="p-4 font-lato text-amber text-sm flex justify-center items-center"
    type="button"
  >
    <img class="w-5" :src="languageIcon" />
    <p class="mx-2">Language</p>
    <img class="w-5" :src="bottomIcon" />
  </button>
  <swipe-modal v-model="isSheet" contents-height="88px">
    <div class="h-full flex justify-center items-center">
      <button
        @click="handleSwitchLocale('ja')"
        :class="{
          'text-amber': $i18next.language === 'ja',
          'text-neutral-300': $i18next.language !== 'ja',
        }"
        class="mb-4 mt-5 font-lato text-xs px-6 sm:px-8 py-2 border-r-2"
        type="button"
      >
        日本語
      </button>
      <button
        @click="handleSwitchLocale('en')"
        :class="{
          'text-amber': $i18next.language === 'en',
          'text-neutral-300': $i18next.language !== 'en',
        }"
        class="mb-4 mt-5 font-lato text-xs px-6 sm:px-8 py-2 border-r-2"
        type="button"
      >
        English
      </button>
      <button
        @click="handleSwitchLocale('zh')"
        :class="{
          'text-amber': $i18next.language === 'zh',
          'text-neutral-300': $i18next.language !== 'zh',
        }"
        class="mb-4 mt-5 font-lato text-xs px-6 sm:px-8 py-2 border-r-2"
        type="button"
      >
        中文
      </button>
      <button
        @click="handleSwitchLocale('ko')"
        :class="{
          'text-amber': $i18next.language === 'ko',
          'text-neutral-300': $i18next.language !== 'ko',
        }"
        class="mb-4 mt-5 font-lato text-xs px-6 sm:px-8 py-2"
        type="button"
      >
        한국어
      </button>
    </div>
  </swipe-modal>
</template>

<script>
import swipeModal from '@takuma-ru/vue-swipe-modal'

export default {
  name: 'LanguageSwitchControl',
  components: {
    swipeModal,
  },
  data() {
    return {
      bottomIcon: require('@/assets/images/controls/icon_arrow.svg'),
      languageIcon: require('@/assets/images/controls/icon_language.svg'),
      isSheet: false,
    }
  },
  methods: {
    handleSwitchLocale(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          lang: val,
        },
      })
      // this.$nextTick(() => {
      //   this.isSheet = false
      // })
    },
  },
}
</script>
