<template>
  <div class="flex justify-center">
    <ul class="flex bg-white shadow rounded-full px-1 py-2 text-gray-400 text-sm">
      <li
        v-for="category of categories"
        :key="category.code"
        class="p-2 cursor-pointer mx-1"
        :class="{
          'text-orange-400 rounded-full bg-gray-100': category.code === modelValue?.code,
        }"
        @click="$emit('update:modelValue', category)"
      >
        {{ category.title }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TravelGuideMenu',
  props: { modelValue: Object, categories: Array },
}
</script>
