<template>
  <MainLayout :title="$t('Wi-Fi')" :loading="loading" backRoute="home">
    <div class="w-full max-w-[600px] html-wrap bg-white p-4" v-html="content"></div>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import MainLayout from '@/components/layouts/MainLayout'
import { capitalize } from '@/utils/text'

export default {
  name: 'WifiView',
  components: {
    MainLayout,
  },
  computed: {
    ...mapGetters(['hotelGuideData']),
    content() {
      const wifiFacilityData = this.hotelGuideData?.facilityAdditionalInfoList?.[10]?.[0]

      if (!wifiFacilityData) return capitalize(this.$t('no data'))

      const content = this.$i18next.getValueByLanguage(wifiFacilityData, 'content')

      if (this.$i18next.language !== 'ko') content?.replace(/<br(?: .+?)?>/g, '')

      return content
    },
  },
}
</script>
