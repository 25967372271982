import api from '@/api'
import { getSessionStorageObject, setSessionStorageObject } from '@/utils/sessionStorage'

const state = {
  productsCart: getSessionStorageObject('productsCart') || {},
  cartRooms: getSessionStorageObject('cartRooms') || [],
  notSalesProductsInCart: [],
}

const getters = {
  productsCart: state => state.productsCart,
  notSalesProductsInCart: state => Object.values(state.notSalesProductsInCart),
  cartRooms: state => state.cartRooms,
  productsCartTotalCount: state => {
    return Object.values(state.productsCart || {}).reduce((sum, item) => +item.count + sum, 0)
  },
  productsCartTotalSum: state => {
    return Object.values(state.productsCart || {}).reduce((sum, item) => item.product.price * +item.count + sum, 0)
  },
}

const mutations = {
  SET_PRODUCTS_CART(state, data) {
    state.productsCart = data
    setSessionStorageObject('productsCart', state.productsCart)
  },
  SET_NOT_SALES_PRODUCTS_IN_CART(state, data) {
    state.notSalesProductsInCart = data
  },
  SET_CART_ROOMS(state, data) {
    state.cartRooms = data
    setSessionStorageObject('cartRooms', state.cartRooms)
  },
}

const actions = {
  getCartProductsPastSalesHour({ state, dispatch, commit }) {
    return dispatch('getProductsList').then(({ products }) => {
      const notSalesProductIds = products.filter(product => !product.isNowOnSale).map(product => product.id)
      const notSalesProducts = Object.values(state.productsCart).filter(({ product }) =>
        notSalesProductIds.includes(product.id)
      )
      commit('SET_NOT_SALES_PRODUCTS_IN_CART', notSalesProducts)
      return notSalesProducts
    })
  },
  getProductsList({ getters }) {
    return api.get('mobile-request/products', { params: { hotelId: getters['hotelId'] } }).then(res => res.data)
  },
  getGuestRoomByTelephone({ getters, commit }, { telephone }) {
    return api
      .get('mobile-request/checkin-room-number', { params: { hotelId: getters['hotelId'], telephone } })
      .then(res => {
        commit('SET_CART_ROOMS', res.data?.checkinAssignRooms)
        return res.data?.checkinAssignRooms
      })
  },
  clearCart({ commit }) {
    commit('SET_PRODUCTS_CART', {})
    commit('SET_CART_ROOMS', [])
  },
  makeRequestOrder({ getters }, order) {
    return api.post('mobile-request/order', { hotelId: getters['hotelId'], order }).then(res => res.data)
  },
  makeSales({ getters }, sales) {
    return api.put('sales-manager/sales', { hotelId: getters['hotelId'], sales }).then(res => res.data)
  },
  setProductsCart({ commit }, productsCart) {
    commit('SET_PRODUCTS_CART', productsCart)
  },
  changeProductsCartCount({ commit, state: { productsCart } }, { count, product }) {
    const cart = { ...productsCart }
    if (!count) {
      delete cart[product.id]
    } else {
      if (!cart[product.id]) cart[product.id] = {}

      cart[product.id].count = count
      cart[product.id].product = product
    }

    commit('SET_PRODUCTS_CART', cart)
  },
  sendmailMobileRequestInfoForUser(_, data) {
    return api.post('frontend-sendmail/mobile-request-info', data)
  },
  sendmailMobileRequestInfoForAdmin(_, data) {
    return api.post('frontend-sendmail/mobile-request-order', data)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
