<script>
import { omit } from 'lodash'
import { Field } from 'vee-validate'

import FieldMixin from '@/components/UI/formFields/mixins/FieldMixin.vue'

export default {
  mixins: [FieldMixin],
  components: { Field },
  props: {
    mask: String,
    hint: String,
  },
  computed: {
    input_attrs() {
      return omit(this.$attrs, ['class'])
    },
  },
}
</script>

<template>
  <div class="w-full flex flex-col mb-6">
    <Field
      v-bind="$attrs"
      v-slot="{ field, errorMessage, meta }"
      :name="name"
      :rules="rules"
      ref="field"
    >
      <label v-if="label" class="mb-2 text-small font-semibold" :for="name">
        {{ label }}
        <span v-if="is_required" class="text-[#272727]">*</span>
      </label>
      <div class="flex flex-wrap items-stretch w-full relative">
        <slot name="prepend" />
        <input
          v-if="mask"
          v-bind="{ ...field, ...input_attrs }"
          v-mask="mask"
          :class="{
            'border-red-500': !meta.valid && meta.validated,
          }"
          :disabled="disabled"
          class="w-full h-[44px] px-6 border focus:outline-none border-slate-300 rounded-full touch-none"
        />
        <input
          v-else
          v-bind="{ ...field, ...input_attrs }"
          :class="{
            'border-red-500': !meta.valid && meta.validated,
          }"
          :disabled="disabled"
          class="w-full h-[44px] px-6 border focus:outline-none border-slate-300 rounded-full touch-none"
        />
      </div>
      <div v-if="hint" class="form-hint text-xs text-slate-600 leading-relaxed mt-3">{{ hint }}</div>
      <div class="mt-2 font-semibold text-red-500" v-if="errorMessage && !hideError">
        {{ errorMessage }}
      </div>
    </Field>
  </div>
</template>
